
import * as React from 'react'
import { connect } from 'react-redux'
import { animated } from 'react-spring'
import cx from 'classnames'

import AppState from '../../../store/models/AppState'
import selectors from '../../../store/selectors'
import Tint from '../../../../../../core/tints/Tint'
import CancelIcon from '../../icons/Cancel'
import TintsIcon from '../../icons/Tints'
import selectTint from '../../../actionCreators/tints/selectTint'
import MobileSelectedItemInfo from './MobileSelectedItemInfo'
import MobileAssetSelectorPanel from './MobileAssetSelectorPanel'

interface OwnProps { }

interface ReduxProps {
  tints: Array<Tint>
  selectedTint: Tint
  translate: (key: string) => string
}

interface DispatchProps {
  selectTint: (tint: Tint) => void
}

type Props = OwnProps & ReduxProps & DispatchProps


function MobileTintSelector(props: Props) {
  return (
    <MobileAssetSelectorPanel
      title={props.translate('editor.tints.title')}
      icon={<TintsIcon />}
      iconClassName="tints"
      footerText={props.translate('editor.tints.caption')}
    >
      <MobileSelectedItemInfo
        name={
          props.selectedTint != null
            ? props.translate(props.selectedTint.productFamily)
            : props.translate('editor.no-selection.title')
        }
        code={
          props.selectedTint != null
            ? props.selectedTint.code
            : props.translate('editor.no-selection.captionMobile')
        }
      />

      <div className='panel-group-selector--mobile--list'>
        <button
          className={cx(
            'mobile-swatch',
            props.selectedTint == null && 'selected'
          )}
          onClick={() => props.selectTint(null)}
        >
          <CancelIcon />
        </button>
        {props.tints.reverse().map((tint) => (
          <button
            key={tint.code}
            className={cx(
              'mobile-swatch',
              props.selectedTint == tint && 'selected'
            )}
            style={{
              background: tint.swatchColor
            }}
            onClick={() => props.selectTint(tint)}
          />
        ))}
      </div>
    </MobileAssetSelectorPanel>
  )
}



export default connect(
  (state: AppState): ReduxProps => {
    return {
      tints: selectors.getTints(state),
      selectedTint: selectors.getSelectedTint(state),
      translate: selectors.getTranslator(state)
    }
  },
  {
    selectTint
  }
)(MobileTintSelector)