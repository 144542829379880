import * as React from 'react'
import cx from 'classnames'
import { animated, useSpring } from 'react-spring'
import useGesture from './../../../helpers/react-use-gesture'
import Wrap from '../../../../../../core/wraps/Wrap'
import { remap, clamp } from '../../../helpers/math'
import CancelIcon from '../../icons/Cancel'
import DesktopScrollBar from './DesktopScrollBar'

let swatch = {
  boundingSize: 36,
  size: 28,
  margin: 8
}

let DesktopPager = React.memo(function Pager(props: {
  basePath: string
  wraps: Array<Wrap>
  width: number
  selectedWrap: Wrap | null
  temporaryWrap: Wrap | null
  onHoverWrap: (Wrap) => void
  onSelectWrap: (Wrap) => void
}) {
  let { wraps } = props
  let numberOfWraps = wraps.length
  let columns = Math.floor(props.width / swatch.boundingSize)
  let perPage = columns * 2
  let pages = Math.ceil(numberOfWraps / perPage)

  let scrollAreaWidth = Math.ceil((numberOfWraps + 1) / 2) * swatch.boundingSize
  let farthestPossibleScroll = scrollAreaWidth - props.width

  // sideFadeSpring.value:
  // -1 == right-fade visible
  //  0 == both left-fade and right-fade visible
  //  1 == left-fade visible
  // starting value is -1 because the Pager starts scrolled to the left.
  let [sideFadeSpring, setSideFadeSpring] = useSpring(() => ({ value: -1 }))
  let updateFadeSpring = React.useCallback((scrollLeft) => {
      if (scrollLeft <= 10) {
        setSideFadeSpring({ value: -1 })
      } else if (scrollLeft >= farthestPossibleScroll - 10) {
        setSideFadeSpring({ value: 1 })
      } else {
        setSideFadeSpring({ value: 0 })
      }
  }, [ farthestPossibleScroll])

  let scrollBarRef = React.useRef(null) // This will be an object with a setX method
  let bind = useGesture({
    onScroll: (event) => {
      let scrollLeft = event.xy[0]
      let x = remap(0, farthestPossibleScroll, 0, 1, scrollLeft)
      if (scrollBarRef.current != null) {
        scrollBarRef.current.setX(x)
      }
      updateFadeSpring(scrollLeft)
    }
  })

  let scrollPanelRef = React.useRef(null)
  let onScrollBarScroll = React.useCallback(
    (x) => {
      let scrollLeft = remap(0, 1, 0, farthestPossibleScroll, x)
      let element: HTMLDivElement | null = scrollPanelRef.current
      if (element != null) {
        element.scrollLeft = scrollLeft
      }
      updateFadeSpring(scrollLeft)
    },
    // React will only update this callback if this changes
    [farthestPossibleScroll]
  )

  return (
    <>
      <animated.div
        key="left-fade"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: swatch.boundingSize * 2,
          width: 50,
          background: 'linear-gradient(90deg, white 10%, rgba(255,255,255,0))',
          zIndex: 10,
          pointerEvents: 'none',
          transform: sideFadeSpring.value.interpolate(
            (v) => `translateX(${clamp(-50, 0, remap(-1, 0, -50, 0, v))}px)`
          )
        }}
      />
      <animated.div
        key="right-fade"
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          height: swatch.boundingSize * 2,
          width: 50,
          background: 'linear-gradient(90deg, rgba(255,255,255,0), white 90%)',
          zIndex: 10,
          pointerEvents: 'none',
          transform: sideFadeSpring.value.interpolate(
            (v) => `translateX(${clamp(0, 50, remap(0, 1, 0, 50, v))}px)`
          )
        }}
      />
      <div
        ref={scrollPanelRef as any}
        {...bind()}
        className="pager-scrollarea"
        style={{
          width: props.width,
          height: swatch.boundingSize * 2 + 20,
          position: 'relative'
        }}
      >
        <div
          style={{
            position: 'absolute',
            height: swatch.boundingSize * 2,
            width: Math.ceil(numberOfWraps / 2) * swatch.boundingSize
          }}
        >
          <Page
            basePath={props.basePath}
            wraps={wraps}
            columns={columns}
            selectedWrap={props.selectedWrap}
            temporaryWrap={props.temporaryWrap}
            onHoverWrap={props.onHoverWrap}
            onSelectWrap={props.onSelectWrap}
          />
        </div>
      </div>
      <div
        style={{
          marginLeft: 4,
          position: 'absolute',
          bottom: 0,
          width: '100%'
        }}
      >
        <DesktopScrollBar
          ref={scrollBarRef}
          width={props.width - 8}
          pageCount={pages}
          onScroll={onScrollBarScroll}
        />
      </div>
    </>
  )
})

function Page(props: {
  basePath: string
  wraps: Array<Wrap>
  columns: number
  selectedWrap: Wrap | null
  temporaryWrap: Wrap | null
  onHoverWrap: (Wrap) => void
  onSelectWrap: (Wrap) => void
}) {
  return (
    <div
      style={{
        width: '100%',
        height: swatch.boundingSize + swatch.boundingSize,
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: swatch.boundingSize,
        gridTemplateRows: '1fr 1fr',
        justifyItems: 'center',
        alignItems: 'center'
      }}
    >
      <button
        className={cx('wrap-select-button', !props.selectedWrap && 'selected')}
        style={{
          height: swatch.boundingSize
        }}
        onMouseEnter={() => {
          props.onHoverWrap(null)
        }}
        onClick={() => {
          props.onSelectWrap(null)
        }}
      >
        <div
          className="wrap-select-swatch"
          style={{
            height: swatch.size,
            width: swatch.size,
            display: 'inline-block',
            color: '#b7b7b7'
          }}
        >
          <CancelIcon />
        </div>
      </button>
      {props.wraps.map((wrap, index) => {
        const styles:any = {
          backgroundColor: wrap.meta.swatchColor
        }
        if (wrap.meta.swatchImage) {
          styles.backgroundImage = 'url("' + props.basePath + wrap.meta.swatchImage + '")'
        }

        return <button
          key={wrap.meta.code}
          className={cx('wrap-select-button desktop-swatch', {
            selected: wrap === props.selectedWrap,
            temporary: wrap === props.temporaryWrap
          })}
          style={styles}
          onMouseEnter={() => {
            props.onHoverWrap(wrap)
          }}
          onClick={() => {
            props.onSelectWrap(wrap)
          }}
        />
      })}
    </div>
  )
}

export default DesktopPager
