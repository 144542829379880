import Dispatch from './Dispatch'
import GetState from './GetState'
import selectors from '../store/selectors'
import FormConfigLoader from '../../../../core/FormConfigLoader'
import setLoadingMessage from './setLoadingMessage'
import doesUrlExist from '../../../../core/utils/doesUrlExist'
import setLoadingError from './setLoadingError'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState()

    if (selectors.hasLoadedFormConfig(state)) {
      return // Config's already loaded. Nothing left to do!
    }

    const environmentConfig = selectors.getEnvironmentConfig(state)

    dispatch(setLoadingMessage(selectors.translate(state, 'loading.form-config')))
    const formConfigLoader = new FormConfigLoader()
    const selectedLanguage = selectors.getLanguage(state);
    let formPath = environmentConfig.getAssetFullPath('assets/js/locales/'+selectedLanguage+'/forms.json')
    if (!await doesUrlExist(formPath)) {
      formPath = environmentConfig.getAssetFullPath('assets/js/forms.json')
    }

    let formConfig = null
    try {
      formConfig = await formConfigLoader.load(formPath)
    }
    catch(e) {
      console.warn('Unable to load form config json file from `' + formPath + '`!', e)
    }

    if (!formConfig) {
      console.warn('Unable to load form configuration! Aborting.')
      dispatch(setLoadingError(selectors.translate(state, 'error.form-config')))
			return
    }

    dispatch({
      type: 'SET_FORM_CONFIG',
      formConfig: formConfig
    })
  }
}