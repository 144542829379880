import Dispatch from './Dispatch'
import GetState from './GetState'
import selectors from '../store/selectors'
import removeAccentWrap from './accents/removeAccentWrap'
import removeTint from './tints/removeTint'
import selectWrapForPanelGroup from './selectWrapForPanelGroup'
import setEditorPanelState from './editorPanels/setEditorPanelState'
import EditorPanelState from '../store/models/EditorPanelState'
import deselectPanelGroup from './wraps/deselectPanelGroup'
import removePaintProtectionFilm from './films/removePaintProtectionFilm'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		// TODO: Check to make sure we're in a valid state to be able to transition to the new state?

    const state = getState()

    const panelGroups = selectors.getSelectedVehiclePanelGroups(state)
    panelGroups.forEach( (panelGroup) => {
      dispatch(selectWrapForPanelGroup(panelGroup, null))
    })

    dispatch(removeTint())
    dispatch(removeAccentWrap())
    dispatch(deselectPanelGroup())
    dispatch(removePaintProtectionFilm())
    dispatch(setEditorPanelState(EditorPanelState.Closed))
  }
}