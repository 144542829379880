import * as React from 'react'
import Required from './validators/Required'

type ValidationFunction = (fieldName:string, value:string) => string

interface OwnProps {
	name?: string,
	required?: any,
	validator?: Array<ValidationFunction> | ValidationFunction
}

interface OwnState {
	error:string
}

/// Pretty much just a container for form validation.
export default class TextInput<T, S> extends React.Component<OwnProps & T, OwnState & S> {
  render() {
		return <React.Fragment /> // For subclasses to implement
	}

	getValue = ():any => {
		return null // For subclasses to implement
	}

	validate = ():boolean => {
		let validators = []
		if (this.props.validator) {
			if (Array.isArray(this.props.validator)) {
				validators = validators.concat(this.props.validator)
			}
			else {
				validators.push(this.props.validator)
			}
		}
		else if (this.props.required) {
			validators.push(Required)
		}

		if (validators.length <= 0) {
			return true
		}

		const value = this.getValue()
		for(let i=0; i<validators.length; i++) {
			const validator = validators[i]
			const message = validator(this.props.name, value)
			if (message) {
				this.setState({error: message})
				return false
			}
		}

		this.setState({error:null})
		return true
	}
}