import Dispatch from '../Dispatch'
import GetState from '../GetState'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		// TODO: Check to make sure we're in a valid state to be able to transition to the new state?

    dispatch({
      type: 'SELECT_ACCENT_WRAP',
      wrap: null
    })
  }
}