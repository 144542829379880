import AssetLibrary from "../AssetLibrary"
import Wrap from './Wrap'
import WrapFactory from './WrapFactory'
import * as _ from 'underscore'

class WrapsConfigLoader {
	constructor() {

	}

	load = async (url:string):Promise<Array<Wrap>> => {
		const data = await AssetLibrary.loadJson(url)
		if (!data) {
			return null
		}

		if (!data.wraps) {
			throw new Error('Loaded JSON did not have a `wraps` element.')
		}

		let loaded = []
		data.wraps.forEach( (wrapJSON) => {
			const wrap = WrapFactory.fromConfig(wrapJSON, data)
			if (wrap) {
				loaded.push(wrap)
			}
		})

		// Make sure all of the shareIdentifiers are unique
		loaded.forEach( (wrap) => {
			const matches = loaded.filter( (otherWrap) => {
				return otherWrap.meta.shareIdentifier === wrap.meta.shareIdentifier
			})

			if (matches.length !== 1) {
				throw new Error('Loaded wraps JSON had multiple items with the same shareIdentifier key `' + wrap.meta.shareIdentifier + '`!')
			}
		})

    loaded = _.sortBy(loaded, (wrap) => {
      return wrap.meta.sortOrder
    })

		return loaded
	}

}

export default WrapsConfigLoader