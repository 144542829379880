import UserSelections from "../UserSelections"

class VehicleMeshVisibilityUpdater {
	configure = (basePath:string, selections:UserSelections, forceAccentsToBeVisible:boolean) => {
    const vehicle = selections.getVehicle()
    const accentWrap = selections.getAccent()
    vehicle.load(basePath).then( () => {
      vehicle.model.traverse( (child:any) => {
        if (child.isMesh) {
          child.visible = true
          if (vehicle.isAccentMeshName(child.name)) {
            child.visible = !!accentWrap || forceAccentsToBeVisible
          }
        }
      })
    })
	}
}

export default VehicleMeshVisibilityUpdater