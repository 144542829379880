import Dispatch from '../Dispatch'
import GetState from '../GetState'
import FetchState from '../../store/models/FetchState'
import axios from 'axios'
import selectors from '../../store/selectors'
import restylerSelectors from '../../store/selectors/restylers'
import AppState from '../../store/models/AppState'
import SharingUtility from '../../../../../core/SharingUtility'
import PanelGroup from '../../../../../core/vehicles/PanelGroup'
import waitFor from '../../../../../core/utils/waitFor'
import merge from 'deepmerge'
import requestShareImageData from '../requestShareImageData'
import PaintProtectionFilm from '../../../../../core/films/PaintProtectionFilm'

export default (
	contactInfo:{}
) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    let state = getState()
    const restyler = restylerSelectors.getContactRestyler(state)
    if (!restyler) {
      console.warn('submitContactRestylerForm called, but no restyler was in the store! Ignoring.')
	    return
	  }
    
    dispatch({
      type: 'SET_RESTYLER_FORM_STATE',
      value: FetchState.Loading
    })
    
    await dispatch(requestShareImageData())
    
    state = getState()
    const postData = buildPostData(state, contactInfo, restyler)
    
    try {
      var endpoint = selectors.getEnvironmentConfig(state).getRestylerFindRestylerEmailUrl()
      await axios.post(endpoint, postData)
      
      dispatch({
        type: 'SET_RESTYLER_FORM_STATE',
        value: FetchState.Success
      })
    }
    catch(err) {
      dispatch({
        type: 'SET_RESTYLER_FORM_STATE',
        value: FetchState.Failure
      })
    }
  }
}

const buildPostData = (state:AppState, contactInfo:any, selectedRestyler:any) => {
  const serializedSelections = buildSerializedSelections(state)
  return {
    restyler: selectedRestyler,
    contact: contactInfo,
    selections: {
      ...serializedSelections
    },
    tracking: selectors.getTrackingMeta(state)
  }
}

const translateWrapMeta = (state:AppState, wrapMeta:any) => {
  const wrapMetaClone:any = merge({}, wrapMeta)
  wrapMetaClone.colorGroup = selectors.translate(state, wrapMetaClone.colorGroup)
  wrapMetaClone.finishGroup = selectors.translate(state, wrapMetaClone.finishGroup)
  wrapMetaClone.name = selectors.translate(state, wrapMetaClone.name)
  wrapMetaClone.productFamily = selectors.translate(state, wrapMetaClone.productFamily)
  return wrapMetaClone
}

const translateTintMeta = (state:AppState, tintMeta:any) => {
  const tintMetaClone:any = merge({}, tintMeta)
  tintMetaClone.name = selectors.translate(state, tintMetaClone.name)
  tintMetaClone.productFamily = selectors.translate(state, tintMetaClone.productFamily)
  return tintMetaClone
}

const translatePaintProtectionFilm = (state:AppState, film:PaintProtectionFilm) => {
  const filmMetaClone:any = merge({}, film)
  filmMetaClone.name = selectors.translate(state, film.name)
  filmMetaClone.finish = selectors.translate(state, film.finish)
  return filmMetaClone
}

const buildSerializedSelections = (state:AppState) => {
  const userSelections = selectors.getAllUserSelections(state)
  
  const vehicle = userSelections.getVehicle()
  const accent = userSelections.getAccent()
  const tint = userSelections.getTint()
  const protection = userSelections.getPaintProtectionFilm()

  const panelSelections = []
  if (selectors.hasMadeVehicleSelection(state)) {
    PanelGroup.Keys.forEach( (key) => {
      const panelGroup = vehicle.getPanelGroup(key)
      const wrap = userSelections.getWrapForPanelGroup(panelGroup)
      if (wrap) {
        panelSelections.push({
          name: selectors.translate(state, panelGroup.localizationKey),
          wrap: translateWrapMeta(state, wrap.meta)
        })
      }
    })
  }
  
  const shareImageURL = selectors.getShareImageURL(state)
  return {
    language: selectors.getLanguage(state),
    shareCode: SharingUtility.createShareCode(userSelections),
    shareImageURL: shareImageURL,
    vehicleName: vehicle ? selectors.translate(state, userSelections.getVehicle().nameLocalizationKey) : null,
    accent: accent ? translateWrapMeta(state, accent.meta) : null,
    tint: tint ? translateTintMeta(state, tint.serialize()) : null,
    paintProtectionFilm: protection ? translatePaintProtectionFilm(state, protection) : null,
    panels: panelSelections
  }
}