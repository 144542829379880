import Dispatch from './Dispatch'
import GetState from './GetState'

export default (i18n:any, languageKey:string) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    i18n.language = languageKey // The i18n instance did not seem to provide a way to get the key, so we manually add it in there so it can be queried later. I swear this used to be included in the i18n instance, but sure enough it didnt' exist currently.
		dispatch({
			type: 'SET_LANGUAGE',
			language: i18n
		})
  }
}