import Dispatch from '../Dispatch'
import GetState from '../GetState'
import RestylerSearchResult from '../../store/models/RestylerSearchResult'
import openModal from '../modals/openModal'
import Modal from '../../store/models/Modal'

export default (restyler:RestylerSearchResult) => {
  return async (dispatch: Dispatch, getState: GetState):Promise<void> => {
    dispatch({
      type: 'CONTACT_RESTYLER',
      restyler: restyler
    })

    dispatch(openModal(Modal.ContactRestylerForm))
  }
}