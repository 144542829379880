import THREE from '../three/threeWithExtensions'
import WrapMetaData from './WrapMetaData'
import Wrap from './Wrap'
import SharingUtility from '../SharingUtility'
import ModernShaderMaterial from '../materials/wraps/ModernShaderMaterial'
import PhysicalMaterial from '../materials/wraps/PhysicalMaterial'
import FleckShaderMaterial from '../materials/wraps/FleckShaderMaterial'
import PlatformDetector from '../utils/PlatformDetector'
import merge from 'deepmerge'

class WrapFactory {
	static fromConfig = (wrap:any, data:any) => {
		if (!wrap) {
			throw new Error('Must supply a JSON configuration node!')
		}

		if (wrap.disabled) {
			// If there's a disabled flag on it, we can just skip any further imports.
			return null
		}

		let meta = null
		try
		{
			meta = WrapFactory.createWrapMetadata(wrap)
		}
		catch(e) {
			console.warn('Unable to create metadata for wrap JSON `' + JSON.stringify(wrap) + '`. ' + e.message)
			return null
		}

		if (!wrap.material) {
			throw new Error('Are you sure you passed in a JSON configuration node? The `.material` property is required.')
		}
		let material = null
		let materialProperties = WrapFactory.getMaterialPropertiesIncludingPresets(wrap.materialProps, data.materialPresets)

		switch(wrap.material.toLowerCase()) {
			case 'FleckShaderMaterial'.toLowerCase():
			case 'FlakeShaderMaterial'.toLowerCase():
				material = new FleckShaderMaterial(materialProperties)
				break
			case 'PhysicalMaterial'.toLowerCase():
				material = new PhysicalMaterial(materialProperties)
				break
			case 'ModernShaderMaterial'.toLowerCase():
				material = new ModernShaderMaterial(wrap.name, materialProperties)
				break
			default:
				throw new Error('Unknown wrap material type `' + wrap.material + '` defined on `' + wrap.name + '`.')
		}

		const wrapInstance = new Wrap(meta, material)
		return wrapInstance
	}

	static createWrapMetadata = (json:any) => {
		if (!json) {
			throw new Error('json is required')
		}

		if (!json.shareIdentifier) {
			throw new Error('wrap missing `shareIdentifier` metadata! ' + JSON.stringify(json))
		}
		if (json.shareIdentifier.length !== SharingUtility.WrapIdentifierLength) {
			throw new Error('wrap `shareIdentifier` length must be ' + SharingUtility.WrapIdentifierLength + '.')
		}

		if (!json.code) {
			throw new Error('wrap missing `code` metadata! ' + JSON.stringify(json))
		}

		if (!json.name) {
			throw new Error('wrap missing `name` metadata! ' + JSON.stringify(json))
		}

		if (!json.productFamily) {
			throw new Error('wrap missing `productFamily` metadata! ' + JSON.stringify(json))
		}

		if (!json.colorGroup) {
			throw new Error('wrap missing `colorGroup` metadata! ' + JSON.stringify(json))
		}

		if (!json.finishGroup) {
			throw new Error('wrap missing `finishGroup` metadata! ' + JSON.stringify(json))
		}

		if (!json.swatchColor) {
			throw new Error('wrap missing `swatchColor` metadata! ' + JSON.stringify(json))
		}

		const meta = new WrapMetaData(json.shareIdentifier, json.code, json.name, json.productFamily, json.colorGroup, json.finishGroup, json.swatchColor, json.swatchImage)
		meta.isNew = !!json.new
		meta.isDefault = !!json.isDefault

		if (json.sortOrder) {
			const sortOrder = parseInt(json.sortOrder)
			if (!isNaN(sortOrder)) {
				meta.sortOrder = sortOrder
			}
		}

		return meta
	}

	static getMaterialPropertiesIncludingPresets = (materialProperties:any, materialPresets:any):any => {
		if (!materialProperties) {
			throw new Error('materialProperties is required')
		}

		if (!materialPresets) {
			throw new Error('materialPresets is required')
		}

		let merged = materialProperties
		if (materialProperties.preset) {
			const presets = materialPresets[materialProperties.preset]
			merged = merge(presets, merged)

			// THIS IS A HACK!
			// if we are on iOS and we are using the gloss-modern & gloss-modern-metallic presets
			// override the USE_TEX_LOG as it prevents a "wireframe" issue on this specific platform
			if ( PlatformDetector.isIOS() && (materialProperties.preset == "gloss-modern" || materialProperties.preset == "gloss-modern-metallic")) {
				// console.log("changing USE_TEX_LOD for iOS")
				merged.defines.USE_TEX_LOD = true
			}
		}
		delete merged['preset'] // And after we're done, make sure no 'preset' key exists. No need to keep it around after it's been merged in.

		const keys = Object.keys(merged)
		keys.forEach( (key) => {
			if (merged.hasOwnProperty(key)) {
				const value = merged[key]
				if (value && value.toString().indexOf('#') === 0) { // Assumption that it's a color property if it starts with a hash.
					const colorValue = parseInt(value.replace('#', '0x'), 16)
					merged[key] = new THREE.Color(colorValue)
				}
			}
		})

		return merged
	}
}

export default WrapFactory