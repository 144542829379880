import * as dat from 'dat.gui'
import THREE from '../../three/threeWithExtensions'
import AbstractWrapMaterial from './AbstractWrapMaterial'
import { GuiFieldName, prepareColorField, onColorChange } from '../../utils/onColorChange'
import shaderMaterialPromiseCreator from '../../utils/ShaderMaterialPromiseCreator'
import AssetLibrary from '../../AssetLibrary'
import VoidPromise from '../../utils/VoidPromise'
import MaterialExportImportTranslator from '../../utils/MaterialExportImportTranslator'

// parts of the following shader were reused through the MIT LICENCE -- from
// https://github.com/KhronosGroup/glTF-WebGL-PBR/tree/master/shaders
const vertexShader = `
#ifdef HAS_NORMALS
varying vec3 v_NormalWorld;
varying vec3 v_Normal;
#endif

varying vec3 v_PositionWorld;
varying vec3 v_Position;
varying vec2 v_UV;

uniform float textureScale;
uniform float u_textureStretch;

void main()
{
  v_UV = uv * vec2(textureScale, textureScale * u_textureStretch);

  vec4 pos_world = modelMatrix * vec4(position, 1);
  v_PositionWorld = vec3(pos_world.xyz) / pos_world.w;

  vec4 pos = viewMatrix * modelMatrix * vec4(position, 1);
  v_Position = vec3(pos.xyz) / pos.w;

#ifdef HAS_NORMALS
  v_NormalWorld = normalize(vec3(modelMatrix * vec4(normal.xyz, 0.0)));
  v_Normal = normalize(vec3(viewMatrix * modelMatrix * vec4(normal.xyz, 0.0)));
#endif

  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1);
}
`

// parts of the following shader were reused through the MIT LICENCE -- found --
// https://github.com/KhronosGroup/glTF-WebGL-PBR/blob/master/shaders/pbr-frag.glsl
//
//
// This fragment shader defines a reference implementation for Physically Based Shading of
// a microfacet surface material defined by a glTF model.
//
// References:
// [1] Real Shading in Unreal Engine 4
//     http://blog.selfshadow.com/publications/s2013-shading-course/karis/s2013_pbs_epic_notes_v2.pdf
// [2] Physically Based Shading at Disney
//     http://blog.selfshadow.com/publications/s2012-shading-course/burley/s2012_pbs_disney_brdf_notes_v3.pdf
// [3] README.md - Environment Maps
//     https://github.com/KhronosGroup/glTF-WebGL-PBR/#environment-maps
// [4] "An Inexpensive BRDF Model for Physically based Rendering" by Christophe Schlick
//     https://www.cs.virginia.edu/~jdl/bib/appearance/analytic%20models/schlick94b.pdf
const fragmentShader = `
precision highp float;

uniform float u_ColorBoost;
uniform vec3 u_ambientLightColor;
uniform float u_daytimePercentage;
uniform float u_DayNightChange;

#if NUM_POINT_LIGHTS > 0
struct PointLight {
    vec3 position;
    vec3 color;
    float distance;
    float decay;
};
uniform PointLight pointLights[NUM_POINT_LIGHTS];
#endif

#if NUM_DIR_LIGHTS > 0
struct DirectionalLight {
    vec3 direction;
    vec3 color;
};
uniform DirectionalLight directionalLights[NUM_DIR_LIGHTS];
#endif

// uniform vec3 u_LightDirection;
// uniform vec3 u_LightColor;
uniform float u_EnvContrib;

#ifdef USE_IBL
// uniform samplerCube u_DiffuseEnvSampler;
uniform samplerCube u_SpecularEnvSampler;
uniform sampler2D u_brdfLUT;
uniform float u_EnvBlur;
#endif

// anisotropy
#ifdef USE_ANISOTROPIC
uniform float u_AnisoAngle;
#ifdef HAS_ANISOTROPIC_MAP
uniform sampler2D u_AnisotropicSampler;
#endif
#endif

#ifdef HAS_BASECOLORMAP
uniform sampler2D u_BaseColorSampler;
#endif
#ifdef HAS_NORMALMAP
uniform sampler2D u_NormalSampler;
uniform float u_NormalScale;
#endif
#ifdef HAS_EMISSIVEMAP
uniform sampler2D u_EmissiveSampler;
uniform vec3 u_EmissiveFactor;
#endif
#ifdef HAS_METALROUGHNESSMAP
uniform sampler2D u_MetallicRoughnessSampler;
#endif
#ifdef HAS_OCCLUSIONMAP
uniform sampler2D u_OcclusionSampler;
uniform float u_OcclusionStrength;
#endif
uniform float u_MetallicRoughnessValuex;
uniform float u_MetallicRoughnessValuey;
uniform vec2 u_MetallicRoughnessValues;
uniform vec3 u_BaseColorFactor;

uniform float u_Clearcoat;
uniform float u_ClearcoatRoughness;


#ifdef USE_TWOCOLOR
uniform float u_ColorChangeScaling;
uniform vec3 u_TwoColorStart;
uniform vec3 u_TwoColorEnd;
#endif

#ifdef USE_IRIDESCENCE
uniform vec3 u_IridescentStartColor;
uniform float u_IridescentScale;
uniform float u_IridescentDesaturate;
uniform float u_IridescentFalloff;
uniform float u_IridescentContrib;
#endif

// uniform vec3 u_Camera;

// debugging flags used for shader output of intermediate PBR variables
uniform vec4 u_ScaleDiffBaseMR;
uniform vec4 u_ScaleFGDSpec;
uniform vec4 u_ScaleIBLAmbient;

varying vec3 v_PositionWorld;
varying vec3 v_Position;

varying vec2 v_UV;

#ifdef HAS_NORMALS
#ifdef HAS_TANGENTS
varying mat3 v_TBN;
#else
varying vec3 v_NormalWorld;
varying vec3 v_Normal;
#endif
#endif

// Encapsulate the various inputs used by the various functions in the shading equation
// We store values in this struct to simplify the integration of alternative implementations
// of the shading terms, outlined in the Readme.MD Appendix.
struct PBRInfo
{
    float NdotL;                  // cos angle between normal and light direction
    float NdotV;                  // cos angle between normal and view direction
    float NdotH;                  // cos angle between normal and half vector
    float LdotH;                  // cos angle between light direction and half vector
    float VdotH;                  // cos angle between view direction and half vector
    float perceptualRoughness;    // roughness value, as authored by the model creator (input to shader)
    float metalness;              // metallic value at the surface
    vec3 reflectance0;            // full reflectance color (normal incidence angle)
    vec3 reflectance90;           // reflectance color at grazing angle
    float alphaRoughness;         // roughness mapped to a more linear change in the roughness (proposed by [2])
    float clearcoatAlphaRoughness;
    vec3 diffuseColor;            // color contribution from diffuse lighting
    vec3 specularColor;           // color contribution from specular lighting
};



const float M_PI = 3.141592653589793;
const float c_MinRoughness = 0.04;

vec4 SRGBtoLINEAR(vec4 srgbIn)
{
    #ifdef MANUAL_SRGB
    #ifdef SRGB_FAST_APPROXIMATION
    vec3 linOut = pow(srgbIn.xyz,vec3(2.2));
    #else //SRGB_FAST_APPROXIMATION
    vec3 bLess = step(vec3(0.04045),srgbIn.xyz);
    vec3 linOut = mix( srgbIn.xyz/vec3(12.92), pow((srgbIn.xyz+vec3(0.055))/vec3(1.055),vec3(2.4)), bLess );
    #endif //SRGB_FAST_APPROXIMATION
    return vec4(linOut,srgbIn.w);;
    #else //MANUAL_SRGB
    return srgbIn;
    #endif //MANUAL_SRGB
}

// Find the normal for this fragment, pulling either from a predefined normal map
// or from the interpolated mesh normal and tangent attributes.
vec3 getNormal()
{
    // Retrieve the tangent space matrix

	//if we don't have tangents -
#ifndef HAS_TANGENTS
    vec3 pos_dx = dFdx(v_Position);
    vec3 pos_dy = dFdy(v_Position);
    vec3 tex_dx = dFdx(vec3(v_UV, 0.0));
    vec3 tex_dy = dFdy(vec3(v_UV, 0.0));
    vec3 t = (tex_dy.t * pos_dx - tex_dx.t * pos_dy) / (tex_dx.s * tex_dy.t - tex_dy.s * tex_dx.t);

#ifdef HAS_NORMALS
    vec3 ng = normalize(v_Normal);
#else
    vec3 ng = cross(pos_dx, pos_dy);
#endif

    t = normalize(t - ng * dot(ng, t));
    vec3 b = normalize(cross(ng, t));
    mat3 tbn = mat3(t, b, ng);
#else // HAS_TANGENTS
    mat3 tbn = v_TBN;
#endif

#ifdef HAS_NORMALMAP
    vec3 n = texture2D(u_NormalSampler, v_UV).rgb;
    n = normalize(tbn * ((2.0 * n - 1.0) * vec3(u_NormalScale, u_NormalScale, 1.0)));
#else
    // The tbn matrix is linearly interpolated, so we need to re-normalize
    vec3 n = normalize(tbn[2].xyz);
#endif

    return n;
}

// Calculation of the lighting contribution from an optional Image Based Light source.
// Precomputed Environment Maps are required uniform inputs and are computed as outlined in [1].
// See our README.md on Environment Maps [3] for additional discussion.
#ifdef USE_IBL
vec3 getIBLContribution(PBRInfo pbrInputs, vec3 n, vec3 reflection)
{
    float mipCount = 9.0; // resolution of 512x512

	// this one blurs as roughness increases
	// float lod = (pbrInputs.perceptualRoughness * mipCount);
	//this one blurs as our own roughness increases
    float lod = (u_EnvBlur * mipCount);
    // retrieve a scale and bias to F0. See [1], Figure 3
    vec3 brdf = SRGBtoLINEAR(texture2D(u_brdfLUT, vec2(pbrInputs.NdotV, 1.0 - pbrInputs.perceptualRoughness))).rgb;

    // unmirroring the skybox
    vec3 reflection_flipx = vec3(-reflection.x, reflection.y, reflection.z);

#ifdef USE_TEX_LOD
    vec3 specularLight = SRGBtoLINEAR(textureCubeLodEXT(u_SpecularEnvSampler, reflection_flipx, lod)).rgb;
#else
    vec3 specularLight = SRGBtoLINEAR(textureCube(u_SpecularEnvSampler, reflection_flipx)).rgb;
#endif

    // vec3 specular = specularLight * (pbrInputs.specularColor * brdf.x + brdf.y);
    vec3 specular = specularLight * pbrInputs.specularColor;

    // specular *= vec3(u_EnvContrib);
    specular *= vec3(u_EnvContrib);

	return specular;

    // For presentation, this allows us to disable IBL terms
    specular *= u_ScaleIBLAmbient.y;

    return specular;
}
#endif

// Basic Lambertian diffuse
// Implementation from Lambert's Photometria https://archive.org/details/lambertsphotome00lambgoog
// See also [1], Equation 1
vec3 diffuse(PBRInfo pbrInputs)
{
    return pbrInputs.diffuseColor / M_PI;
}

// The following equation models the Fresnel reflectance term of the spec equation (aka F())
// Implementation of fresnel from [4], Equation 15
vec3 specularReflection(PBRInfo pbrInputs)
{
    return pbrInputs.reflectance0 + (pbrInputs.reflectance90 - pbrInputs.reflectance0) * pow(clamp(1.0 - pbrInputs.VdotH, 0.0, 1.0), 5.0);
}

// This calculates the specular geometric attenuation (aka G()),
// where rougher material will reflect less light back to the viewer.
// This implementation is based on [1] Equation 4, and we adopt their modifications to
// alphaRoughness as input as originally proposed in [2].
float geometricOcclusion(PBRInfo pbrInputs)
{
    float NdotL = pbrInputs.NdotL;
    float NdotV = pbrInputs.NdotV;
    float r = pbrInputs.alphaRoughness;

    float attenuationL = 2.0 * NdotL / (NdotL + sqrt(r * r + (1.0 - r * r) * (NdotL * NdotL)));
    float attenuationV = 2.0 * NdotV / (NdotV + sqrt(r * r + (1.0 - r * r) * (NdotV * NdotV)));
    return attenuationL * attenuationV;
}

// The following equation(s) model the distribution of microfacet normals across the area being drawn (aka D())
// Implementation from "Average Irregularity Representation of a Roughened Surface for Ray Reflection" by T. S. Trowbridge, and K. P. Reitz
// Follows the distribution function recommended in the SIGGRAPH 2013 course notes from EPIC Games [1], Equation 3.
float microfacetDistribution(float roughness, float NdotH)
{
    float roughnessSq = roughness * roughness;
    float f = (NdotH * roughnessSq - NdotH) * NdotH + 1.0;
    return roughnessSq / (M_PI * f * f);
}

// All components are in the range [0â€¦1], including hue.
vec3 rgb2hsv(vec3 c)
{
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}

// All components are in the range [0â€¦1], including hue.
vec3 hsv2rgb(vec3 c)
{
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}

vec4 fragmentColor(vec3 lightDirection, vec3 lightColor)
{
    // Metallic and Roughness material properties are packed together
    // In glTF, these factors can be specified by fixed scalar values
    // or from a metallic-roughness map
    float perceptualRoughness = u_MetallicRoughnessValuey;
    float perceptualClearcoatRoughness = u_ClearcoatRoughness;
    float metallic = u_MetallicRoughnessValuex;
#ifdef HAS_METALROUGHNESSMAP
    // Roughness is stored in the 'g' channel, metallic is stored in the 'b' channel.
    // This layout intentionally reserves the 'r' channel for (optional) occlusion map data
    vec4 mrSample = texture2D(u_MetallicRoughnessSampler, v_UV);
    perceptualRoughness = mrSample.g * perceptualRoughness;
    metallic = mrSample.b * metallic;
#endif
    perceptualRoughness = clamp(perceptualRoughness, c_MinRoughness, 1.0);
    metallic = clamp(metallic, 0.0, 1.0);
    // Roughness is authored as perceptual roughness; as is convention,
    // convert to material roughness by squaring the perceptual roughness [2].
    float alphaRoughness = perceptualRoughness * perceptualRoughness;
    float clearcoatAlphaRoughness = perceptualClearcoatRoughness * perceptualClearcoatRoughness;

    // The albedo may be defined from a base texture or a flat color
#ifdef HAS_BASECOLORMAP
    vec4 baseColor = SRGBtoLINEAR(texture2D(u_BaseColorSampler, v_UV)) * vec4(u_BaseColorFactor, 1);
#else
    vec4 baseColor = vec4(u_BaseColorFactor, 1);
#endif

    vec3 f0 = vec3(0.04);
    vec3 diffuseColor = baseColor.rgb * (vec3(1.0) - f0);
    diffuseColor *= 1.0 - metallic;
    vec3 specularColor = mix(f0, baseColor.rgb, metallic);

    // Compute reflectance.
    float reflectance = max(max(specularColor.r, specularColor.g), specularColor.b);

    // For typical incident reflectance range (between 4% to 100%) set the grazing reflectance to 100% for typical fresnel effect.
    // For very low reflectance range on highly diffuse objects (below 4%), incrementally reduce grazing reflecance to 0%.
    float reflectance90 = clamp(reflectance * 25.0, 0.0, 1.0);
    vec3 specularEnvironmentR0 = specularColor.rgb;
    vec3 specularEnvironmentR90 = vec3(1.0, 1.0, 1.0) * reflectance90;

    vec3 n = getNormal();                             // normal at surface point
    vec3 v = normalize(-v_Position);        // Vector from surface point to camera
    vec3 l = normalize(lightDirection);             // Vector from surface point to light
    vec3 h = normalize(l+v);                          // Half vector between both l and v

    float NdotL = clamp(dot(n, l), 0.001, 1.0);
    float NdotV = clamp(abs(dot(n, v)), 0.001, 1.0);
    float NdotH = clamp(dot(n, h), 0.0, 1.0);
    float LdotH = clamp(dot(l, h), 0.0, 1.0);
    float VdotH = clamp(dot(v, h), 0.0, 1.0);

    vec3 n_surface = normalize(v_Normal);
    float NdotH_surface = clamp(dot(n_surface, h), 0.0, 1.0);

    PBRInfo pbrInputs = PBRInfo(
        NdotL,
        NdotV,
        NdotH,
        LdotH,
        VdotH,
        perceptualRoughness,
        metallic,
        specularEnvironmentR0,
        specularEnvironmentR90,
        alphaRoughness,
        clearcoatAlphaRoughness,
        diffuseColor,
        specularColor
    );

    // Calculate the shading terms for the microfacet specular shading model
    vec3 F = specularReflection(pbrInputs);
    float G = geometricOcclusion(pbrInputs);
    float D = microfacetDistribution(pbrInputs.alphaRoughness, pbrInputs.NdotH);
    float D_clearcoat = microfacetDistribution(pbrInputs.clearcoatAlphaRoughness, NdotH_surface);

    // Calculation of analytical lighting contribution
    vec3 diffuseContrib = (1.0 - F) * diffuse(pbrInputs);
    vec3 specContrib = F * G * D / (4.0 * NdotL * NdotV);
    vec3 specContrib_clearcoat = u_Clearcoat * (F * G * D_clearcoat / (4.0 * NdotL * NdotV));

#ifdef USE_TWOCOLOR
    float twoColorFrac = clamp(acos(NdotH) / radians(90.0) * u_ColorChangeScaling, 0.0, 1.0);
    vec3 twoColorColor = mix(u_TwoColorStart, u_TwoColorEnd, twoColorFrac);
    specContrib *= twoColorColor;
#endif

#ifdef USE_IRIDESCENCE
    vec3 iridescentColor = rgb2hsv(u_IridescentStartColor);
    float iridescentAmount = u_IridescentScale * (1.0 - NdotH);
    iridescentColor.x -= iridescentAmount;
    iridescentColor.y *= clamp(iridescentAmount * u_IridescentDesaturate, 0.0, 1.0);
    iridescentColor.z = clamp((NdotH - 1.0) * u_IridescentFalloff + 1.0, 0.0, 1.0);



	specContrib *= mix( vec3(1.0),
						mix(hsv2rgb(iridescentColor), vec3(1.0),  perceptualRoughness),
						u_IridescentContrib);


#endif

    // Obtain final intensity as reflectance (BRDF) scaled by the energy of the light (cosine law)
    vec3 color = NdotL * lightColor * (diffuseContrib + specContrib + specContrib_clearcoat);

    // Calculate lighting contribution from image based lighting source (IBL)
#ifdef USE_IBL
    vec3 n_world = normalize(v_NormalWorld);
    vec3 v_world = normalize(cameraPosition - v_PositionWorld);
    vec3 reflection = -normalize(reflect(v_world, n_world));
    color += getIBLContribution(pbrInputs, n_world, reflection);
#endif

    // Apply optional PBR terms for additional (optional) shading
#ifdef HAS_OCCLUSIONMAP
    float ao = texture2D(u_OcclusionSampler, v_UV).r;
    color = mix(color, color * ao, u_OcclusionStrength);
#endif

#ifdef HAS_EMISSIVEMAP
    vec3 emissive = SRGBtoLINEAR(texture2D(u_EmissiveSampler, v_UV)).rgb * u_EmissiveFactor;
    color += emissive;
#endif

    // This section uses mix to override final color for reference app visualization
    // of various parameters in the lighting equation.
    color = mix(color, F, u_ScaleFGDSpec.x);
    color = mix(color, vec3(G), u_ScaleFGDSpec.y);
    color = mix(color, vec3(D), u_ScaleFGDSpec.z);
    color = mix(color, specContrib, u_ScaleFGDSpec.w);

    color = mix(color, diffuseContrib, u_ScaleDiffBaseMR.x);
    color = mix(color, baseColor.rgb, u_ScaleDiffBaseMR.y);
    color = mix(color, vec3(metallic), u_ScaleDiffBaseMR.z);
    color = mix(color, vec3(perceptualRoughness), u_ScaleDiffBaseMR.w);

    float daytimeMultiplier = (1.0 - u_DayNightChange) + (u_DayNightChange * u_daytimePercentage);
    return vec4(color * vec3(u_ColorBoost) * daytimeMultiplier, baseColor.a);
}

#ifndef saturate
// <common> may have defined saturate() already
#define saturate( a ) clamp( a, 0.0, 1.0 )
#endif

vec3 ReinhardToneMapping(vec3 color, float toneMappingExposure) {
	color *= toneMappingExposure;
	return saturate( color / ( vec3( 1.0 ) + color ) );
}

void main() {
    vec4 color;
    color += vec4(u_ambientLightColor, 1);

    #if NUM_DIR_LIGHTS > 0
    for (int i = 0; i < NUM_DIR_LIGHTS; i++) {
        DirectionalLight d = directionalLights[i];
        color += fragmentColor(d.direction, d.color);
    }
    #endif

    #if NUM_POINT_LIGHTS > 0
    for (int i = 0; i < NUM_POINT_LIGHTS; i++) {
        PointLight l = pointLights[i];
        vec3 l_viewSpace = l.position - v_Position;
        color += fragmentColor(l_viewSpace, l.color);
    }
    #endif

    float toneMappingExposure = 0.4;
    vec3 reinhard = ReinhardToneMapping(color.rgb, toneMappingExposure);
    gl_FragColor = vec4(reinhard, 1);
}
`

class ModernShaderMaterial extends AbstractWrapMaterial {
  name:string = ''
  _defines:any = null
  _uniforms:any = null
  _folder:any = null

  constructor(name, materialProperties) {
    super()

    this.name = name

    this._defines = {
      HAS_NORMALS: false,
      HAS_BASECOLORMAP: false,
      HAS_NORMALMAP: false,
      HAS_TANGENTS: false,
      USE_IBL: false,
      USE_TEX_LOD: false, // roughness blurs reflection if true
      USE_IRIDESCENCE: false,
      USE_TWOCOLOR: false,
      USE_ANISOTROPIC: false
    }

    this._uniforms = {
			...THREE.UniformsLib['lights'],
      u_BaseColorFactor: prepareColorField({ type: "c", value: new THREE.Color(0xffffff) }),
      u_ColorBoost: { type: 'f', value: 1.0 },
      u_ambientLightColor: prepareColorField({ type: "c", value: new THREE.Color(0x000000) }),
      u_daytimePercentage: { type: 'f', value: 0.0 },
      u_DayNightChange: { type: 'f', value: 0.4 },
			u_BaseColorSampler: { type: 't', value: null },
			u_NormalSampler: { type: 't', value: null },
			u_NormalScale: { type: 'f', value: 1.0 },
      textureScale:	{ type: 'f', value: 20.0 },
      u_textureStretch: { type: 'f', value: 1.0 },
			u_Clearcoat: { type: 'f', value: 1 },
			u_MetallicRoughnessValuey: { type: 'f', value: 0.12 },
			u_MetallicRoughnessValuex: { type: 'f', value: 1.0 },
			u_ClearcoatRoughness: { type: 'f', value: 0.07 },
			//'u_DiffuseEnvSampler': { type: 't', value: t_envDiffuse },
			u_SpecularEnvSampler: { type: 't', value: null },
			u_EnvContrib: { type: 'f', value: 0.0 },
			u_EnvBlur: { type: 'f', value: 0.0 },
			u_brdfLUT: { type: 't', value: null },
			u_ColorChangeScaling: { type: 'f', value: 1.0 },
			u_TwoColorStart: prepareColorField({ type: "c", value: new THREE.Color(0xff1111) }),
			u_TwoColorEnd: prepareColorField({ type: "c", value: new THREE.Color(0x111111) }),
			u_IridescentStartColor: prepareColorField({ type: "c", value: new THREE.Color(0xff00ff) }),
			u_IridescentScale: { type: 'f', value: 5.0 },
			u_IridescentDesaturate: { type: 'f', value: 5.0 },
			u_IridescentFalloff: { type: 'f', value: 5.0 },
			u_IridescentContrib: { type: 'f', value: 1.0 },
			u_AnisoAngle: { type: 'f', value: 0.0 },
			u_AnisotropicSampler: { type: 't', value: null }
    }

    this._setValues(materialProperties)

    this._folder = null
  }

  _setValues = (materialProperties:any) => {
    for (let key in materialProperties.defines) {
      this._defines[key] = !!materialProperties.defines[key]
    }

		for (let key in materialProperties.uniforms) {
      let existing = this._uniforms[key]
      if (!existing) {
        console.error('Unrecognized uniform key `' + key + '`!')
        continue
      }

      if (existing.type === 'c') {
        existing.value = new THREE.Color(materialProperties.uniforms[key])
        existing = prepareColorField(existing)
        this._uniforms[key] = existing
      }
      else if (existing.type === 'f' || existing.type === 't') {
        existing.value = materialProperties.uniforms[key]
      }
    }
  }

  load = (basePath:string):Promise<any> => {
    if (this.getLoadPromise()) {
      return this.getLoadPromise()
    }

    // console.log('ModernShaderMaterial `' + this.name + '` loading...');

    const promises = []

    const materialPromise = shaderMaterialPromiseCreator({
			defines: this._defines,
			uniforms: this._uniforms,
			vertexShader: vertexShader,
			fragmentShader: fragmentShader,
			lights: true,
			extensions: {
					derivatives: true,
					shaderTextureLOD: true,
			}
		})

		materialPromise.then( (material) => {
			this.setRenderMaterial(material)
    })
    promises.push(materialPromise)

    let baseTexture = null
    if (this._uniforms.u_BaseColorSampler.value) {
      const baseColorPromise = AssetLibrary.loadTexture(basePath+this._uniforms.u_BaseColorSampler.value).then( (texture) => {
        baseTexture = texture
        baseTexture.wrapS = baseTexture.wrapT = THREE.RepeatWrapping
      })
      promises.push(baseColorPromise)
    }

    let flutTexture = null
    if (this._uniforms.u_brdfLUT.value) {
      const flutPromise = AssetLibrary.loadTexture(basePath+this._uniforms.u_brdfLUT.value).then( (texture) => {
        flutTexture = texture
        flutTexture.format = THREE.RGBFormat
        flutTexture.wrapS = flutTexture.wrapT = THREE.ClampToEdgeWrapping
      })
      promises.push(flutPromise)
    }

    let normalTexture = null
    if (this._uniforms.u_NormalSampler.value) {
      const normalsPromise = AssetLibrary.loadTexture(basePath+this._uniforms.u_NormalSampler.value).then( (texture) => {
        normalTexture = texture
        normalTexture.wrapS = normalTexture.wrapT = THREE.RepeatWrapping
      })
      promises.push(normalsPromise)
    }

    // const waitPromise = waitFor(250)
    // waitPromise.then( () => {
    //   console.log('ModernShaderMaterial `' + this.name + '` done waiting!');
    // })
    // promises.push(waitPromise)

    const allPromise = VoidPromise.all(promises)
    this.setLoadPromise(allPromise)
    allPromise.then( () => {
      const material = this.getRenderMaterial()
      if (baseTexture) {
        material.uniforms.u_BaseColorSampler.value = baseTexture
      }

      if (flutTexture) {
        material.uniforms.u_brdfLUT.value = flutTexture
      }

      if (normalTexture) {
        material.uniforms.u_NormalSampler.value = normalTexture
      }

      this.updateShader()
      // console.log('ModernShaderMaterial `' + this.name + '` loaded');
    })
    return allPromise
  }

  configureEnvironmentMaps = (dayEnvMap:any, nightEnvMap:any):void => {
		const material = this.getRenderMaterial()
		material.uniforms.u_SpecularEnvSampler.value = dayEnvMap

		// material.needsUpdate = true
  }

  setEnvironmentMapDaytimePercentage = (daytimePercentage:number):void => {
    const material = this.getRenderMaterial()
    material.uniforms.u_daytimePercentage.value = daytimePercentage
  }

  configureGui = (gui:dat.GUI):void => {
		const material = this.getRenderMaterial() as THREE.ShaderMaterial

    this._folder = gui.addFolder('Modern Shader')

		let obj = {
      updateShader:this.updateShader,
      exportValues:this.exportValues,
      importValues:this.importValues
    }
    this._folder.add(obj, 'updateShader').name('Update Shader')
    this._folder.add(obj, 'exportValues').name('Export')
    this._folder.add(obj, 'importValues').name('Import')

		this._folder.addColor(material.uniforms.u_BaseColorFactor, GuiFieldName).name('u_BaseColorFactor').onChange( onColorChange(material.uniforms.u_BaseColorFactor) )
    this._folder.add(material.uniforms.u_ColorBoost, 'value', 0.0, 100.0).name('Color Boost').step(1.0)

    this._folder.addColor(material.uniforms.u_ambientLightColor, GuiFieldName).name('u_ambientLightColor').onChange( onColorChange(material.uniforms.u_ambientLightColor) )
    this._folder.add(material.uniforms.u_DayNightChange, 'value', 0.0, 1.0).name('Day/Night Change').step(0.01)

		this._folder.add(material.defines, 'HAS_NORMALMAP').name('Fleck Map?').onChange(this.updateShader)

		this._folder.add(material.uniforms.u_NormalScale, 'value', 0.0, 1.0).name('Fleck intensity').step(0.01)
		this._folder.add(material.uniforms.textureScale, 'value', 0, 1000).name('Fleck Size').step(1)
    this._folder.add(material.uniforms.textureScale, 'value', 20, 30).name('(fine range)').step(0.01)
    this._folder.add(material.uniforms.u_textureStretch, 'value', 0, 10).name('(Stretch)').step(0.01)

		this._folder.add(material.defines, 'USE_IBL').name('Env Reflection?').onChange(this.updateShader)
		this._folder.add(material.defines, 'USE_TEX_LOD').name('Roughness blurs env?').onChange(this.updateShader)
		this._folder.add(material.uniforms.u_EnvBlur, 'value', 0, 1).name('Env Blur').step(0.01)
		this._folder.add(material.uniforms.u_EnvContrib, 'value', 0, 10).name('+Env').step(0.01)
		this._folder.add(material.uniforms.u_EnvContrib, 'value', 0, .1).name('(finer range)').step(0.001)

		this._folder.add(material.uniforms.u_ClearcoatRoughness, 'value', 0, 1).name('Clearcoat Rough').step(0.01)
		this._folder.add(material.uniforms.u_MetallicRoughnessValuey, 'value', 0, 1).name('roughness').step(0.01)
		this._folder.add(material.uniforms.u_MetallicRoughnessValuex, 'value', 0, 1).name('metalness').step(0.01)

		this._folder.add(material.defines, 'USE_ANISOTROPIC').name('Anisotropic?').onChange(this.updateShader)
		this._folder.add(material.uniforms.u_AnisoAngle, 'value', 0, 1).name('Aniso Angle').step(0.01)
		// gui.add(matPBR.uniforms.u_AnisotropicSampler, 't', 0, 1).name('Aniso Angle').step(0.01);
		// add map picking for anisotropic map

		var f1 = this._folder.addFolder('colors')
		// add color changing for u_BaseColorFactor
		// add color changing for u_TwoColorStart
		// add color changing for u_TwoColorEnd
		// add map picking for base color map

		f1.add(material.defines, 'HAS_BASECOLORMAP').name('Texture?').onChange(this.updateShader)
		f1.add(material.defines, 'USE_TWOCOLOR').name('Color Flip?').onChange(this.updateShader)
		f1.addColor(material.uniforms.u_TwoColorStart, GuiFieldName).name('u_TwoColorStart').onChange( onColorChange(material.uniforms.u_TwoColorStart) )
		f1.addColor(material.uniforms.u_TwoColorEnd, GuiFieldName).name('u_TwoColorEnd').onChange( onColorChange(material.uniforms.u_TwoColorEnd) )
		f1.add(material.uniforms.u_ColorChangeScaling, 'value', 0.1, 10).name('Color Flip Angle').step(0.01)
		f1.open()

		var f2 = this._folder.addFolder('iridescence');
		f2.add(material.defines, 'USE_IRIDESCENCE').onChange(this.updateShader)
		f2.addColor(material.uniforms.u_IridescentStartColor, GuiFieldName).name('u_IridescentStartColor').onChange( onColorChange(material.uniforms.u_IridescentStartColor) )
		f2.add(material.uniforms.u_IridescentContrib, 'value', 0.0, 100).name('u_IridescentContrib').step(0.01)
		f2.add(material.uniforms.u_IridescentScale, 'value', 0.1, 100).name('u_IridescentScale').step(0.01)
		f2.add(material.uniforms.u_IridescentDesaturate, 'value', 0.1, 100).name('u_IridescentDesaturate').step(0.01)
		f2.add(material.uniforms.u_IridescentFalloff, 'value', 0.1, 100).name('u_IridescentFalloff').step(0.01)
		f2.open()
  }

  unloadGui = (gui:dat.GUI):void => {
    gui.removeFolder(this._folder)
    this._folder = null
  }

  updateGui = () => {
    if (!this._folder) {
      return
    }

    this.updateGuiFolder(this._folder)
  }

  updateGuiFolder = (folder:any) => {
    for (var i in folder.__controllers) {
      folder.__controllers[i].updateDisplay()
      for (var k in folder.__folders) {
        const child = this._folder.__folders[k]
        this.updateGuiFolder(child)
      }
    }
  }

	updateShader = () => {
		const material = this.getRenderMaterial()
    material.needsUpdate = true;
  }

  exportValues = () => {
    const material = this.getRenderMaterial()

    const exportObject = {
      defines: {
        ...material.defines
      },
      uniforms: {
        u_BaseColorFactor: MaterialExportImportTranslator.exportColorField(this._uniforms.u_BaseColorFactor),
        u_ambientLightColor: MaterialExportImportTranslator.exportColorField(this._uniforms.u_ambientLightColor),
        u_ColorBoost: MaterialExportImportTranslator.exportNumberField(this._uniforms.u_ColorBoost),
        u_DayNightChange: MaterialExportImportTranslator.exportNumberField(this._uniforms.u_DayNightChange),
        //u_BaseColorSampler: 'assets/textures/modern-shader/PaintColors.png', // TODO: Config this
        //u_NormalSampler: 'assets/textures/modern-shader/PaintNormals.png',
        u_NormalScale: MaterialExportImportTranslator.exportNumberField(this._uniforms.u_NormalScale),
        textureScale: MaterialExportImportTranslator.exportNumberField(this._uniforms.textureScale),
        u_textureStretch: MaterialExportImportTranslator.exportNumberField(this._uniforms.u_textureStretch),
        u_Clearcoat: MaterialExportImportTranslator.exportNumberField(this._uniforms.u_Clearcoat),
        u_MetallicRoughnessValuey: MaterialExportImportTranslator.exportNumberField(this._uniforms.u_MetallicRoughnessValuey),
        u_MetallicRoughnessValuex: MaterialExportImportTranslator.exportNumberField(this._uniforms.u_MetallicRoughnessValuex),
        u_ClearcoatRoughness: MaterialExportImportTranslator.exportNumberField(this._uniforms.u_ClearcoatRoughness),
        // //'u_DiffuseEnvSampler': { type: 't', value: t_envDiffuse },
        // u_SpecularEnvSampler: { type: 't', value: null },
        u_EnvContrib: MaterialExportImportTranslator.exportNumberField(this._uniforms.u_EnvContrib),
        u_EnvBlur: MaterialExportImportTranslator.exportNumberField(this._uniforms.u_EnvBlur),
        //u_brdfLUT: 'assets/textures/modern-shader/brdfLUT.png',
        u_ColorChangeScaling: MaterialExportImportTranslator.exportNumberField(this._uniforms.u_ColorChangeScaling),
        u_TwoColorStart: MaterialExportImportTranslator.exportColorField(this._uniforms.u_TwoColorStart),
        u_TwoColorEnd: MaterialExportImportTranslator.exportColorField(this._uniforms.u_TwoColorEnd),
        u_IridescentStartColor: MaterialExportImportTranslator.exportColorField(this._uniforms.u_IridescentStartColor),
        u_IridescentScale: MaterialExportImportTranslator.exportNumberField(this._uniforms.u_IridescentScale),
        u_IridescentDesaturate: MaterialExportImportTranslator.exportNumberField(this._uniforms.u_IridescentDesaturate),
        u_IridescentFalloff: MaterialExportImportTranslator.exportNumberField(this._uniforms.u_IridescentFalloff),
        u_IridescentContrib: MaterialExportImportTranslator.exportNumberField(this._uniforms.u_IridescentContrib),
        u_AnisoAngle: MaterialExportImportTranslator.exportNumberField(this._uniforms.u_AnisoAngle),
        // u_AnisotropicSampler: { type: 't', value: null }
      }
    }

    MaterialExportImportTranslator.showExportUI(exportObject)
  }

  importValues = () => {
    const promise = MaterialExportImportTranslator.showImportUI()
    promise.then( (data) => {
      this._setValues(data)
      this.updateGui()
    })
    promise.catch( () => {} )
  }
}

export default ModernShaderMaterial