import * as React from "react";
import { connect } from "react-redux";
import cx from "classnames";

import AppState from "../../store/models/AppState";
import selectors from "../../store/selectors";
import RestylerSearchResult from "../../store/models/RestylerSearchResult";
import TranslationComponent from "../TranslationComponent";
import selectRestyler from "../../actionCreators/findRestyler/selectRestyler";
import contactRestyler from "../../actionCreators/findRestyler/contactRestyler";
import EnvironmentConfig from "../../../../../core/EnvironmentConfig";
import restylerSelectors from "../../store/selectors/restylers";
import RestylerClassification from "../../store/models/RestylerClassification";
import openDirectionsToRestyler from "../../actionCreators/findRestyler/openDirectionsToRestyler";
import DistanceDisplay from "./DistanceDisplay";

interface OwnProps {
  className?: string;
  innerClassName?: string;
  restyler: RestylerSearchResult;
  resultNumber: number;
  onSelected?: (restyler: RestylerSearchResult) => any;
}

interface ReduxProps {
  environmentConfig: EnvironmentConfig;
  translate: (key: string) => string;
  selectedRestyler: RestylerSearchResult;
  isSmallViewport: boolean;
  getRestylerClassifications: (
    restyler: RestylerSearchResult
  ) => Array<RestylerClassification>;
}

interface DispatchProps {
  selectRestyler: (restyler: RestylerSearchResult) => any;
  contactRestyler: (restyler: RestylerSearchResult) => any;
  openDirectionsToRestyler: (restyler: RestylerSearchResult) => any;
}

interface State {}

class RestylerCard extends React.Component<
  OwnProps & ReduxProps & DispatchProps,
  State
> {
  _root: HTMLElement;

  _setRoot = (element) => {
    this._root = element;
  };

  _onSelected = (evt) => {
    const nodeName = evt.target.nodeName.toLowerCase();
    if (nodeName === "button" || nodeName === "a") {
      return; // If it was the contact button or an anchor tag, ignore it.
    }

    this.props.selectRestyler(this.props.restyler);
    if (this.props.onSelected) {
      this.props.onSelected(this.props.restyler);
    }
  };

  _onContactSelected = () => {
    this.props.contactRestyler(this.props.restyler);
  };

  _getRootClassName = () => {
    if (!this.props.className) {
      return "";
    }

    const tokens = this.props.className.split(" ");
    return tokens[0];
  };

  render() {
    const { restyler } = this.props;

    const rootClassName = this._getRootClassName();
    const certifications = this.props.getRestylerClassifications(restyler);

    return (
      <li
        ref={this._setRoot}
        className={cx(
          this.props.className,
          this.props.restyler === this.props.selectedRestyler &&
            rootClassName + "--selected"
        )}
        onClick={this._onSelected}
      >
        <div className={this.props.innerClassName}>
          {!this.props.isSmallViewport && (
            <div className={cx(rootClassName + "--resultNumber")}>
              <h3 className="h3 marginless text--heavy">
                {this.props.resultNumber}
              </h3>
            </div>
          )}

          <div
            className={cx(rootClassName + "--titleAndDistance", "landmarklet")}
          >
            <h2 className="h2 marginless text--heavy">
              {this.props.isSmallViewport && (
                <React.Fragment>
                  <span>{this.props.resultNumber + ". "}</span>
                </React.Fragment>
              )}
              {restyler.companyName}
            </h2>
            <p className="marginless text--light text--right">
              <DistanceDisplay
                displayUnit={this.props.translate('search.distance-unit')}
                distanceInMiles={restyler.distanceInMiles}
              />
            </p>
          </div>

          <div className="landmarklet">
            {restyler.address && (
              <React.Fragment>
                <p className="marginless">{restyler.address.address1}</p>
                {restyler.address.address2 && (
                  <p>{restyler.address.address2}</p>
                )}
                {restyler.address && // There is some garbage data coming in. Make sure we have something before rendering this p tag.
                  (restyler.address.city ||
                    restyler.address.stateProvince ||
                    restyler.address.zip) && (
                    <p className="marginless">
                      {restyler.address.city}
                      {
                        restyler.address.city &&
                          ", " /* If there was no city, skip the comma. */
                      }
                      {restyler.address.stateProvince} {restyler.address.zip}
                    </p>
                  )}
              </React.Fragment>
            )}
            {restyler.phone && (
              <React.Fragment>
                <p className="marginless hidden-lap-and-up">
                  <a className="" href={"tel:" + restyler.phone}>
                    {restyler.phone}
                  </a>
                </p>
                <p className="marginless hidden-below-lap">{restyler.phone}</p>
              </React.Fragment>
            )}
            <p className="marginless">
              <a
                onClick={() => {
                  this.props.openDirectionsToRestyler(restyler);
                }}
              >
                <TranslationComponent localizationKey="search.directions" />
              </a>
            </p>
          </div>

          <div className={cx(rootClassName + "--footer")}>
            <div className={cx(rootClassName + "--classifications")}>
              {certifications.map(
                (classification: RestylerClassification, index) => {
                  return (
                    <p
                      key={classification.title}
                      className={cx(
                        index < certifications.length - 1
                          ? "landmarklet"
                          : "marginless",
                        "text--light"
                      )}
                      dangerouslySetInnerHTML={{ __html: classification.title }}
                    />
                  );
                }
              )}
            </div>
            <button className="btn" onClick={this._onContactSelected}>
              <TranslationComponent localizationKey="search.contact" />
            </button>
          </div>
        </div>
      </li>
    );
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      translate: selectors.getTranslator(state),
      environmentConfig: selectors.getEnvironmentConfig(state),
      selectedRestyler: restylerSelectors.getSelectedRestyler(state),
      isSmallViewport: selectors.isSmallViewport(state),
      getRestylerClassifications: (restyler) => {
        return restylerSelectors.getRestylerClassifications(state, restyler);
      },
    };
  },
  {
    selectRestyler,
    contactRestyler,
    openDirectionsToRestyler,
  }
)(RestylerCard);
