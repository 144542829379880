import * as React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import { animated } from 'react-spring'
import { Spring } from 'react-spring'

import AppState from '../../../store/models/AppState'
import selectors from '../../../store/selectors'
import selectFinishGroup from '../../../actionCreators/selectFinishGroup'
import selectPanelGroup from '../../../actionCreators/wraps/selectPanelGroup'
import SceneMode from '../../../../../../core/SceneMode'
import Wrap from '../../../../../../core/wraps/Wrap'
import { remap } from '../../../helpers/math'
import WrapMetaData from '../../../../../../core/wraps/WrapMetaData'
import AbstractWrapMaterial from '../../../../../../core/materials/wraps/AbstractWrapMaterial'
import DesktopColorSelector from './DesktopColorSelector'
import MaterialsIcon from '../../icons/Materials'
import AccentsIcon from '../../icons/Accents'
import PanelGroup from '../../../../../../core/vehicles/PanelGroup'
import DesktopWrapSelectionTitle from './DesktopWrapSelectionTitle'
import EnvironmentConfig from '../../../../../../core/EnvironmentConfig'
import DesktopAssetSelectorPanel from './DesktopAssetSelectorPanel'


// So we can select no wrap (sidestepping null overload issue)
let NO_WRAP_CAPTION = 'NO_WRAP_CAPTION'
let NO_WRAP_TITLE = 'NO_WRAP_TITLE'

let noWrap = new Wrap(
  new WrapMetaData(
    'xx',
    NO_WRAP_CAPTION,
    NO_WRAP_TITLE,
    'productFamily',
    'colorFamily',
    'Matte',
    '#ddd',
    null
  ),
  new AbstractWrapMaterial()
)

interface OwnProps {
  type: string
  title: string
  icon: typeof MaterialsIcon | typeof AccentsIcon
  selectedWrap: Wrap
  onBackClick: () => void
  onWrapSelected: (material: Wrap) => void
  showPanelGroupFilter: boolean
}

interface ReduxProps {
  environmentConfig: EnvironmentConfig
  sceneMode: SceneMode
  finishGroups: Array<string>
  selectedFinishGroup: string
  wraps: Array<Wrap>
  panelGroups: Array<PanelGroup>
  selectedPanelGroup: PanelGroup
  translate: (key: string) => string
}

interface DispatchProps {
  selectFinishGroup: (group: string) => void
  selectPanelGroup: (panelGroup: PanelGroup) => void // types?
}

type Props = OwnProps & ReduxProps & DispatchProps

interface State {
  hoveredWrap: Wrap
}

class DesktopMaterialSelector extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      hoveredWrap: noWrap
    }
  }

  onPanelGroupSelected = (panelGroupName:string) => {
    const match = this.props.panelGroups.find( (panelGroup) => {
      const title = this.props.translate(panelGroup.localizationKey)
      return title === panelGroupName
    })

    this.props.selectPanelGroup(match)
  }

  onFinishGroupSelected = (finishGroupName:string) => {
    this.props.selectFinishGroup(finishGroupName)
  }

  render() {
    // The icon prop will be a class, so make a binding with an upcased first
    // letter, so we can render it e.g. <Icon />.
    let { icon: Icon } = this.props

    const headerOptions = []
    if (this.props.type === 'materials') {
      headerOptions.push({
        title: this.props.translate('editor.materials.entire-vehicle'),
        values: this.props.panelGroups.map((group) => {
          return this.props.translate(group.localizationKey)
        }),
        selectedValue: this.props.selectedPanelGroup ? this.props.translate(this.props.selectedPanelGroup.localizationKey) : null,
        onHeaderOptionSelected: this.onPanelGroupSelected
      })
    }

    headerOptions.push({
      title: this.props.translate('editor.materials.all'),
      values: this.props.finishGroups,
      selectedValue: this.props.selectedFinishGroup,
      onHeaderOptionSelected: this.onFinishGroupSelected
    })

    const value = 1.0
    return (
      <DesktopAssetSelectorPanel
        title={this.props.title}
        titleIcon={Icon}
        headerOptions={headerOptions}
        bodyStyles={{
          display: 'grid',
          gridAutoFlow: 'column',
          gap: '30px 30px',
          height: '100%',
          overflow: 'hidden',
          padding: '15px 55px 15px 15px'
        }}
      >
        <React.Fragment>
          <DesktopWrapSelectionTitle
            type={this.props.type}
            value={value}
            noWrap={noWrap}
            wrap={this.state.hoveredWrap || this.props.selectedWrap}
          />

          <animated.div
            style={{
              position: 'relative',
              top: -5,
              height: 110,
              opacity: remap(0.9, 1, 0, 1, value),
              transform: `translateX(${remap(0.8, 1, -20, 0, value)}px)`,
              overflow: 'hidden'
            }}
          >
            <div
              onMouseLeave={() => {
                this.setState({ hoveredWrap: null })
              }}
            >
              <DesktopColorSelector
                basePath={this.props.environmentConfig.getAssetBasePath()}
                wraps={this.props.wraps}
                selectedWrap={this.props.selectedWrap}
                temporaryWrap={this.props.selectedWrap}
                onHoverWrap={(wrap) => {
                  this.setState({ hoveredWrap: wrap })
                }}
                onSelectWrap={(wrap) => {
                  this.props.onWrapSelected(wrap)
                }}
              />
            </div>
          </animated.div>
        </React.Fragment>
      </DesktopAssetSelectorPanel>
    )
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      environmentConfig: selectors.getEnvironmentConfig(state),
      sceneMode: selectors.getSelectedSceneMode(state),
      finishGroups: selectors.getAllWrapFinishGroups(state),
      selectedFinishGroup: selectors.getSelectedFinishGroup(state),
      wraps: selectors.getWrapsInSelectedFinishGroup(state),
      translate: selectors.getTranslator(state),
      panelGroups: selectors.getSelectedVehiclePanelGroups(state),
      selectedPanelGroup: selectors.getSelectedPanelGroup(state)
    }
  },
  {
    selectFinishGroup,
    selectPanelGroup
  }
)(DesktopMaterialSelector)