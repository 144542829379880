import Dispatch from '../Dispatch'
import GetState from '../GetState'
import Coordinate from '../../store/models/Coordinate'

export default (coordinate:Coordinate) => {
  if (!coordinate) {
    console.warn('setMapCoordinate called, but no coordinate was supplied! Ignoring.')
    return
  }
  
  return async (dispatch: Dispatch, getState: GetState):Promise<void> => {
    dispatch({
      type: 'SET_RESTYLER_MAP_COORDINATE',
      coordinate: coordinate
    })
  }
}