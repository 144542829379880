import Dispatch from './Dispatch'
import GetState from './GetState'
import View from '../store/models/View'
import WrapsConfigLoader from '../../../../core/wraps/WrapsConfigLoader'
import TintsConfigLoader from '../../../../core/tints/TintsConfigLoader'
import FilmsConfigLoader from '../../../../core/films/FilmsConfigLoader'
import navigateTo from './navigateTo'
import SharingUtility from '../../../../core/SharingUtility'
import environmentInstance from '../../../../core/environments/EnvironmentInstance'
import partsInstances from '../../../../core/materials/parts/PartsInstances'
import UserSelectionsLoader from '../../../../core/UserSelectionsLoader'
import DefaultsFinder from '../../../../core/DefaultsFinder'
import THREE from '../../../../core/three/threeWithExtensions'
import selectors from '../store/selectors'
import setLoadingMessage from './setLoadingMessage'
import setLoadingError from './setLoadingError'
import VoidPromise from '../../../../core/utils/VoidPromise'
import { getGPUTier } from 'detect-gpu'
import doesUrlExist from '../../../../core/utils/doesUrlExist'
import loadFormConfigIfNecessary from './loadFormConfigIfNecessary'
import { determineEnvironment, Environment } from '../../../../core/EnvironmentConfigLoader'
import showLanguageSelectionIfNecessary from './showLanguageSelectionIfNecessary'

export default (shareCode:string) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState()
    const environmentConfig = selectors.getEnvironmentConfig(state)

    dispatch({ type:'SET_IS_PRELOADING', value:true })

    if (determineEnvironment() !== Environment.Development) { // Chrome fails the GPU test when run in Responsive mode. So we don't check GPU in dev.
      dispatch(setLoadingMessage(selectors.translate(state, 'loading.webgl-test')))
      if (!THREE.WebGL.isWebGLAvailable()) {
        dispatch(setLoadingError(selectors.translate(state, 'errors.wegbl')))
        return
      }

      let GPUTier = await getGPUTier()
      console.log('performanceBlacklist:', environmentConfig.performanceBlacklist)
      console.log('GPUTier:', GPUTier)
      let formattedTier = GPUTier.isMobile ? 'GPU_MOBILE_TIER_' + GPUTier.tier : 'GPU_DESKTOP_TIER_' + GPUTier.tier
      if (environmentConfig.performanceBlacklist.indexOf(formattedTier) >= 0) {
        dispatch(setLoadingError(selectors.translate(state, 'errors.performance')))
        return
      }
    }

    dispatch(setLoadingMessage(selectors.translate(state, 'loading.wraps')))
    const wrapsLoader = new WrapsConfigLoader()
    const selectedLanguage = selectors.getLanguage(state);
    let wrapsPath = environmentConfig.getAssetFullPath('assets/js/locales/' + selectedLanguage + '/wraps.json')
    if (!await doesUrlExist(wrapsPath)) {
      wrapsPath = environmentConfig.getAssetFullPath('assets/js/wraps.json')
    }

		let wraps = null
		try {
			wraps = await wrapsLoader.load(wrapsPath)
		}
		catch(e) {
      console.warn('Unable to load wraps json file from `' + wrapsPath + '`!', e)
    }

    if (!wraps) {
      console.warn('Unable to load wraps! Aborting.')
      dispatch(setLoadingError(selectors.translate(state, 'error.wraps')))
			return
    }

    // <preload wrap swatches>
    const swatchPromises = []
    for(let i=0; i<wraps.length; i++) {
      const wrap = wraps[i]
      swatchPromises.push(wrap.loadSwatchImages(environmentConfig.getAssetBasePath()))
    }
    VoidPromise.all(swatchPromises)
    // </preload wrap swatches>

		dispatch({
			type: 'SET_WRAPS',
			wraps: wraps
    })

    dispatch(setLoadingMessage(selectors.translate(state, 'loading.tints')))
    let tintsPath = environmentConfig.getAssetFullPath('assets/js/locales/' + selectedLanguage + '/tints.json')
    if (!await doesUrlExist(tintsPath)) {
      tintsPath = environmentConfig.getAssetFullPath('assets/js/tints.json')
    }

    let tints = null
    try {
      const tintsLoader = new TintsConfigLoader()
      tints = await tintsLoader.load(tintsPath)
    }
    catch(e) {
      console.warn('Unable to load tints json file from `' + tintsPath + '`!', e)
    }

    if (!tints) {
      console.warn('Unable to load tints! Aborting.')
      dispatch(setLoadingError(selectors.translate(state, 'error.tints')))
			return
    }

    dispatch({
      type: 'SET_TINTS',
      tints: tints
    })

    dispatch(setLoadingMessage(selectors.translate(state, 'loading.films')))

    let filmsPath = environmentConfig.getAssetFullPath('assets/js/locales/' + selectedLanguage + '/films.json')
    if (!await doesUrlExist(filmsPath)) {
      filmsPath = environmentConfig.getAssetFullPath('assets/js/films.json')
    }

    let films = null
    try {
      const filmsLoader = new FilmsConfigLoader()
      films = await filmsLoader.load(filmsPath)
    }
    catch(e) {
      console.warn('Unable to load films json file from `' + filmsPath + '`!', e)
    }

    if (!films) {
      console.warn('Unable to load films! Aborting.')
      dispatch(setLoadingError(selectors.translate(state, 'error.films')))
			return
    }

    dispatch({
      type: 'SET_FILMS',
      films: films
    })

    dispatch(setLoadingMessage(selectors.translate(state, 'loading.environment')))
    try {
      await environmentInstance.load(environmentConfig.getAssetBasePath())
    }
    catch(e) {
      console.warn('Unable to load environment! Aborting.', e)
      dispatch(setLoadingError(selectors.translate(state, 'errors.environment')))
      return
    }

    dispatch(setLoadingMessage(selectors.translate(state, 'loading.parts')))
    try {
      await partsInstances.load(environmentConfig.getAssetBasePath())
    }
    catch(e) {
      console.warn('Unable to load environment! Aborting.', e)
      dispatch(setLoadingError(selectors.translate(state, 'errors.parts')))
      return
    }

    dispatch(loadFormConfigIfNecessary())

    dispatch(setLoadingMessage(selectors.translate(state, 'loading.performance-testing')))

    const vehicles = selectors.getVehicles(state)
    const defaultTint = DefaultsFinder.getDefaultTint(tints)
    const defaultWrap = DefaultsFinder.getDefaultWrap(wraps)

    let	selections = SharingUtility.selectionsFromCode(shareCode.toString(), vehicles, tints, wraps, films)
		if (!selections) {
      console.warn('Unable to load selections from code `' + shareCode + '`. Reverting to normal path.')
      donePreloading(dispatch, View.VehicleSelection)
			return
		}

    dispatch(setLoadingMessage(selectors.translate(state, 'loading.selections')))
    try {
      await UserSelectionsLoader.load(environmentConfig.getAssetBasePath(), selections, defaultTint, defaultWrap)
      dispatch({
        type: 'SET_SELECTIONS',
        selections: selections
      })
      donePreloading(dispatch, View.VehicleEdit)
    }
    catch(e) {
      console.warn('Unable to load selections from code `' + shareCode + '`. Reverting to normal path.')
      donePreloading(dispatch, View.VehicleSelection)
    }

    dispatch(showLanguageSelectionIfNecessary())
  }
}

const donePreloading = (dispatch: Dispatch, view:any):void => {
  dispatch(navigateTo(view, false))

  // Needed a little delay to give the frames a chance to completely render. This prevents some flickering while the scene loads.
  setTimeout(() => {
    dispatch({ type:'SET_IS_PRELOADING', value:false })
  }, 500)
}