import * as React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import { animated } from 'react-spring'

import AppState from '../../../store/models/AppState'
import selectors from '../../../store/selectors'
import Wrap from '../../../../../../core/wraps/Wrap'
import { remap } from '../../../helpers/math'
import EnvironmentConfig from '../../../../../../core/EnvironmentConfig'

interface OwnProps {
  value: number,
  noWrap: Wrap,
  wrap: Wrap,
  type: string
}

interface ReduxProps {
  environmentConfig: EnvironmentConfig
  translate: (key: string) => string
}

interface DispatchProps {
}

interface State {
}

class DesktopWrapSelectionTitle extends React.Component<OwnProps & ReduxProps & DispatchProps, State> {
  render() {
    return <animated.div
      className='wrap-selection-title'
      style={{
        opacity: remap(0.75, 1, 0, 1, this.props.value),
        transform: `translateX(${remap(0.5, 1, 80, 0, this.props.value)}px)`
      }}
    >
      <div className='wrap-selection-title__swatch'
        style={{
          backgroundColor: this.determineBackgroundColor(),
          backgroundImage: this.determineBackgroundImage(this.props.environmentConfig.getAssetBasePath())
        }}
      />
      <div className={cx('wrap-selection-title__text', this.props.type && this.props.type+"Colors")}>
        <div>
          <b>
            {this.hasRealWrap() && this.props.translate(this.props.wrap.meta.name)}
            {!this.hasRealWrap() && this.props.translate('editor.no-selection.title')}
          </b>
        </div>
        <div>
          {this.hasRealWrap() && this.props.wrap.meta.code}
          {!this.hasRealWrap() && this.props.translate('editor.no-selection.caption')}
        </div>
      </div>
    </animated.div>
  }

  hasRealWrap = ():boolean => {
    if (!this.props.wrap) {
      return false
    }

    if (this.props.wrap === this.props.noWrap) {
      return false
    }

    return true
  }

  determineBackgroundColor = ():string => {
    if (this.hasRealWrap()) {
      return this.props.wrap.meta.swatchColor
    }

    return '#ddd'
  }

  determineBackgroundImage = (basePath:string):string => {
    if (!this.hasRealWrap()) {
      return null
    }

    if (this.props.wrap.meta.swatchImage) {
      return this.wrapInUrl(basePath+this.props.wrap.meta.swatchImage)
    }

    return this.wrapInUrl(basePath+'assets/images/wrap-overlays/' + this.props.translate(this.props.wrap.meta.finishGroup) + '.png')
  }

  wrapInUrl = (url:string) => {
    return 'url("' + url + '")'
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      environmentConfig: selectors.getEnvironmentConfig(state),
      translate: selectors.getTranslator(state)
    }
  },
  { }
)(DesktopWrapSelectionTitle)