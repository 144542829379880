import * as React from 'react'
import { animated, useSpring } from 'react-spring'
import cx from 'classnames'
import TranslationComponent from '../../TranslationComponent'

import ArrowIcon from '../../icons/Arrow'

interface Props {
  onClick: () => void
  side: 'left' | 'right'
  i18nKey: string
}

function DesktopOpenButton(props:Props) {
  let [hovered, setHovered] = React.useState(false)
  let slide = useSpring({ x: hovered ? (props.side === 'left' ? 10 : -10) : 0 })
  let translateDir = props.side === 'left' ? -1 : 1
  let text = (
    <span
      key="text"
      className={cx("cvs-open-button__text", props.side === 'right' && 'yd')}
    >
      <TranslationComponent localizationKey={props.i18nKey} />
    </span>
  )
  let arrow = (
    <animated.div
      key="arrow"
      className={cx("cvs-open-button__button__svg-wrapper")}
      style={{
        transform: slide.x.interpolate((x) => `translateX(${x}px)`)
      }}
    >
      <ArrowIcon direction={props.side === 'left' ? 'right' : 'left'} forceWidth={23} forceHeight={23} />
    </animated.div>
  )
  let buttonChildren = props.side === 'right' ? [text, arrow] : [arrow, text]
  return (
    <div
      className="cvs-open-button"
      style={{ [props.side]: 0 }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <animated.div>
        <button
          className="cvs-open-button__button"
          onClick={props.onClick}
          children={buttonChildren}
        />
      </animated.div>
    </div>
  )
}

export default DesktopOpenButton