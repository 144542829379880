import * as React from 'react'
import { connect } from 'react-redux'
import OptionCategoryButton from '../OptionCategoryButton'
import SceneMode from '../../../../../../core/SceneMode'

import AccentsIcon from '../../icons/Accents'
import MaterialsIcon from '../../icons/Materials'
import TintsIcon from '../../icons/Tints'
import ShieldInactive from '../../icons/shields/ShieldInactive'
import ShieldActive from '../../icons/shields/ShieldActive'
import DesktopOpenButton from './DesktopOpenButton'

import openPanelGroupSelector from '../../../actionCreators/editorPanels/openPanelGroupSelector'
import openWindowTintPanel from '../../../actionCreators/editorPanels/openWindowTintPanel'
import openAccentPanel from '../../../actionCreators/editorPanels/openAccentPanel'
import selectPanelGroup from '../../../actionCreators/wraps/selectPanelGroup'
import openWrapsPanel from '../../../actionCreators/editorPanels/openWrapsPanel'
import openPpfPanel from '../../../actionCreators/editorPanels/openPpfPanel'
import openChangeVehiclePanel from '../../../actionCreators/editorPanels/openChangeVehiclePanel'
import openYourDesignPanel from '../../../actionCreators/editorPanels/openYourDesignPanel'
import EditorPanelState from '../../../store/models/EditorPanelState'
import selectors from '../../../store/selectors'
import AppState from '../../../store/models/AppState'
import PaintProtectionFilm from '../../../../../../core/films/PaintProtectionFilm'

interface OwnProps { }

interface ReduxProps {
  sceneMode:SceneMode
  editorPanelState: EditorPanelState
  selectedFilm: PaintProtectionFilm|null
  paintProtectionFilmsEnabled: boolean
}

interface DispatchProps {
  openWrapsPanel: () => void
  openWindowTintPanel: () => void
  openAccentPanel: () => void
  openPpfPanel: () => void
  openChangeVehiclePanel: () => void
  openYourDesignPanel: () => void
}

type Props = OwnProps & ReduxProps & DispatchProps

function DesktopButtons(props: Props) {
  const visible = props.editorPanelState === EditorPanelState.Closed

  return (
    <React.Fragment>
      <div 
        className="option-select-container"
        style={{
          opacity: visible ? 1 : 0,
          pointerEvents: visible ? 'auto' : 'none',
          zIndex: visible ? 1002 : -1
        }}
      >
        <OptionCategoryButton
          icon={<MaterialsIcon />}
          sceneMode={props.sceneMode}
          onClick={props.openWrapsPanel}
          forceHover={false}
        />

        <OptionCategoryButton
          icon={<AccentsIcon />}
          sceneMode={props.sceneMode}
          onClick={props.openAccentPanel}
          forceHover={false}
        />
        
        <OptionCategoryButton
          icon={<TintsIcon />}
          sceneMode={props.sceneMode}
          onClick={props.openWindowTintPanel}
          forceHover={false}
        />
        
        {props.paintProtectionFilmsEnabled && (
          <OptionCategoryButton
            className={props.selectedFilm ? 'option-category-button--selected' : ''}
            icon={props.selectedFilm ? <ShieldActive width={45} /> : <ShieldInactive width={35} />}
            sceneMode={props.sceneMode}
            onClick={props.openPpfPanel}
            forceHover={false}
          />
        )}
      </div>

      <DesktopOpenButton
        i18nKey="editor.your-design"
        side="right"
        onClick={props.openYourDesignPanel}
      />

      <DesktopOpenButton
        i18nKey="editor.change-vehicle"
        side="left"
        onClick={props.openChangeVehiclePanel}
      />
    </React.Fragment>
  )
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      sceneMode: selectors.getSelectedSceneMode(state),
      editorPanelState: selectors.getEditorPanelState(state),
      selectedFilm: selectors.getSelectedPaintProtectionFilm(state),
      paintProtectionFilmsEnabled: selectors.isPaintProtectionFilmsEnabled(state)
    }
  },
  {
    openPanelGroupSelector,
    openWindowTintPanel,
    openWrapsPanel,
    openAccentPanel,
    openPpfPanel,
    openChangeVehiclePanel,
    openYourDesignPanel,
    selectPanelGroup
  }
)(DesktopButtons)
