import * as React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import { animated, useSpring } from 'react-spring'

import AppState from '../../store/models/AppState'
import selectors from '../../store/selectors'
import EditorPanelState from '../../store/models/EditorPanelState'
import setEditorPanelState from '../../actionCreators/editorPanels/setEditorPanelState'
import removeTint from '../../actionCreators/tints/removeTint'
import removeAccentWrap from '../../actionCreators/accents/removeAccentWrap'
import selectWrapForPanelGroup from '../../actionCreators/selectWrapForPanelGroup'
import removeAllVehicleSelections from '../../actionCreators/removeAllVehicleSelections'
import openPanelGroup from '../../actionCreators/openPanelGroup'
import Tint from '../../../../../core/tints/Tint'
import Wrap from '../../../../../core/wraps/Wrap'
import PanelGroup from '../../../../../core/vehicles/PanelGroup'
import TranslationComponent from '../TranslationComponent'
import { remap } from '../../helpers/math'
import PaintProtectionFilm from '../../../../../core/films/PaintProtectionFilm'
import removePaintProtectionFilm from '../../actionCreators/films/removePaintProtectionFilm'
import ShieldFactory from '../../components/icons/shields/ShieldFactory'
// SVGs
import ArrowIcon from '../icons/Arrow'
import CancelIcon from '../icons/Cancel'
import EditIcon from '../icons/Edit'

import Scrim from './Scrim'

interface OwnProps {}

interface ReduxProps {
  desktop: boolean
  editorState: EditorPanelState
  selectedTint: Tint
  selectedAccent: Wrap
  panelGroups: Array<PanelGroup>
  selectedPaintProtectionFilm: PaintProtectionFilm|null
  getSelectedWrapForPanelGroup: (panelGroup: PanelGroup) => Wrap
  translate: (key: string) => string
}

interface DispatchProps {
  setEditorPanelState: (panelState: EditorPanelState) => void
  removeTint: () => void
  removeAccentWrap: () => void
  selectWrapForPanelGroup: (panelGroup: PanelGroup, wrap: Wrap) => void
  openPanelGroup: (panelGroup: PanelGroup) => void
  removeAllVehicleSelections: () => void
  removePaintProtectionFilm: () => void
}

type Props = OwnProps & ReduxProps & DispatchProps

function YourDesignSelector(props: Props) {
  let isOpen = props.editorState === EditorPanelState.YourDesignOpen
  let openSpring = useSpring({ value: isOpen ? 1 : 0 })

  let designItems = designItemsFromProps(props)

  return (
    <React.Fragment>
      <Scrim
        show={isOpen}
        openSpring={openSpring.value}
        onClick={() => {
          props.setEditorPanelState(EditorPanelState.Closed)
        }}
      />

      <animated.div
        className="your-design"
        style={{
          pointerEvents: isOpen ? 'all' : 'none',
          opacity: openSpring.value.interpolate((v) =>
            remap(0.5, 1, 0, 1, v)
          ),
          transform: openSpring.value.interpolate(
            (v) => `translate(${remap(0.5, 1, 10, 0, v)}%, -50%)`
          )
        }}
      >
        <button 
          className='your-design--back' 
          onClick={() => { props.setEditorPanelState(EditorPanelState.Closed) }}
        >
          <ArrowIcon direction="left" width={30} />
        </button>

        {designItems.length <= 0 && (
          <div className='no-selections'>
            <TranslationComponent localizationKey="editor.no-selections" />
          </div>
        )}
        
        {designItems.length > 0 && (
          <React.Fragment>
            <ul className="v-list">
              {designItems.map((props, index) => (
                <DesignItem key={index} {...props} />
              ))}
            </ul>
            <button
              onClick={props.removeAllVehicleSelections}
              className="red-on-hover clear-all"
            >
              <TranslationComponent localizationKey={'editor.clear-all'} />
            </button>
          </React.Fragment>
        )}
      </animated.div>

      <svg className="scrim-circle scrim-circle--right">
        <animated.circle
          cx="100%"
          cy={window.innerHeight / 2}
          r={openSpring.value.interpolate((v) => v * 768)}
          fill="white"
        />
        <animated.circle
          cx="100%"
          cy={window.innerHeight / 2}
          r={openSpring.value.interpolate((v) => v * 746)}
          fill="none"
          stroke="#ddd"
        />
      </svg>
    </React.Fragment>
  )
}

function Swatch(props: { color: string; finishGroup: string }) {
  return (
    <div
      className={cx("yd-swatch", "image-"+props.finishGroup)}
      style={{
        backgroundColor: props.color
      }}
    />
  )
}

type DesignItemProps = {
  name: string
  groupName: string
  groupKey: string
  swatch?: {
    color: string,
    finishGroup: string
  },
  icon?: any,
  onEditClick: () => void
  onRemoveClick: () => void
  desktop: boolean
}
function DesignItem(props: DesignItemProps) {
  return (
    <li className="your-design--item">
      {props.swatch && (
        <Swatch color={props.swatch.color} finishGroup={props.swatch.finishGroup} />
      )}
      {props.icon}

      <div className={cx("your-design--item-info", props.groupKey)}>
        <div className="your-design--item-info__group">{props.groupName}</div>
        <div className="your-design--item-info__name" title={props.name}>
          {props.name}
        </div>
      </div>

      <div className="your-design--item-actions">
        <button onClick={props.onRemoveClick}>
          <CancelIcon />
        </button>
        <button
          onClick={props.onEditClick}
          className='pencil'
        >
          <EditIcon />
        </button>
      </div>
    </li>
  )
}

function designItemsFromProps(props: Props) {
  let designItems: Array<DesignItemProps> = []
  props.panelGroups.forEach((panelGroup) => {
    const wrap = props.getSelectedWrapForPanelGroup(panelGroup)
    if (!wrap) {
      return
    }

    designItems.push({
      swatch: {
        color: wrap.meta.swatchColor,
        finishGroup: wrap.meta.finishGroup
      },
      groupName: props.translate(panelGroup.localizationKey),
      groupKey: panelGroup.key+"-group",
      name: wrap.meta.code + ' ' + props.translate(wrap.meta.name),
      onEditClick: () => {
        props.openPanelGroup(panelGroup)
      },
      onRemoveClick: () => {
        props.selectWrapForPanelGroup(panelGroup, null)
      },
      desktop: props.desktop
    })
  })

  if (props.selectedTint) {
    designItems.push({
      swatch: {
        color: props.selectedTint.swatchColor,
        finishGroup: 'Gloss'
      },
      groupName: props.translate('editor.tints.title-singular'),
      groupKey: "window-group",
      name: props.selectedTint.code + ' ' + props.translate(props.selectedTint.name),
      onEditClick: () => {
        props.setEditorPanelState(EditorPanelState.SelectTintOpen)
      },
      onRemoveClick: props.removeTint,

      desktop: props.desktop
    })
  }
  
  if (props.selectedAccent) {
    designItems.push({
      swatch: {
        color: props.selectedAccent.meta.swatchColor,
        finishGroup: props.selectedAccent.meta.finishGroup,
      },
      groupName: props.translate('editor.accents.title-singular'),
      groupKey: "accent-group",
      name: props.selectedAccent.meta.code + ' ' + props.translate(props.selectedAccent.meta.name),
      onEditClick: () => {
        props.setEditorPanelState(EditorPanelState.SelectAccentOpen)
      },
      onRemoveClick: props.removeAccentWrap,
      desktop: props.desktop
    })
  }

  if (props.selectedPaintProtectionFilm) {
    designItems.push({
      icon: <div className='yd-icon'>
        {ShieldFactory.tryToGetIcon(props.selectedPaintProtectionFilm.icon, { width: 30 })}
      </div>,
      groupName: props.translate('editor.films.title'),
      groupKey: 'films',
      name: props.translate(props.selectedPaintProtectionFilm.name) + ' ' + props.translate(props.selectedPaintProtectionFilm.finish),
      onEditClick: () => {
        props.setEditorPanelState(EditorPanelState.SelectPpfOpen)
      },
      onRemoveClick: props.removePaintProtectionFilm,
      desktop: props.desktop
    })
  }

  return designItems
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      desktop: selectors.isDesktopViewport(state),
      editorState: selectors.getEditorPanelState(state),
      selectedTint: selectors.getSelectedTint(state),
      selectedAccent: selectors.getSelectedAccentWrap(state),
      selectedPaintProtectionFilm: selectors.getSelectedPaintProtectionFilm(state),
      panelGroups: selectors.getSelectedVehiclePanelGroups(state),
      getSelectedWrapForPanelGroup: (panelGroup: PanelGroup): Wrap => {
        return selectors.getSelectedWrapForPanelGroup(state, panelGroup)
      },
      translate: selectors.getTranslator(state)
    }
  },
  {
    setEditorPanelState,
    removeTint,
    removeAccentWrap,
    selectWrapForPanelGroup,
    openPanelGroup,
    removeAllVehicleSelections,
    removePaintProtectionFilm
  }
)(YourDesignSelector)
