import cx from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import { RestylerFormField } from '../../../../../core/FormConfig'
import closeContactRestylerForm from '../../actionCreators/closeContactRestylerForm'
import submitContactRestylerForm from '../../actionCreators/findRestyler/submitContactRestylerForm'
import closeModal from '../../actionCreators/modals/closeModal'
import navigateBack from '../../actionCreators/navigateBack'
import AppState from '../../store/models/AppState'
import FetchState from '../../store/models/FetchState'
import selectors from '../../store/selectors'
import restylerSelectors from '../../store/selectors/restylers'
import FormValidator from '../forms/FormValidator'
import Select from '../forms/Select'
import TextArea from '../forms/TextArea'
import TextInput from '../forms/TextInput'
import Required from '../forms/validators/Required'
import ValidEmail from '../forms/validators/ValidEmail'
import TranslationComponent from '../TranslationComponent'
import ModalComponent from './ModalComponent'

interface OwnProps { }

interface ReduxProps {
  restyler:any,
  submitState: FetchState,
  translate: (key:string) => any,
  restylerFormRows: Array<Array<RestylerFormField>>,
  showDisclaimerAbove: boolean
}

interface DispatchProps {
  closeContactRestylerForm: () => any,
  closeModal: () => any,
  navigateBack: () => any,
  submitContactRestylerForm: (fields) => any
}

interface State { }

class ContactRestylerForm extends React.Component<OwnProps & ReduxProps & DispatchProps, State> {
  _fields = {}
  _validatorLookup = {
    "required": Required,
    "email": ValidEmail
  }
  
  componentDidMount() {
    let keys = Object.keys(this._fields)
    if ( keys.length > 0 && this._fields[keys[0]] != null ) {
      this._fields[keys[0]].focus()
    }
  }
  
  render() {
    if (!this.props.restyler) {
      console.warn('ContactRestylerForm render called, but no restyler was set!')
      return null
    }
    
    return (
      <ModalComponent closeable={true}>
        <div className="contact-restyler">
          <div className="inner">
            <div>
              {(this.props.submitState === FetchState.Failure) && (
                <React.Fragment>
                  <div className="failure-message text-center landmark">
                    <TranslationComponent html={true} localizationKey='contact.failure-HTML' replace={{
                      restyler: this.props.restyler.companyName
                    }} />
                  </div>
                </React.Fragment>
              )}
              
              {(this.props.submitState === FetchState.Success) && (
                <React.Fragment>
                  <div className="success-message text-center landmark">
                    <TranslationComponent html={true} localizationKey='contact.success-HTML' replace={{
                      restyler: this.props.restyler.companyName
                    }} />
                  </div>
                </React.Fragment>
              )}
              
              {(this.props.submitState === FetchState.Success || this.props.submitState === FetchState.Failure) && (
                <React.Fragment>
                  <div className='gw modal-button-row'>
                    <div className='g text-center lap-one-half landmarklet'>
                      <button className="btn" onClick={() => { this.props.closeModal() }}>
                        <TranslationComponent localizationKey='contact.another-installer' />
                      </button>
                    </div>
                    <div className='g text-center lap-one-half landmarklet'>
                      <button className="btn" onClick={() => { this.props.navigateBack()}}>
                        <TranslationComponent localizationKey='contact.back-to-design' />
                      </button>
                    </div>
                    <div className='g text-center'>
                      <a className="islet" href={this.props.translate('global.email-signup.url')} target="_blank">
                        <TranslationComponent localizationKey='global.email-signup.title' />
                      </a>
                    </div>
                  </div>
                </React.Fragment>
              )}

              {(this.props.submitState === FetchState.NotAsked || this.props.submitState === FetchState.Loading || this.props.submitState === FetchState.Failure) && (
                <React.Fragment>
                  <h2 className="h4"><TranslationComponent localizationKey='contact.title' /></h2>
                  <h3>{this.props.restyler.companyName}</h3>
                  <form onSubmit={this._onSubmit}>
                    {this.props.restylerFormRows && this.props.restylerFormRows.map((row, i) => {
                      return <div key={'row'+i} className="gw">
                        {row.map((entry, j) => {
                          if ( entry.type !== 'opt-in' ) {
                            const placeholder = this.props.translate('contact.fields.'+entry.id+'.placeholder')
                            const asterisk = (entry.validators.includes('required') ? '*' : '')
                            const label = this.props.translate('contact.fields.'+entry.id+'.label') + asterisk
                            const validators = entry.validators.map((validatorId) => this._validatorLookup[validatorId])
                            
                            return (
                              <div key={'row'+i+'entry'+j} className={cx('g', 'landmark', row.length >= 2 && 'lap-one-half')}>
                                {(entry.type === 'text' || entry.type === 'email' || entry.type === 'phone') && (
                                  <TextInput 
                                    ref={(e) => this._fields[entry.id] = e }
                                    placeholder={placeholder}
                                    label={label}
                                    type={entry.type}
                                    validator={validators}
                                  />
                                )}
                              
                                {(entry.type === 'country') && (
                                  <Select 
                                    ref={(e) => this._fields[entry.id] = e }
                                    placeholder={placeholder}
                                    label={label}
                                    options={this.props.translate('contact.countries')}
                                    defaultValue={this.props.translate('contact.default-country')}
                                    validator={validators}
                                  />
                                )}
                              
                                {(entry.type === 'textarea') && (
                                  <TextArea 
                                    ref={(e) => this._fields[entry.id] = e }
                                    placeholder={placeholder}
                                    label={label}
                                  />
                                )}
                              </div>
                            )
                          } // endif not opt-in type
                        })}
                      </div>
                    })}
                  
                    <div className="gw">
                      <div className="g landmarklet">
                        <button className="btn restylerSubmit" disabled={this.props.submitState === FetchState.Loading}>
                          <TranslationComponent localizationKey='contact.submit' />
                        </button>
                      </div>
                      <div className="g warning landmarklet">
                        <TranslationComponent localizationKey='contact.submit-warning' />
                      </div>

                      {this.props.showDisclaimerAbove && (
                        <div className='g'>
                          <footer>
                            <TranslationComponent html={true} localizationKey='contact.legal-HTML' />
                          </footer>
                        </div>
                      )}

                      <div className="g text-center opt-in landmarklet">
                        <a href={this.props.translate('global.email-signup.url')} target="_blank">
                          <TranslationComponent localizationKey='global.email-signup.title' />
                        </a>
                      </div>
                    </div>
                  </form>
                  {!this.props.showDisclaimerAbove && (
                    <footer>
                      <TranslationComponent html={true} localizationKey='contact.legal-HTML' />
                    </footer>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </ModalComponent>
    )
  }
  
  _onSubmit = (evt) => {
    evt.preventDefault()
    
    const valid = FormValidator.validate(Object.values(this._fields))
    if (valid) {
      let values = {}
      Object.keys(this._fields).map((key) => {
        values[key] = this._fields[key].getValue()
      })
      this.props.submitContactRestylerForm(values)
    }
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      restyler: restylerSelectors.getContactRestyler(state),
      submitState: restylerSelectors.getRestylerFormSubmitState(state),
      translate: selectors.getTranslator(state),
      restylerFormRows: selectors.getRestylerFormRows(state),
      showDisclaimerAbove: selectors.getRestylerFormShowDisclaimerOnTop(state)
    }
  },
  {
    closeContactRestylerForm, closeModal, submitContactRestylerForm,  navigateBack
  }
)(ContactRestylerForm)