import * as React from 'react'
import { animated, useSpring, interpolate } from 'react-spring'

import { clamp, remap } from '../helpers/math'
import useEventListener from '../helpers/useEventListener'

export default function Mouse() {
  let [mainSpring, setMainSpring] = useSpring(() => ({
    xy: [0, 0],
    immediate: true,
    config: { // immediate doesn't seem to work in the props despite it being in the documentation, so we bypass and set the duration and progress to finished.
      duration: 0,
      progress: 1
    }
  }))
  let [outerSpring, setOuterSpring] = useSpring(() => ({
    xy: [0, 0],
    scale: 1,
    config: { mass: 1, tension: 1200, friction: 91 }
  }))

  useEventListener('mousemove', (event) => {
    let xy = { xy: [event.clientX, event.clientY] }
    setMainSpring(xy)
    setOuterSpring(xy)
  })

  // Make the outer circle track with the mouse when dragging.
  useEventListener('mousedown', (event) => {
    setOuterSpring({ scale: 0.75, immediate: true })
  })
  useEventListener('mouseup', (event) => {
    setOuterSpring({ scale: 1, immediate: false })
  })

  React.useEffect(() => {
    document.body.style.cursor = 'none'
    return () => {
      document.body.style.cursor = 'auto'
    }
  }, [])

  return (
    <>
      <animated.div
        className="mouse"
        style={{
          transform: (mainSpring.xy.interpolate as any)((x, y) => {
            return `translate(${x}px, ${y}px)`
          }),
          background: 'red',
          height: 10,
          width: 10,
          borderRadius: 5,
          position: 'fixed',
          top: -5,
          left: -5,
          pointerEvents: 'none',
          zIndex: 2000
        }}
      />
      <animated.div
        style={{
          transform: (interpolate as any)(
            [mainSpring.xy, outerSpring.xy, outerSpring.scale],
            ([mainX, mainY], [x, y], s) => {
              // get polar coordinates between mouse and outer spring and
              // clamp radius to keep inside circle from breaking out of the outside one.
              let nx = x - mainX
              let ny = y - mainY
              let radius = Math.sqrt(nx * nx + ny * ny)
              let radiusClamped = clamp(0, 10, remap(0, 50, 0, 10, radius))
              let angle = Math.atan2(ny, nx)
              // Add the adjusted polar coordinates to mainSpring.xy
              let clampedX = mainX + radiusClamped * Math.cos(angle)
              let clampedY = mainY + radiusClamped * Math.sin(angle)
              return `translate(${clampedX}px, ${clampedY}px) scale(${s})`
            }
          ),
          border: '2px solid rgba(255, 255, 255, 0.75)',
          height: 32,
          width: 32,
          borderRadius: 16,
          position: 'fixed',
          top: -16,
          left: -16,
          pointerEvents: 'none',
          zIndex: 2000
        }}
      />
    </>
  )
}