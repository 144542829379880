import THREE from '../../../../../../core/three/threeWithExtensions'
import UserSelections from "../../../../../../core/UserSelections"
import HighlightController from './HighlightController'
import PanelGroup from '../../../../../../core/vehicles/PanelGroup'
import EditorPanelState from '../../../store/models/EditorPanelState'
import Environment from '../../../../../../core/environments/Environment'
import environmentInstance from '../../../../../../core/environments/EnvironmentInstance'
import CameraControls from 'camera-controls'
import Wrap from '../../../../../../core/wraps/Wrap'
import VehicleMaterialUpdater from '../../../../../../core/vehicles/VehicleMaterialUpdater'
import VehicleMeshVisibilityUpdater from '../../../../../../core/vehicles/VehicleMeshVisibilityUpdater'
import IMaterial from '../../../../../../core/materials/IMaterial'
import getDevicePixelRatio from '../../../../../../core/utils/getDevicePixelRatio'
import ViewportDetector from '../../../../../../core/utils/ViewportDetector'
import AutoRotationController from './AutoRotationController'
import EnvironmentConfig from '../../../../../../core/EnvironmentConfig'
let innerHeight = require('ios-inner-height')
require('blueimp-canvas-to-blob')

CameraControls.install({ THREE: THREE });

interface ControllerProperties {
  environmentConfig: EnvironmentConfig,
  selections: UserSelections,
  temporarySelections: UserSelections,
  selectedPanelGroup: PanelGroup,
  editorPanelState: EditorPanelState,
  needsShareImageData: boolean,
  defaultWrap: IMaterial,
  defaultTint: IMaterial,
  isPortrait: boolean
}

interface ControllerSetupProperties { 
  selectPanelGroup: (group: PanelGroup) => void  
  deselectPanelGroup: () => void  
  openWindowTintPanel: () => void, 
  closeWindowTintPanel: () => void,
  openAccentPanel: () => void,
  closeAccentPanel: () => void,
  setShareImageData: (data: any, previewData: any) => void, 
  recordFrameDuration: (durationInMilliseconds: number) => void, 
  recordRenderError: (error: any) => void 
}

class ThreeController {
  _canvas: HTMLCanvasElement
  _composer: THREE.EffectComposer
  _renderPass: THREE.RenderPass
  _outlinePass: THREE.OutlinePass
  _shaderPass: THREE.ShaderPass
  _scene: THREE.Scene
  _renderer: THREE.WebGLRenderer
  _camera: THREE.PerspectiveCamera
  _clock: THREE.Clock
  _cameraControls: any // Sorry, really wanted to keep this typed, but it produced console warnings that the client complained about :(
  _rotationController: AutoRotationController
  _highlightController: HighlightController
  _setupProperties: ControllerSetupProperties
  _properties: ControllerProperties
  _oldProperties: ControllerProperties
  _running = false
  _takingScreenshots = false
  _environment: Environment
  _wrapsToUpdateWithEnvironmentPercentage: Array<Wrap>
  _vehicleMaterialUpdater: VehicleMaterialUpdater
  _vehicleMeshVisibilityUpdater: VehicleMeshVisibilityUpdater
  _vehicleModel: THREE.Mesh
  _shadowPlane: THREE.Mesh
  _shadowTexture: THREE.Texture
  _lastRenderTimestamp: number

  // <background> iPhone SE could not render the background and car at the same time without z-fighting, so we do two separate render passes. One for the background, and another for the foreground.  
  _backgroundRenderPass: THREE.RenderPass
  _backgroundCamera: THREE.PerspectiveCamera
  _backgroundScene: THREE.Scene
  // </background>

  setProperties = (properties: ControllerProperties): void => {
    if (this.arePropertiesSame(this._properties, properties)) { // No sense updating everything if the properties are the same.      
      return
    }
    this._properties = properties
    this._updateSceneToSelections()
  }

  arePropertiesSame = (a: ControllerProperties, b: ControllerProperties): boolean => {
    // We had some bugs where setProperties was getting spammed with the same properties over and over. This method helped identify which properties were causing the calls.    
    if (!a || !b) {
      return false
    }

    if (a.selections !== b.selections) { 
      return false
    }

    if (a.temporarySelections !== b.temporarySelections) { 
      return false
    }

    if (a.selectedPanelGroup !== b.selectedPanelGroup) { 
      return false 
    }

    if (a.editorPanelState !== b.editorPanelState) {
      return false 
    }

    if (a.needsShareImageData !== b.needsShareImageData) { 
      return false 
    }

    if (a.defaultWrap !== b.defaultWrap) {
      return false
    }

    if (a.defaultTint !== b.defaultTint) {
      return false 
    }

    if (a.isPortrait !== b.isPortrait) { 
      return false
    }

    return true
  }

  start = () => {
    if (!this._setupProperties) {
      console.warn('Unable to start because setup did not successfully complete.')
      return
    }

    this._canvas.classList.remove('offscreen')
    this._running = true
    this._highlightController.enable()
    this._cameraControls.reset()
    this._rotationController.start()
    window.requestAnimationFrame(this._renderLoop.bind(this))
  }

  pause = () => {
    this._running = false
    if (!this._setupProperties) {
      console.warn('Unable to pause because setup did not successfully complete.')
      return
    }
    
    this._highlightController.disable()
    this._cameraControls.saveState()
    this._rotationController.pause()
  }

  unpause = () => {
    this._running = true
    this._highlightController.enable()
    this._lastRenderTimestamp = new Date().getTime()
    this._rotationController.unpause()
  }

  setup = (properties: ControllerSetupProperties) => {
    if (this._setupProperties) {
      return
    }

    this._setupProperties = properties
    this._canvas = document.getElementById('canvas') as HTMLCanvasElement
    this._canvas.width = window.innerWidth
    this._canvas.height = window.innerHeight

    try {
      this._renderer = new THREE.WebGLRenderer({
        canvas: this._canvas, precision: "highp", // Looks really bad on mobile with mediump. Highp is the default, but explicitly setting it here so that we remember there's a reason that we need it.      
        preserveDrawingBuffer: true // Necessary for the toDataURL call on the Canvas to pull the image data out.
      })
      
      this._renderer.toneMapping = THREE.LinearToneMapping // Linear was the default in 0.101, but 0.134's default is NoToneMapping.
      this._renderer.toneMappingExposure = 1.0; // 1.0 was the default in 0.101.

      ;(window as any)['renderer'] = this._renderer // Sorry, this is very dirty! The startup.ts action uses this value to detect if any webGL exceptions have occurred.   
      this._renderer.setClearColor(0xffffff, 1.0)
    }
    catch (error) {
      this._setupProperties.recordRenderError(error)
      this._setupProperties = null // Clear out the props. We're done here. Fubar'ed.     
      return
    }

    this._backgroundCamera = new THREE.PerspectiveCamera(45, this._canvas.width / this._canvas.height, 1, 10000)
    this._camera = new THREE.PerspectiveCamera(45, this._canvas.width / this._canvas.height, 10, 2000)
    ;(window as any).camera = this._camera;

    this._camera.position.set(-450, 80, -250)
    this._clock = new THREE.Clock()
    this._cameraControls = new CameraControls(this._camera, this._canvas) // needs to have the top level container as the event listener so that controls work with the overlay/app layout 
    this._cameraControls.dampingFactor = 0.25
    this._cameraControls.truckSpeed = 0.0
    this._cameraControls.azimuthRotateSpeed = 2.0
    this._cameraControls.polarRotateSpeed = 1.6
    this._cameraControls.minDistance = 250 // Note, this value can get reset when changing vehicles  
    this._cameraControls.maxDistance = 650 // Note, this value can get reset when changing vehicles   
    this._cameraControls.minPolarAngle = Math.PI * 0.1
    this._cameraControls.maxPolarAngle = Math.PI * 0.425
    this._cameraControls.setLookAt(0, 80, -250, 0, 0, 0, true)
    this._rotationController = new AutoRotationController(this._cameraControls)
    this._backgroundScene = new THREE.Scene()
    this._scene = new THREE.Scene()
    this._composer = new THREE.EffectComposer(this._renderer)

    this._backgroundRenderPass = new THREE.RenderPass(this._backgroundScene, this._backgroundCamera)
    this._composer.addPass(this._backgroundRenderPass)
    
    this._renderPass = new THREE.RenderPass(this._scene, this._camera)
    this._renderPass.clear = false
    this._renderPass.clearDepth = true
    this._composer.addPass(this._renderPass)

    // https://threejs.org/examples/?q=outline#webgl_postprocessing_outline
    this._outlinePass = new THREE.OutlinePass(new THREE.Vector2(this._canvas.width, this._canvas.height), this._scene, this._camera)
    this._composer.addPass(this._outlinePass)

    this._shaderPass = new THREE.ShaderPass(THREE.FXAAShader)
    this._shaderPass.uniforms['resolution'].value.set(1 / this._canvas.width, 1 / this._canvas.height)
    this._shaderPass.renderToScreen = true
    this._composer.addPass(this._shaderPass)

    this._highlightController = new HighlightController(this._canvas, this._canvas, this._scene, this._camera, this._outlinePass, this._onHighlightSelection)
    this._vehicleMeshVisibilityUpdater = new VehicleMeshVisibilityUpdater()
    this._vehicleMaterialUpdater = new VehicleMaterialUpdater()
    this._wrapsToUpdateWithEnvironmentPercentage = []
    this._environment = environmentInstance

    this._shadowPlane = new THREE.Mesh(new THREE.PlaneGeometry(800, 800, 1, 1), new THREE.MeshBasicMaterial({ map: null, wireframe: true }))
    this._backgroundScene.add(this._shadowPlane)
    this._shadowPlane.rotateX(THREE.Math.degToRad(-90))
    this._shadowPlane.translateZ(1) // move it up a lil bit to remove the z-index fighting

    // Listen for window resize events so we can resize the canvas  
    window.addEventListener('resize', this._handleWindowResize)
    window.addEventListener('deviceorientation', this._handleWindowResize)
    const pixelRatio = getDevicePixelRatio()
    if (pixelRatio > 1) {
      this._handleWindowResize()
    }
  }

  _updateSceneToSelections = (): void => {
    if (!this._setupProperties || !this._properties) {
      // Really shouldn't get here...but safety.    
      return
    }

    let basePath: string = this._properties.environmentConfig.getAssetBasePath()
    this._environment.setScene(basePath, this._backgroundScene, this._scene)
    this._environment.setDaytimePercentageCallback(this._vehicleMaterialUpdater.setEnvironmentMapDaytimePercentage)
    const newVehicle = this._properties.selections.getVehicle() // The vehicle always pulls from the confirmed selections. Otherwise, there's too much loading & UI lag.   
    
    if (this._properties.isPortrait) {
      this._cameraControls.minDistance = newVehicle.cameraDistancePortrait.x
      this._cameraControls.maxDistance = newVehicle.cameraDistancePortrait.y
    }
    else {
      this._cameraControls.minDistance = newVehicle.cameraDistance.x
      this._cameraControls.maxDistance = newVehicle.cameraDistance.y
    }
    const cameraDistance = this._camera.position.length() // This only works because the camera is always rotating around the origin (0, 0, 0)    
    
    let moveTo = null
    if (cameraDistance < this._cameraControls.minDistance) {
      moveTo = this._camera.position.clone().normalize().multiplyScalar(this._cameraControls.minDistance)
    }
    else if (cameraDistance > this._cameraControls.maxDistance) {
      moveTo = this._camera.position.clone().normalize().multiplyScalar(this._cameraControls.maxDistance)
    }
    
    if (moveTo) {
      this._cameraControls.setLookAt(moveTo.x, moveTo.y, moveTo.z, 0, 0, 0, true)
    }

    this._rotationController.setUserIsInteracting(this._properties.selectedPanelGroup != null || this._properties.editorPanelState !== EditorPanelState.Closed)
    const sceneMode = this._properties.selections.getSceneMode()
    this._environment.transitionToSceneMode(sceneMode)
    
    Promise.all([this._environment.load(basePath), newVehicle.load(basePath)]).then(() => {
      if (this._vehicleModel) {
        this._scene.remove(this._vehicleModel)
        this._backgroundScene.remove(this._shadowPlane)
      }

      this._vehicleModel = newVehicle.model
      this._scene.add(this._vehicleModel)
      if (newVehicle.shadowTextureMaterial != null) {
        this._shadowPlane.material = newVehicle.shadowTextureMaterial
        this._backgroundScene.add(this._shadowPlane)
      }

      const envMapDay = this._environment.getDayEnvironmentMap()
      const envMapNight = this._environment.getNightEnvironmentMap()
      const envPercentage = this._environment.getDaytimeEnvironmentMapPercentage()
      this._vehicleMaterialUpdater.configure(basePath, this._properties.defaultTint, this._properties.defaultWrap, envMapDay, envMapNight, envPercentage, this._properties.temporarySelections)
      this._vehicleMeshVisibilityUpdater.configure(basePath, this._properties.temporarySelections, this._properties.editorPanelState === EditorPanelState.SelectAccentOpen)

      this._highlightController.configure(
        this._oldProperties ? this._oldProperties.selections : null, 
        this._properties.selections, 
        this._properties.editorPanelState, 
        true
      )

      // <camera positioning>
      let pointCameraAt = null
      if (!this._oldProperties || this._oldProperties.selections.getVehicle() !== this._properties.selections.getVehicle()) {
        const vehicle = this._properties.selections.getVehicle()
        if (!this._properties.isPortrait) {
          pointCameraAt = vehicle.cameraStartAt
        }
        else {
          pointCameraAt = vehicle.cameraStartAt.normalize().multiplyScalar(vehicle.cameraDistancePortrait.y)
        }
      }
      else if (this._properties.selectedPanelGroup && (!this._oldProperties || this._oldProperties.selectedPanelGroup !== this._properties.selectedPanelGroup)) {
        pointCameraAt = this._properties.selectedPanelGroup.cameraPosition
      }
      if (pointCameraAt) {
        this._cameraControls.setLookAt(pointCameraAt.x, pointCameraAt.y, pointCameraAt.z, 0, 0, 0, true)
      }
      // </camera positioning>

      this._oldProperties = this._properties
      if (this._properties.needsShareImageData) { this._takeScreenshots() }
    })
  }

  _cropSquare = (canvas: HTMLCanvasElement): HTMLCanvasElement => {
    let cropCanvas = document.createElement("canvas")
    if (canvas.width > canvas.height) {
      cropCanvas.width = canvas.height
      cropCanvas.height = canvas.height
    }
    else {
      cropCanvas.width = canvas.width
      cropCanvas.height = canvas.width
    }
    const cropContext = cropCanvas.getContext("2d")
    const dx = (cropCanvas.width * 0.5) - (canvas.width * 0.5)
    let dy = 0
    if (ViewportDetector.isPortrait()) {
      dy = ((cropCanvas.height * 0.5) - (canvas.height * 0.5)) + (canvas.height * 0.1)
    }
    cropContext.drawImage(canvas, dx, dy)
    return cropCanvas
  }

  _copyCanvas = (canvas:HTMLCanvasElement): HTMLCanvasElement => {
    let newCanvas = document.createElement("canvas")
    newCanvas.width = canvas.width
    newCanvas.height = canvas.height

    const newContext = newCanvas.getContext("2d")
    newContext.drawImage(canvas, 0, 0)

    return newCanvas
  }

  _takeScreenshot(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._render()
      let canvas = this._cropSquare(this._canvas)
      canvas.toBlob((blob) => { resolve(blob) }, 'image/jpeg', 0.95)
    })
  }

  _takeScreenshots = () => {
    this._takingScreenshots = true

    let existingFrame = this._copyCanvas(this._canvas)
    existingFrame.className = 'screenshotPlaceholder'
    this._canvas.parentNode.insertBefore(existingFrame, this._canvas.nextSibling) // Put the canvas immediately after the _canvas in the DOM. This lets the styles for the surrounding components work mostly as normal.

    setTimeout(() => { // Sorry, needed a timeout because mobile devices are really freaking slow, and the flicker remained unless we delayed the next actions.
      // Set up the camera & things for the screenshots   
      const portrait = ViewportDetector.isPortrait()
      const originalCameraPos = this._cameraControls.getPosition()
      const screenshotPosition = (portrait ? this._properties.selections.getVehicle().screenshotPositionPortrait : this._properties.selections.getVehicle().screenshotPosition)
      const screenshotLookAtOffset = this._properties.selections.getVehicle().screenshotLookAtOffset
      this._cameraControls.setLookAt(screenshotPosition.x, screenshotPosition.y, screenshotPosition.z, screenshotLookAtOffset.x, screenshotLookAtOffset.y, screenshotLookAtOffset.z, false)
      this._highlightController.deselectAll()

      this._takeScreenshot().then((shareScreenshot) => {
        this._environment.setupForScreenshot()
        this._takeScreenshot().then((previewScreenshot) => {
          // Revert things back to the way they were  
          this._environment.resetFromScreenshot()
          this._cameraControls.setLookAt(originalCameraPos.x, originalCameraPos.y, originalCameraPos.z, 0, 0, 0, false)
          this._highlightController.configure(
            this._oldProperties ? this._oldProperties.selections : null, 
            this._properties.selections, 
            this._properties.editorPanelState, 
            false
          )
          this._render() // Render to get our canvas back to the initial state
          existingFrame.remove() // Remove the old frame now that we've re-rendered

          // And finish up!   
          this._setupProperties.setShareImageData(shareScreenshot, previewScreenshot)
          this._takingScreenshots = false
        })
      })
    }, 100)
  }

  _renderLoop = () => {
    if (this._properties && this._setupProperties) {
      if (this._running && !this._takingScreenshots) {
        this._render()
      }
    }

    window.requestAnimationFrame(this._renderLoop.bind(this))
  }

  _render = () => {
    this._cameraControls.update(this._clock.getDelta()) // Important that this be here, otherwise the actual THREE camera can be in the wrong place. We do this regardless of whether or not we're paused or taking screenshots!

    // <Sync> the background camera with the regular camera so that the frames are always in sync    
    const near = this._backgroundCamera.near
    const far = this._backgroundCamera.far
    this._backgroundCamera.copy(this._camera)
    this._backgroundCamera.near = near
    this._backgroundCamera.far = far
    this._backgroundCamera.updateProjectionMatrix() // Must call updateProjectMatrix after changing parameters. Without this, the near/far parameters do not take effect.   
    // </Sync>

    try {
      this._composer.render()
    }
    catch (error) {
      this._setupProperties.recordRenderError(error)
    }
    this._lastRenderTimestamp = new Date().getTime()

    let webGLError = this._renderer.getContext().getError()
    if (webGLError) {
      this._setupProperties.recordRenderError('WebGL Error')
    }
  }

  _onHighlightSelection = (meshes: Array<THREE.Mesh>): void => {
    if (!this._properties) {
      return
    }
    const vehicle = this._properties.selections.getVehicle()
    // <debugging> 
    // console.log('Meshes selected:')  
    // for(let i=0; i<meshes.length; i++) {  
    //   const mesh = meshes[i]  
    //   console.log('\t' + i + '. Mesh=' + mesh.name + ', Material=' + mesh.material.name, mesh)  
    // }    
    // </debugging>

    return; // Got direction from client that they want to NOT enable the highlight selection on the physical model.

    for (let i = 0; i < meshes.length; i++) {
      const mesh = meshes[i]
      if (vehicle.isAccentMeshName(mesh.name)) {
        if (this._properties.editorPanelState === EditorPanelState.SelectAccentOpen) {
          this._setupProperties.closeAccentPanel()
        }
        else {
          this._setupProperties.openAccentPanel()
        }
        break
      }
      if (vehicle.isWindowMeshName(mesh.name)) {
        if (this._properties.editorPanelState === EditorPanelState.SelectTintOpen) {
          this._setupProperties.closeWindowTintPanel()
        }
        else {
          this._setupProperties.openWindowTintPanel()
        }
        break
      }

      const panelGroup = vehicle.getPanelGroupForMeshName(mesh.name)
      if (panelGroup) {
        if (panelGroup === this._properties.selectedPanelGroup) {
          this._setupProperties.deselectPanelGroup()
        }
        else {
          this._setupProperties.selectPanelGroup(panelGroup)
        }
        break
      }
    }
  }

  _handleWindowResize = () => {
    const width = window.innerWidth
    const height = innerHeight() // using the ios inner height shim library to correctly handle landscape iOS height calculations
    this._renderer.setSize(width, height)
    // this._canvas.width = width // Setting on the canvas directly is unnecessary because the renderer does that for us.   
    // this._canvas.height = height
    this._camera.aspect = width / height
    this._camera.updateProjectionMatrix()
    this._composer.setSize(width, height)
    // this._outlinePass.setSize(width, height) 
    // Not needed because the composer takes care of setting this.  
    this._shaderPass.uniforms['resolution'].value.set(1 / width, 1 / height)
    // console.log('cameraPosition', this._camera.position)  
  }
}

export default ThreeController