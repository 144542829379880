import * as React from 'react'
import { animated } from 'react-spring'
import { Spring } from 'react-spring'
import { connect } from 'react-redux'
import cx from 'classnames'

import { remap } from '../../../helpers/math'
import ArrowIcon from '../../icons/Arrow'

import setEditorPanelState from '../../../actionCreators/editorPanels/setEditorPanelState'
import selectPanelGroup from '../../../actionCreators/wraps/selectPanelGroup'
import EditorPanelState from '../../../store/models/EditorPanelState'
import PanelGroup from '../../../../../../core/vehicles/PanelGroup'
import AppState from '../../../store/models/AppState'
import DesktopFilterMenu from './DesktopFilterMenu'

interface HeaderSelectionOptions {
  title: string
  values: Array<string>
  selectedValue: string
  onHeaderOptionSelected: (option:string) => any
}

interface OwnProps {
  className?: string
  title: string
  titleIcon: any // Sorry. Some react component class Couldn't figure out how to type this.
  headerOptions?: Array<HeaderSelectionOptions>
  bodyStyles?:any
  bodyClassName?: string
  footerText?:string
  children: any
}

interface ReduxProps {}

interface DispatchProps {
  setEditorPanelState: (state: EditorPanelState) => void
  selectPanelGroup: (panelGroup: PanelGroup) => void
}

class DesktopAssetSelectorPanel extends React.Component<OwnProps & ReduxProps & DispatchProps> {
  render() {
    return <Spring
      from={{ value: 0.0 }}
      to={{ value: 1 }}
    >
      {({value}) => (
        <div 
          className={cx(this.props.className, "panel-group-selector")}
          style={{ opacity: value.get() }}
        >
          <animated.button
            className="panel-group-selector__back"
            onClick={this.onBackSelected}
            style={{
              opacity: value,
              transform: `translate(calc(-50% + ${remap(0, 1, 20, 0, value.get())}px), -50%)`
            }}
          >
            <ArrowIcon direction="left" width={44} />
          </animated.button>

          <animated.div
            className="panel-group-selector__title"
            style={{
              opacity: value,
              transform: `translate(${remap(0, 1, 4, 0, value.get())}px, ${remap(0, 1, 10, 0, value.get())}px)`
            }}
          >
            {this.props.titleIcon}
            {this.props.title}
          </animated.div>

          <animated.div className="panel-group-selector__header" style={{
            opacity: remap(0.25, 1, 0, 1, value.get())
          }}>
            {this.props.headerOptions && this.props.headerOptions.map( (headerOption, index) => {
              return <DesktopFilterMenu
                allLabel={headerOption.title}
                items={headerOption.values.map((value) => {
                  return { label: value, value: value }
                })}
                selected={headerOption.selectedValue}
                onSelect={(value) => {
                  headerOption.onHeaderOptionSelected(value)
                }}
                right={(this.props.headerOptions.length - index - 1) * 200}
              />
            })}
          </animated.div>
          
          <div
            className={cx(this.props.bodyClassName, "body")}
            style={{...this.props.bodyStyles}}
          >
            {this.props.children}
          </div>
          {this.props.footerText && (
            <p className='panel-group-selector__footer'>
              {this.props.footerText}
            </p>
          )}
        </div>
      )}
    </Spring>
  }

  onBackSelected = () => {
    this.props.selectPanelGroup(null) // Yeah, this is a little strange to have in here, since it's not generic. But It's better than prop-barfing callbacks through the chain.
    this.props.setEditorPanelState(EditorPanelState.Closed)
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return { }
  },
  { 
    setEditorPanelState,
    selectPanelGroup
  }
)(DesktopAssetSelectorPanel)