import * as React from 'react'
import cx from 'classnames'

// SVGs
import ArrowIcon from '../../icons/Arrow'

type MobileFilterMenuItem<V> = {
  label: string
  value: V
}

type MobileFilterMenuProps<V> = {
  allLabel: string
  items: Array<MobileFilterMenuItem<V>>
  selected: V
  onSelect: (value: V) => void
  left: string
}

function MobileFilterMenu<V>(props: MobileFilterMenuProps<V>) {
  // a better name for this would be "open"
  let [hovered, setHovered] = React.useState(false)
  let selectedItem = props.items.find((item) => {
    return item.value === props.selected
  })

  let open = hovered

  return (
    <div
      className={cx('mobile-filter-menu', hovered && 'hovered')}
      style={{ left: props.left }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ul className={cx('v-list')}>
        <li className="title">
          {open ? (
            <button
              onClick={() => {
                  props.onSelect(null)
                  setHovered(false)
                }}
              className={cx(selectedItem == null && 'selected')}
            >
              {props.allLabel}
            </button>
          ) : (
            /* closed */
            <button
              onClick={() => {
                /* do nothing */
                setHovered(true)
              }}
              className="selected"
            >
              {selectedItem != null && !open
                ? selectedItem.label
                : props.allLabel}
            </button>
          )}
            <span className="filter-menu__icon">
              <ArrowIcon direction={open ? 'up' : 'down'} width={14} />
            </span>
        </li>
        {props.items.map((item) => {
          return (
            <li
              key={item.label + item.value}
              className={cx(
                'entry',
                selectedItem && selectedItem.label === item.label && 'selected'
              )}
            >
              <button
                className={cx(
                  selectedItem &&
                    selectedItem.label === item.label &&
                    'selected'
                )}
                onClick={() => {
                    props.onSelect(item.value)
                    setHovered(false)
                }}
              >
                {item.label}
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default MobileFilterMenu
