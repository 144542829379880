import Dispatch from './Dispatch'
import GetState from './GetState'
import selectors from '../store/selectors'
import Modal from '../store/models/Modal'
import closeModal from './modals/closeModal'
import selectLanguage from './selectLanguage'
import leven from 'leven'
import * as _ from 'underscore'
import SessionManager from '../helpers/SessionManager'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState()
    
    const language = selectors.getLanguage(state)
    if (!language) {
      console.warn("confirmLanguage called, but no language has been set! Ignoring.")
      return
    }

    await dispatch(selectLanguage(language)) // Important to select the language, in-case the user just left the UI as-is and clicked continue.
    SessionManager.saveLanguageSelection(language) // Would really have loved to set this in the reducer, but that ended up causing some order-of-operation issues. Easier to just place it here.
    
    // Turns out 3M wants to host each language on a separate URL, so we don't change languages in-app anymore.
    // We redirect out to instances of the application hosted on another URL.
    let redirectURLs = selectors.getLanguageRedirectURLs(state, language)
    if (redirectURLs && redirectURLs.length > 0) {
      const url = stripOutQuerystringAndHash(window.location.href)
      redirectURLs = _.map(redirectURLs, (item) => {
        return stripOutQuerystringAndHash(item)
      })
      
      const matches = doesURLMatchAnyRedirect(url, redirectURLs)
      if (!matches) { // If we weren't on one of the redirect pages, then we need to redirect to one of them.
        const redirectTo = getClosestString(url, redirectURLs)
        window.location.href = redirectTo + window.location.search + window.location.hash // Make sure to pass along the search and hash to the new URL.
        return
      }
    }

    // And if the user's selection doesn't need to be redirected, just close the modal.
    await dispatch(closeModal(Modal.LanguageSelection))
  }
}

const doesURLMatchAnyRedirect = (url:string, redirects:string[]):boolean => {
  for (var i=0; i<redirects.length; i++) {
    const redirect = redirects[i]
    const cleanedRedirect = stripOutQuerystringAndHash(redirect)
    const cleanedCurrent = stripOutQuerystringAndHash(url)
    if (cleanedRedirect.toLowerCase() == cleanedCurrent.toLowerCase()) {
      return true
    }
  }

  return false
}

const getClosestString = (url:string, options:string[]):string => {
  let closest = null
  let closestDistance = NaN

  for (var i=0; i<options.length; i++) {
    const option = options[i]
    const distance = leven(url, option)
    if (closest == null || distance < closestDistance) {
      closest = option
      closestDistance = distance
    }
  }

  return closest
}

const stripOutQuerystringAndHash = (url:string):string => {
  if (!url) {
    return url
  }
  
  const hashIndex = url.indexOf('#')
  const queryIndex = url.indexOf('?')

  let useIndex = -1
  if (hashIndex >= 0) {
    useIndex = hashIndex
  }
  if (queryIndex >= 0)
  {
    if (queryIndex < useIndex) {
      useIndex = queryIndex
    }
  }
  
  if (useIndex < 0) { // No querystring or hash? Then no mods needed.
    return removeTrailingslash(url)
  }

  const cleaned = url.substr(0, useIndex)
  return removeTrailingslash(cleaned)
}

const removeTrailingslash = (url:string):string => {
  if (!url) {
    return url
  }

  if (!url.endsWith('/')) {
    return url
  }

  return url.substr(0, url.length - 1)
}