import { applyMiddleware, combineReducers, compose, createStore as createStoreRedux } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk';

import ui from './reducers/ui'
import restylers from './reducers/restylers'

const createStore = () => {
  const rootReducer = combineReducers({
    ui, restylers
  })
  const middleware = [
    thunk,
    createLogger({
      predicate: (getState:any, action:any) => {
        if (!action || !action.type || action.type === 'RECORD_FRAME_DURATION') { // We don't want to pollute the log with all of these events.
          return false
        }

        return true
      }
    })
  ]

  const store = createStoreRedux(
    rootReducer,
    compose(applyMiddleware(...middleware))
  );

  /* Here so that we can debug in the console to get a reference to the store without having to open up the
     slow Redux dev tools. Call `window.store.getState()` to see the contents. */
  (window as any)['store'] = store

  return store
}

export default createStore