import * as _ from 'underscore'

export interface RestylerFormField {
  id: string
  type: "text" | "email" | "country" | "textarea" | "opt-in" | "phone"
  validators: Array<"required" | "email">
}

export interface RestylerFormMetadata {
  disclaimerOnTop: boolean
  fields: Array<Array<RestylerFormField>> // Ugh, yes, it's an Array of Arrays because we needed to have a way to stack two fields in the same row.
}

export interface FormFileData {
  restyler: RestylerFormMetadata
}

class FormConfig {
  _formData:FormFileData

  constructor(formData:FormFileData) {
    if ( !formData["restyler"] ) {
      throw new Error("restyler form entry not found on form config!")
    }
    this._formData = formData
  }

  getRestylerFormRows():Array<Array<RestylerFormField>> {
    return this._formData.restyler.fields
  }

  getRestylerFormShowDisclaimerOnTop():boolean {
    return this._formData.restyler.disclaimerOnTop
  }
}

export default FormConfig