import AnyAction from '../../actions/AnyAction'
import FetchState from '../models/FetchState'
import RestylerSearchResult from '../models/RestylerSearchResult'
import Coordinate from '../models/Coordinate'

export interface RestylersState {
	searchValue: string // The string location that the user is searching for.
	searchWasAutomated: boolean // Whether the search input was from an automatic process or manually triggered by the user.
	searchFilters: Array<string> // What filters the user is currently using. NOT the list of all filters available.
	restylerSearchState: FetchState
	
	mapCoordinate: Coordinate|null // What GPS coordinate the user has panned to on the map
	mapZoom:number, // What level the map is zoomed in to

	searchResults: Array<RestylerSearchResult>|null
	countryCodeDenied: boolean
	selectedRestyler: RestylerSearchResult // The restyler that is currently focused on the map
	contactRestyler: RestylerSearchResult // The restyler the user has selected to contact
	restylerFormSubmitState: FetchState
}

const initialState = {
	searchValue: '',
	searchWasAutomated: false,
	searchFilters: [],
	restylerSearchState: FetchState.NotAsked,
	
	mapCoordinate: null,
	mapZoom: 14,

	browserCoordinate: null,
	restylerSearchCoordinate: null,

	searchResults: null,
	selectedRestyler: null,
	contactRestyler: null,
	countryCodeDenied: false,
	restylerFormSubmitState: FetchState.NotAsked
}

const reducer = (state: RestylersState = initialState, action: AnyAction): RestylersState => {
  switch (action.type) {
    case 'INIT':
			return initialState

		case 'SET_BROWSER_LOCATION': 
			return {
				...state,
				mapCoordinate: action.coordinate
			}
			
		case 'SET_RESTYLER_SEARCH':
			return {
				...state,
				searchValue: action.value,
				searchWasAutomated: action.automated,
				restylerSearchState: FetchState.Loading,
				searchResults: null,
				mapCoordinate: null,
				mapZoom: 8,
				countryCodeDenied: false
			}

		case 'CLEAR_RESTYLER_SEARCH':
			return {
				...state,
				searchValue: '',
				restylerSearchState: FetchState.NotAsked,
				searchResults: null,
				countryCodeDenied: false
			}

		case 'SET_RESTYLER_SEARCH_FILTERS':
			return {
				...state,
				searchFilters: action.filters
			}
		
		case 'SET_RESTYLER_SEARCH_COORDINATE':
			return {
				...state,
				mapCoordinate: action.coordinate
			}
		
		case 'SET_RESTYLER_MAP_COORDINATE':
			return {
				...state,
				mapCoordinate: action.coordinate
			}

		case 'SET_RESTYLER_MAP_ZOOM':
			return {
				...state,
				mapZoom: action.zoom
			}

		case 'SET_RESTYLER_SEARCH_RESULTS':
			return {
				...state,
				restylerSearchState: action.value ? FetchState.Success : FetchState.Failure,
				searchResults: action.value,
				mapCoordinate: action.coordinate,
				countryCodeDenied: action.countryCodeDenied,
				selectedRestyler: null
			}

		case 'SELECT_RESTYLER':
			let coordinate = state.mapCoordinate
			if (action.restyler) {
				coordinate = {
					latitude: action.restyler.lat,
					longitude: action.restyler.lon
				}
			}

			return {
				...state,
				selectedRestyler: action.restyler,
				mapZoom: 14,
				mapCoordinate: coordinate
			}

		case 'CONTACT_RESTYLER':
			return {
				...state,
				contactRestyler: action.restyler,
				restylerFormSubmitState: FetchState.NotAsked
			}

		case 'SET_RESTYLER_FORM_STATE':
			return { 
				...state,
				restylerFormSubmitState: action.value
			}
  }
  return state
}

export default reducer
