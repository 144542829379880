import axios from 'axios'
import Dispatch from '../Dispatch'
import GetState from '../GetState'
import selectors from '../../store/selectors'
import RestylerSearchResult from '../../store/models/RestylerSearchResult'

interface ICoordinate {
	latitude:number,
	longitude:number,
	country:string
}

export default (searchValue:string, fromAutomaticLookup:boolean = false) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		if (!searchValue) {
			console.warn('searchForRestylers called, but no search value supplied. Ignoring.')
			return
		}

		dispatch({
			type: 'SET_RESTYLER_SEARCH',
			value: searchValue,
			automated: fromAutomaticLookup
		})

    // De-activate any filters so they don't stick around for subsequent searches. 
    // We observed a weird state where no results get shown when a user searches
    // in a zipcode that has filters. Then selects a filter. Then does a new search
    // that doesn't have any restylers with that filter. No results get shown to the
    // user, and no filter bar appears leaving the user in a gross state where it looks
    // like there are just no results.
    dispatch({ 
      type: 'SET_RESTYLER_SEARCH_FILTERS',
      filters: []
    })

		const state = getState()

    let region = selectors.translate(state, 'search.regionCountryCode')
    if (!region || region === 'search.regionCountryCode') {
      region = undefined
    }

		let coordinate = null
		try {
			const googleMapsUrl = selectors.getEnvironmentConfig(state).getRestylerGoogleMapsUrl()
			coordinate = await tryToGetLatLongForAddress(googleMapsUrl, searchValue, region)
		}
		catch(err) {
			dispatch({
				type: 'SET_RESTYLER_SEARCH_RESULTS',
				value: [],
				countryCodeDenied: false
			})
			return
		}

		dispatch({
			type: 'SET_RESTYLER_SEARCH_COORDINATE',
			coordinate: coordinate
		})

		const allowedCountryCodes = selectors.getEnvironmentConfig(state).getRestylerAllowedCountryCodes()
		const valid = checkForValidCoordinateCountryCode(coordinate, allowedCountryCodes)
		if (!valid) {
			dispatch({
				type: 'SET_RESTYLER_SEARCH_RESULTS',
				value: [],
				countryCodeDenied: true
			})
			return
		}

    let sessionKey = null
		try {
			const sessionKeyUrl = selectors.getEnvironmentConfig(state).getRestylerSessionKeyUrl()
			sessionKey = await tryToGet3MSessionKey(sessionKeyUrl)
		}
		catch(err) {
			dispatch({
				type: 'SET_RESTYLER_SEARCH_RESULTS',
				value: [],
				countryCodeDenied: false
			})
			return
		}

		let restylers = null
		try {
			const environmentConfig = selectors.getEnvironmentConfig(state)
			const findRestylerUrl = environmentConfig.getRestylerFindRestylerUrl()
			const maximumResultCount = environmentConfig.getRestylerMaxResultCount()
			restylers = await tryToFetchRestylers(findRestylerUrl, coordinate, sessionKey, maximumResultCount)
		}
		catch(err) {
			dispatch({
				type: 'SET_RESTYLER_SEARCH_RESULTS',
				value: [],
				countryCodeDenied: false
			})
			return
		}

		dispatch({
			type: 'SET_RESTYLER_SEARCH_RESULTS',
			value: restylers,
			coordinate: { latitude:coordinate.latitude, longitude:coordinate.longitude },
			countryCodeDenied: false
		})
  }
}

const tryToGetLatLongForAddress = async (googleMapsUrl:string, address:string, region:string):Promise<ICoordinate> => {
	return new Promise( (resolve, reject) => {
		axios.get(googleMapsUrl, {
			params: {
				address: address,
        region: region
			}
		}).then( (response) => {
			if (!response || !response.data || !response.data.results || response.data.results.length <= 0) {
				reject('No results found')
				return
			}

			const result = response.data.results[0]
			if (!result.geometry || !result.geometry.location) {
				reject('No results found')
				return
			}

			// pluck out the geocoded country code
			let country = null
			if ( result.address_components ) {
				for ( let i = 0; i < result.address_components.length; i++ ) {
					if ( result.address_components[i].types && result.address_components[i].short_name ) {
						for ( let j = 0; j < result.address_components[i].types.length; j++ ) {
							if ( result.address_components[i].types[j] === "country" ) {
								country = result.address_components[i].short_name
							}
						}
					}
				}
			}
			if ( !country ) {
				reject('No results found')
				return
			}

			resolve({
				latitude: result.geometry.location.lat,
				longitude: result.geometry.location.lng,
				country: country
			})
		})
		.catch( (error) => {
			reject(error)
		})
	})
}

const tryToGet3MSessionKey = (sessionKeyUrl:string):Promise<string> => {
	return new Promise( (resolve, reject) => {
		axios.get(sessionKeyUrl, {
			params: {}
		})
		.catch( (error) => {
      reject(error)
		})
		.then( (response) => {
			if (!response || !response.data) {
				reject('No API key provided.')
				return
			}
			resolve(response.data)
		})
	})
}

const tryToFetchRestylers = (findRestylerUrl:string, coordinate:ICoordinate, sessionKey:string, maximumResultCount:number):Promise<Array<RestylerSearchResult>> => {
	return new Promise( (resolve, reject) => {
		const url = findRestylerUrl 
			+ '?apiSessionToken=' + encodeURIComponent(sessionKey) 
			+ '&lat=' + encodeURIComponent(coordinate.latitude.toString()) 
			+ '&lon=' + encodeURIComponent(coordinate.longitude.toString())
			+ '&region=' + encodeURIComponent(coordinate.country.toUpperCase())
		axios.post(url) // Ugh, yes, 3Ms API require that the request is a POST but that the parameters must be passed in via query string... (ノ°Д°）ノ︵ ┻━┻
		.catch( (error) => {
			reject(error)
		})
		.then( (response) => {
			if (!(response as any).data) {
				reject('No data available')
				return
			}

			const results = (response as any).data
			resolve(results)
		})
	})
}

const checkForValidCoordinateCountryCode = (coordinate:ICoordinate, allowedCountryCodes:Array<string>):Boolean => {
	let found = false

	if ( !coordinate.country ) return found

	let normalizedCountryCode = coordinate.country.toUpperCase()
	for ( let i = 0; i < allowedCountryCodes.length; i++ ) {
		let normalized = allowedCountryCodes[i].toUpperCase()
		if ( normalized === normalizedCountryCode ) {
			found = true
		}
	}
	return found
}