import Dispatch from './Dispatch'
import GetState from './GetState'
import selectors from '../store/selectors'
import PanelGroup from '../../../../core/vehicles/PanelGroup'
import selectPanelGroup from './wraps/selectPanelGroup'
import setEditorPanelState from './editorPanels/setEditorPanelState'
import EditorPanelState from '../store/models/EditorPanelState'

export default (panelGroup:PanelGroup) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    if (!panelGroup) {
      console.warn('selectWrapForPanelGroup called, but no panel group specified! Ignoring.')
      return
    }

    // TODO: Error checking to make sure this doesn't happen when the app is in the wrong state?

    dispatch(selectPanelGroup(panelGroup))
    dispatch(setEditorPanelState(EditorPanelState.SelectMaterialOpen)) // Really shouldn't need this since the UI isn't available unless the material selector is open, but here it is for safety.
  }
}