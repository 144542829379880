import Dispatch from '../Dispatch'
import GetState from '../GetState'
import RestylerSearchResult from '../../store/models/RestylerSearchResult'

export default (restyler:RestylerSearchResult) => {
  if (!restyler) {
    console.warn('openDirectionsToRestyler called, but no restyler supplied! Ignoring.')
    return
  }

  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    /// According to Google, this algorithm should be cross-platform 
    /// https://developers.google.com/maps/documentation/urls/guide
    const url = "https://www.google.com/maps/dir/?api=1&destination=" + restyler.lat + ',' + restyler.lon
    window.open(url, '_blank')
  }
}