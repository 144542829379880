import Dispatch from './Dispatch'
import GetState from './GetState'
import selectors from '../store/selectors'

export default (finishGroup:string) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		// TODO: Error checking to make sure this doesn't happen when the app is in the wrong state?

		if (!finishGroup) { // None is a valid value, as that defaults us to All Materials
			dispatch({
				type: 'SELECT_FINISH_GROUP',
				finishGroup: null
			})
			return
		}

		const state = getState()
		const finishGroups = selectors.getAllWrapFinishGroups(state)
		if (finishGroups.indexOf(finishGroup) < 0) {
			console.warn('selectFinishGroup called, but supplied finishGroup `' + finishGroup + '` was not in the list. Ignoring.')
			return
		}

    dispatch({
      type: 'SELECT_FINISH_GROUP',
    	finishGroup: finishGroup
    })
  }
}