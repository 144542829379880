import * as React from 'react'
import { connect } from 'react-redux'

import AppState from '../../../store/models/AppState'
import selectors from '../../../store/selectors'
import setEditorPanelState from '../../../actionCreators/editorPanels/setEditorPanelState'
import EditorPanelState from '../../../store/models/EditorPanelState'
import DesktopMaterialSelector from './DesktopMaterialSelector'
import Wrap from '../../../../../../core/wraps/Wrap'
import PanelGroup from '../../../../../../core/vehicles/PanelGroup'
import selectWrap from '../../../actionCreators/wraps/selectWrap'
import selectAccentWrap from '../../../actionCreators/accents/selectAccentWrap'
import temporarilySelectAccentWrap from '../../../actionCreators/accents/temporarilySelectAccentWrap'
import applyTemporarilySelectedAccentWrap from '../../../actionCreators/accents/applyTemporarilySelectedAccentWrap'
import selectPanelGroup from '../../../actionCreators/wraps/selectPanelGroup'
import DesktopTintSelector from './DesktopTintSelector'
import SceneMode from '../../../../../../core/SceneMode'

import MaterialsIcon from '../../icons/Materials'
import AccentsIcon from '../../icons/Accents'
import DesktopFilmSelector from './DesktopFilmSelector'

interface OwnProps { }

interface ReduxProps {
  sceneMode: SceneMode
  editorPanelState: EditorPanelState
  selectedPanelGroup: PanelGroup
  temporarilySelectedPanelGroupWrap: Wrap
  selectedPanelGroupWrap: Wrap
  temporarilySelectedAccentWrap: Wrap
  selectedAccentWrap: Wrap
  translate: (key: string) => string
}

interface DispatchProps {
  setEditorPanelState: (state: EditorPanelState) => void
  selectPanelGroup: (panelGroup: PanelGroup) => void
  selectWrap: (wrap: Wrap) => void
  selectAccentWrap: (wrap: Wrap) => void
}

type Props = OwnProps & ReduxProps & DispatchProps

class DesktopOptionsSelector extends React.Component<Props> {
  render() {
    return (
      <React.Fragment>
        {this.props.editorPanelState === EditorPanelState.SelectMaterialOpen && (
          <DesktopMaterialSelector
            type="materials"
            title={this.props.translate('editor.materials.title')}
            icon={MaterialsIcon}
            selectedWrap={this.props.selectedPanelGroupWrap}
            onWrapSelected={this.props.selectWrap}
            onBackClick={this.onBackSelected}
            showPanelGroupFilter={true}
          />
        )}

        {this.props.editorPanelState === EditorPanelState.SelectTintOpen && (
          <DesktopTintSelector
            onBackClick={this.onBackSelected}
          />
        )}

        {this.props.editorPanelState === EditorPanelState.SelectAccentOpen && (
          <DesktopMaterialSelector
            type="accents"
            title={this.props.translate('editor.accents.title')}
            icon={AccentsIcon}
            selectedWrap={this.props.selectedAccentWrap}
            onWrapSelected={this.props.selectAccentWrap}
            onBackClick={this.onBackSelected}
            showPanelGroupFilter={false}
          />
        )}

        {this.props.editorPanelState === EditorPanelState.SelectPpfOpen && (
          <DesktopFilmSelector onBackClick={this.onBackSelected} />
        )}
      </React.Fragment>
    )
  }

  onBackSelected = () => {
    this.props.selectPanelGroup(null)
    this.props.setEditorPanelState(EditorPanelState.Closed)
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      sceneMode: selectors.getSelectedSceneMode(state),
      editorPanelState: selectors.getEditorPanelState(state),
      selectedPanelGroup: selectors.getSelectedPanelGroup(state),
      temporarilySelectedPanelGroupWrap: selectors.getTemporarilySelectedWrapForSelectedPanelGroup(
        state
      ),
      selectedPanelGroupWrap: selectors.getSelectedWrapForSelectedPanelGroup(
        state
      ),
      selectedAccentWrap: selectors.getSelectedAccentWrap(state),
      temporarilySelectedAccentWrap: selectors.getTemporarilySelectedAccentWrap(
        state
      ),
      translate: selectors.getTranslator(state)
    }
  },
  {
    setEditorPanelState,
    selectPanelGroup,
    selectWrap,
    selectAccentWrap,
    temporarilySelectAccentWrap,
    applyTemporarilySelectedAccentWrap
  }
)(DesktopOptionsSelector)
