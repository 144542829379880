import Dispatch from '../Dispatch'
import GetState from '../GetState'
import PaintProtectionFilm from '../../../../../core/films/PaintProtectionFilm'

export default (film:PaintProtectionFilm|null) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch({
      type: 'SELECT_PAINT_PROTECTION_FILM',
      film: film
    })
  }
}