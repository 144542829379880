import Polyfills from './helpers/polyfills'
Polyfills.apply()

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import createStore from './store/createStore'

require('../scss/application.scss')

const startup = () => {
  const root = document.getElementById('root')
  if (!root) {
    console.error('No `root` element found. Aborting launch.')
    return
  }

  const store = createStore()
  ;(window as any)['store'] = store // Just a helpful debugger so you can type `store.getState()` in your browser console to see the current state.

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    root
  )
}

startup()