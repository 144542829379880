import * as dat from 'dat.gui'
import THREE from '../../three/threeWithExtensions'
import IMaterial from '../IMaterial';

class AbstractWrapMaterial implements IMaterial {
  _material:THREE.Material = null

  _loadPromise:Promise<void> = null
  _isLoaded:boolean = false

  constructor() {

  }

  setRenderMaterial = (material:THREE.Material):void => {
    if (!material) {
      throw new Error('material is required')
    }

    this._material = material
	}

	getRenderMaterial = ():THREE.Material => {
		return this._material
  }

  configureVehicleColorMap = (colorMap:THREE.Texture):void => {
    // For subclasses to implement
  }

  configureEnvironmentMaps = (dayEnvMap:any, nightEnvMap:any):void => {
    // For subclasses to implement
  }

  setEnvironmentMapDaytimePercentage = (daytimePercentage:number):void => {
    // For subclasses to implement
  }

  configureGui = (gui:dat.GUI):void => {
    // https://threejs.org/docs/scenes/js/material.js
    // folder = this._gui.addFolder('THREE.MeshPhysicalMaterial')
    // folder.addColor(_paintMaterialData, 'color' ).onChange(handlePropertyChange)
    // folder.add(_paintMaterialData, 'metalness', 0, 1 ).step(0.01).onChange(handlePropertyChange)
  }

  unloadGui = (gui:dat.GUI):void => {
    // For subclasses to implement
  }

  load = (basePath:string):Promise<void> => {
    // For subclasses to implement. Should always return a promise.
    // Remember to call `this.isLoaded = true` before resolving!
    return new Promise( (resolve, reject) => {
      this._isLoaded = true
      resolve() // Assume it's good if the texture doesn't override because it doesn't need to do anything.
    })
  }
  
  isLoading = ():boolean => {
    return (this._loadPromise != null && !this.isLoaded())
  }

	isLoaded = ():boolean => {
		return this._isLoaded
  }
  
  getLoadPromise = ():Promise<void> => {
    return this._loadPromise;
  }

  setLoadPromise = (promise:Promise<void>) => {
    if (!promise) {
      throw new Error('promise is required')
    }

    this._loadPromise = promise
    this._loadPromise.then( () => {
      this._isLoaded = true
    })
  }
}

export default AbstractWrapMaterial