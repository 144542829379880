import * as React from 'react'

interface OwnProps {
  width?: number
  height?: number
}

const WIDTH = 65.04
const HEIGHT = 65.48
const RATIO = HEIGHT / WIDTH 

export default function Icon(props:OwnProps) {
  let { width, height, ...rest } = props
  if (!width) {
    width = WIDTH
  }

  if (!height) {
    height = width * RATIO
  }

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 65.04 65.48"
      width={width}
      height={height}
    >
      <polygon
        fill='none'
        stroke='#000000'
        strokeWidth={5}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        points="2.5,33.73 31.11,33.73 31.11,62.98 62.54,2.5"
        {...rest}
      />
    </svg>
  )
}
