import * as React from 'react'
import { connect } from 'react-redux'
import { animated, useSpring } from 'react-spring'

import { syncDispatch } from '../../actionCreators/dispatch'
import AppState from '../../store/models/AppState'
import selectors from '../../store/selectors'
import temporarilySelectVehicle from '../../actionCreators/temporarilySelectVehicle'
import selectVehicle from '../../actionCreators/selectVehicle'
import setEditorPanelState from '../../actionCreators/editorPanels/setEditorPanelState'
import Vehicle from '../../../../../core/vehicles/Vehicle'
import EditorPanelState from '../../store/models/EditorPanelState'
import VehicleWheel from './VehicleWheel'
import EnvironmentConfig from '../../../../../core/EnvironmentConfig'
import Scrim from './Scrim'

interface OwnProps {}

interface ReduxProps {
  environmentConfig: EnvironmentConfig,
  editorState: EditorPanelState
  vehicles: Array<Vehicle>
  temporaryVehicle: Vehicle
  temporaryVehicleIsLoading: boolean
  selectedVehicle: Vehicle
  desktop: boolean
  isiOS: boolean
}

interface DispatchProps {
  setEditorPanelState: (panelState: EditorPanelState) => void
  selectVehicle: (vehicle: Vehicle) => void
  temporarilySelectVehicle: (vehicle: Vehicle) => void
}

type Props = OwnProps & ReduxProps & DispatchProps

function ChangeVehicleSelector(props: Props) {
  let isOpen = props.editorState === EditorPanelState.ChangeVehicleOpen
  let openSpring = useSpring({
    value: isOpen ? 1 : 0,
    config: { mass: 1, tension: 500, friction: 31 }
  })
  return (
    <React.Fragment>
      <Scrim
        show={isOpen}
        openSpring={openSpring.value}
        onClick={() => {
          props.setEditorPanelState(EditorPanelState.Closed)
        }}
      />

      {isOpen && (
        <VehicleWheel
          basePath={props.environmentConfig.getAssetBasePath()}
          desktop={props.desktop}
          isiOS={props.isiOS}
          openSpring={openSpring.value}
          vehicles={props.vehicles}
          selectedVehicle={props.selectedVehicle}
          onSelect={(vehicle) => {
            props.selectVehicle(vehicle)
          }}
          onClose={() => {
            props.setEditorPanelState(EditorPanelState.Closed)
          }}
        />
      )}
    </React.Fragment>
  )
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      environmentConfig: selectors.getEnvironmentConfig(state),
      editorState: selectors.getEditorPanelState(state),
      vehicles: selectors.getVehicles(state),
      temporaryVehicle: selectors.getTemporarilySelectedVehicle(state),
      temporaryVehicleIsLoading: selectors.isTemporaryVehicleLoading(state),
      selectedVehicle: selectors.getSelectedVehicle(state),
      desktop: selectors.isDesktopViewport(state),
      isiOS: selectors.isiOS(state)
    }
  },
  {
    setEditorPanelState,
    temporarilySelectVehicle,
    selectVehicle
  }
)(ChangeVehicleSelector)
