import Dispatch from '../Dispatch'
import GetState from '../GetState'
import Coordinate from '../../store/models/Coordinate'
import searchForRestylers from './searchForRestylers'

const tryToGetLocation = ():Promise<Coordinate> => {
  return new Promise( (resolve, reject) => {
    if (!window.navigator || !window.navigator.geolocation || !navigator.geolocation.getCurrentPosition) {
      reject("Unsupported browser")
      return
    }

    navigator.geolocation.getCurrentPosition(
      (position: Position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        })
      }, 
      (error) => {
        reject(error)
      }
    )
  })
}

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    let coordinate = null
    try {
      coordinate = await tryToGetLocation()
    }
    catch(e) { 
      console.warn(e)
    }

    if (!coordinate) {
      console.log('Unable to determine browser coordinate!')
      window.alert('Location lookup failed')
      return
    }

    if (coordinate) {
      dispatch({
        type: 'SET_BROWSER_LOCATION',
        coordinate: coordinate
      })

      dispatch(searchForRestylers(coordinate.latitude + ', ' + coordinate.longitude, true))
    }
  }
}