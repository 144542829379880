import * as React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import AppState from '../../store/models/AppState'

import GoogleMap, { ClusterIconStyle } from './GoogleMap'
import Marker from './Marker'

import restylerSelectors from '../../store/selectors/restylers'
import RestylerSearchResult from '../../store/models/RestylerSearchResult'
import Coordinate from '../../store/models/Coordinate'
import askForBrowserLocation from '../../actionCreators/findRestyler/askForBrowserLocation'
import selectRestyler from '../../actionCreators/findRestyler/selectRestyler'
import LocationArrow from '../../components/icons/LocationArrow'
import setMapCoordinate from '../../actionCreators/findRestyler/setMapCoordinate'
import setMapZoom from '../../actionCreators/findRestyler/setMapZoom'

interface OwnProps {
  className?: string
  mapClassName?: string
  locationClassName?: string
  mapStyles?: any
}

/// JFC. I am so sorry about the optionals. 
/// These properties are not actually optional, but `connect` was blowing shit up when using the `forwardRef` option.
interface ReduxProps {
  selectedMarkerImagePath?: string
  unselectedMarkerImagePath?: string
  clusterMarkerImagePath?: string
  googleMapsApiKey?: string
  googleMapsClient?: string
  isSearching?: boolean
  searchResults?: Array<RestylerSearchResult>
  mapCenter?: Coordinate
  mapZoom?: number
  selectedRestyler?: RestylerSearchResult
}

interface DispatchProps {
   // Sorry about making these optional, but forwardRef was breaking things.
  askForBrowserLocation?: (userInitiated:boolean) => any
  selectRestyler?: (restyler:RestylerSearchResult) => any
  setMapCoordinate?: (coordinate:Coordinate) => any
  setMapZoom?: (newZoom:number) => any
}

interface State {
}

const CLUSTER_IMAGE_SIZE = 42
const CLUSTER_IMAGE_SIZES:Array<number> = [CLUSTER_IMAGE_SIZE, CLUSTER_IMAGE_SIZE, CLUSTER_IMAGE_SIZE, CLUSTER_IMAGE_SIZE, CLUSTER_IMAGE_SIZE]

const buildClusterIconStyles = (clusterMarkerImagePath:string):Array<ClusterIconStyle> => {
  const style:ClusterIconStyle = {
    width: CLUSTER_IMAGE_SIZE,
    height: CLUSTER_IMAGE_SIZE,
    url: clusterMarkerImagePath,
  }

  return [style, style, style, style, style]
}

class RestylerMap extends React.Component<OwnProps & ReduxProps & DispatchProps, State> {
  _map:GoogleMap

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.askForBrowserLocation(false)
  }

  setMapRef = (element) => {
    this._map = element
  }

  getHeight = ():number => {
    return this._map.getHeight()
  }

  askForBrowserLocation = () => {
    this.props.askForBrowserLocation(true)
  }

  renderLocationButton = () => {
    return (
      <div 
        className={cx(this.props.locationClassName)} 
        onClick={this.askForBrowserLocation}
      >
        <LocationArrow width={25} />
      </div>
    )
  }

  render() {
    return (
      <div className={cx(this.props.className)}>
        <GoogleMap
          ref={this.setMapRef}
          className={cx(this.props.mapClassName)}
          apiKey={this.props.googleMapsApiKey}
          client={this.props.googleMapsClient}
          mapOptions={{
            disableDefaultUI: true,
            zoom: 8,
            center: { 
              lat: this.props.mapCenter.latitude, 
              lng: this.props.mapCenter.longitude 
            }
          }}
          clusterOptions={{
            imageSizes: CLUSTER_IMAGE_SIZES,
            gridSize: 45,
            enableRetinaIcons: true,
            styles: buildClusterIconStyles(this.props.clusterMarkerImagePath),
            calculator: (markers:Marker[], clusterIconStylesCount:number) => {
              return {
                index: 0,
                text: '',
                title: 'Multiple restylers'
              }
            }
          }}
          center={{ 
            lat:this.props.mapCenter.latitude, 
            lng: this.props.mapCenter.longitude
          }}
          onCenterChanged={(newCenter:google.maps.LatLng) => {
            this.props.setMapCoordinate({
              latitude: newCenter.lat(),
              longitude: newCenter.lng()
            })
          }}
          zoom={this.props.mapZoom}
          onZoomChanged={(newZoom:number) => {
            this.props.setMapZoom(newZoom)
          }}
          onMarkerSelected={this._onMarkerSelected}
          styles={this.props.mapStyles}
          mapChildren={this.renderLocationButton()}
        >
          {this.props.searchResults && this.props.searchResults.map( (restyler, index) => {
            const isSelected = restyler === this.props.selectedRestyler
            return (
              <Marker 
                key={restyler.key}
                identifier={restyler.key}
                isSelected={isSelected}
                label={{
                  color: 'white',
                  fontFamily: '3MCircular',
                  fontSize: '14px',
                  text: isSelected ? ' ' /* For some reason, an empty space is needed. A literal blank results in [object object] being displayed. */ : (index + 1).toString()
                }}
                position={{ lat:restyler.lat, lng:restyler.lon }}
                title={restyler.companyName}
                icon={{
                  labelOrigin: new google.maps.Point(16, 17), // No need to do an isSelected because there is no label for the selected version
                  url: isSelected ? this.props.selectedMarkerImagePath : this.props.unselectedMarkerImagePath,
                  scaledSize: isSelected ? new google.maps.Size(42, 51) : new google.maps.Size(32, 39)
                }}
                zIndex={isSelected ? 1 : 0}
                onMarkerCreated={(marker) => {
                  google.maps.event.addListener(marker, 'click', this._onMarkerSelected)
                }}
              />
            )
          })}
        </GoogleMap>
      </div>
    )
  }

  _onMarkerSelected = (evt:any) => {
    const restyler = this.props.searchResults.find( (restyler) => {
      return (
        restyler.lat === evt.latLng.lat() 
        && restyler.lon === evt.latLng.lng()
      )
    })
    
    if (!restyler) {
      console.warn('Pin selected, but no restyler found!')
      return
    }

    this.props.selectRestyler(restyler)
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      selectedMarkerImagePath: restylerSelectors.getSelectedMarkerImagePath(state),
      unselectedMarkerImagePath: restylerSelectors.getUnselectedMarkerImagePath(state),
      clusterMarkerImagePath: restylerSelectors.getClusterMarkerImagePath(state),
      googleMapsApiKey: restylerSelectors.getGoogleMapsApiKey(state),
      googleMapsClient: restylerSelectors.getGoogleMapsClient(state),
      mapCenter: restylerSelectors.getMapCenterCoordinate(state),
      mapZoom: restylerSelectors.getMapZoom(state),
      isSearching: restylerSelectors.isSearching(state),
      searchResults: restylerSelectors.getSearchResults(state),
      selectedRestyler: restylerSelectors.getSelectedRestyler(state)
    }
  },
  {
    askForBrowserLocation, selectRestyler, setMapCoordinate, setMapZoom
  },
  null,
  {
    forwardRef: true // Necessary so we can expose our instance methods to parents.
  }
)(RestylerMap)