import * as React from 'react'
import AbstractFormInput from './AbstractFormInput'
import Required from './validators/Required'

interface OwnProps {
	label?: string,
	placeholder?: string,
	onChange?: (newValue:string) => any
}

interface DispatchProps {

}

interface State {
  value:string
}

export default class TextArea extends AbstractFormInput<OwnProps & DispatchProps, State> {
  _input:any

  constructor(props) {
		super(props)

		this.state = {
			value: '',
      error: null
		}
	}

  render() {
		return <React.Fragment>
			{this.props.label && <label>{this.props.label}</label>}
			<textarea
        {...this.props}
        ref={(element) => { this._input = element }}
				value={this.state.value}
        onChange={this._onValueChanged}
        onBlur={this._onBlur}
			/>
			{this.state.error && <p className='error'>{this.state.error}</p>}
		</React.Fragment>
	}

	getValue = () => {
		return this.state.value
  }

  focus = () => {
    this._input.focus()
  }

  _onBlur = (evt) => {
    this.validate()
  }

	_onValueChanged = (evt) => {
		this.setState({ value: evt.target.value }, () => {
			if (this.props.onChange) {
				this.props.onChange(evt.target.value)
			}
		})
	}
}