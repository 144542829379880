import * as React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import AppState from '../../store/models/AppState'
import selectors from '../../store/selectors'
import Vehicle from '../../../../../core/vehicles/Vehicle'
import SceneMode from '../../../../../core/SceneMode'
import PanelGroup from '../../../../../core/vehicles/PanelGroup'
import SceneModeSelector from '../editor/SceneModeSelector'
import ChangeVehicleSelector from '../editor/ChangeVehicleSelector'
import DesktopOptionsSelector from '../editor/desktop/DesktopOptionsSelector'
import YourDesignSelector from '../editor/YourDesignSelector'
import DesktopButtons from '../editor/desktop/DesktopButtons'
import MobileLowerButtons from '../editor/mobile/MobileLowerButtons'
import EditorPanelState from '../../store/models/EditorPanelState'
import MobileOptionsSelector from '../editor/mobile/MobileOptionsSelector'
import openPanelGroupSelector from '../../actionCreators/editorPanels/openPanelGroupSelector'
import openWindowTintPanel from '../../actionCreators/editorPanels/openWindowTintPanel'
import openAccentPanel from '../../actionCreators/editorPanels/openAccentPanel'
import setEditorPanelState from '../../actionCreators/editorPanels/setEditorPanelState'
import selectPanelGroup from '../../actionCreators/wraps/selectPanelGroup'
import Loading from '../Loading'
import BaseView from './BaseView'
import Mouse from '../Mouse'

interface OwnProps {}

interface ReduxProps {
  sceneMode: SceneMode,
  isDesktop: boolean,
  isIPad: boolean,
  vehicle: Vehicle,
  isPreloading: boolean,
  isLoading: boolean,
  editorPanelState: EditorPanelState
}

interface DispatchProps {
  openPanelGroupSelector: () => void
  openWindowTintPanel: () => void
  openAccentPanel: () => void
  setEditorPanelState: (panel: any) => void
  selectPanelGroup: (panelGroup: PanelGroup) => void
}

type Props = OwnProps & ReduxProps & DispatchProps

interface State { }

function slideCanvasDown() {
  document.getElementById('canvas').style.transform = 'none'
}

class VehicleEditView extends React.Component<Props, State> {
  render() {
    if (this.props.isPreloading) {
      return null
    }

    const showDesktopUI = this.props.isDesktop && !this.props.isIPad
    
    if (showDesktopUI) {
      // force the canvas to slide down in situations where the resolution has changed and we switched from mobile to desktop
      slideCanvasDown()
    }

    return (
      <BaseView className={cx("view-vehicle-edit", "selected-" + this.props.vehicle.selectCssClassName)}>
        <div className="top">
          <SceneModeSelector />
        </div>

        <ChangeVehicleSelector />
        <YourDesignSelector />

        {showDesktopUI && this.renderDesktop()}
        {!showDesktopUI && this.renderMobile()}

        {this.props.isLoading && <Loading />}
      </BaseView>
    )
  }

  renderDesktop = () => {
    return (
      <React.Fragment>
        <Mouse />
        <DesktopButtons />
        <DesktopOptionsSelector />
      </React.Fragment>
    )
  }

  renderMobile = () => {
    return (
      <React.Fragment>
        <MobileLowerButtons />
        <MobileOptionsSelector />
      </React.Fragment>
    )
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      isDesktop: selectors.isDesktopViewport(state),
      isIPad: selectors.isIPad(state),
      sceneMode: selectors.getSelectedSceneMode(state),
      vehicle: selectors.getSelectedVehicle(state),
      isPreloading: selectors.isPreloading(state),
      isLoading: selectors.areUserSelectionsLoading(state),
      editorPanelState: selectors.getEditorPanelState(state)
    }
  },
  {
    openPanelGroupSelector,
    openWindowTintPanel,
    openAccentPanel,
    setEditorPanelState,
    selectPanelGroup
  }
)(VehicleEditView)
