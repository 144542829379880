import * as React from 'react'
import cx from 'classnames'

// SVGs
import ArrowIcon from '../../icons/Arrow'

type FilterMenuItem<V> = {
  label: string
  value: V
}

type FilterMenuProps<V> = {
  allLabel: string
  items: Array<FilterMenuItem<V>>
  selected: V
  onSelect: (value: V) => void
  right: number
}

function DesktopFilterMenu<V>(props: FilterMenuProps<V>) {
  // a better name for this would be "open"
  let [hovered, setHovered] = React.useState(false)
  let selectedItem = props.items.find((item) => {
    return item.value === props.selected
  })

  let open = hovered

  return (
    <div
      className={cx('filter-menu', hovered && 'hovered')}
      style={{ right: props.right }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ul className={cx('v-list')}>
        {props.items.map((item) => {
          return (
            <li
              key={item.label + item.value}
              className={cx(
                selectedItem && selectedItem.label === item.label && 'selected'
              )}
            >
              <button
                className={cx(
                  selectedItem &&
                    selectedItem.label === item.label &&
                    'selected'
                )}
                onClick={() => props.onSelect(item.value)}
              >
                {item.label}
              </button>
            </li>
          )
        })}
        <li>
          {open ? (
            <button
              onClick={() => props.onSelect(null)}
              className={cx(selectedItem == null && 'selected')}
            >
              {props.allLabel}
            </button>
          ) : (
            /* closed */
            <button
              onClick={() => {
                /* do nothing */
              }}
              className="selected"
            >
              {selectedItem != null && !open
                ? selectedItem.label
                : props.allLabel}
            </button>
          )}
        </li>
      </ul>
      <div className="filter-menu__icon">
        <ArrowIcon direction={open ? 'down' : 'up'} width={14} />
      </div>
    </div>
  )
}

export default DesktopFilterMenu
