import THREE from '../../three/threeWithExtensions'
import IMaterial from '../IMaterial'

class BakedMaterial implements IMaterial {
  _shouldUseVehicleColorMap:boolean
	_material:THREE.MeshStandardMaterial = null

	constructor(material:THREE.Material, shouldUseVehicleColorMap:boolean) {
		if (!material) {
			throw new Error('material is required')
		}
    this._material = material
    this._shouldUseVehicleColorMap = shouldUseVehicleColorMap
	}

	getRenderMaterial = ():THREE.Material => {
		return this._material
	}

  configureVehicleColorMap = (colorMap:THREE.Texture):void => {
    if (this._shouldUseVehicleColorMap) {
      this._material.map = colorMap
    }
  }

	configureEnvironmentMaps = (dayEnvMap:any, nightEnvMap:any):void => {
		// TODO: Support swapping day/night
		this._material.envMap = dayEnvMap
	}

	setEnvironmentMapDaytimePercentage = (daytimePercentage:number):void => {
		// TODO: Support swapping day/night
	}

  isLoaded = ():boolean => {
		return true
	}

  load = (basePath:string):Promise<void> => {
		return new Promise( (resolve, reject) => {
      resolve()
    })
	}
}

export default BakedMaterial