import * as React from "react";
import cx from "classnames";

import TranslationComponent from "../TranslationComponent";

interface OwnProps {
  className?: string;
  distanceInMiles: number
  displayUnit: string // "mi" | "km"
}

interface ReduxProps {
}

interface DispatchProps {
}

interface State {}

export default class DistanceDisplay extends React.Component<OwnProps & ReduxProps & DispatchProps, State> {
  render = () => {
    return (
      <span className={cx('distanceDisplay', this.props.className)}>
        {this.getDistanceNumber()}
        {this.getDistanceUnit()}
        {' '}
        <TranslationComponent localizationKey="search.distance-away" />
      </span>
    )
  }

  getDistanceNumber = ():string => {
    if (this.props.displayUnit === 'mi') {
      return this.roundToNearestTenth(this.props.distanceInMiles)
    }

    const KILOMETERS_PER_MILE = 1.609344;
    const kilometers = this.props.distanceInMiles * KILOMETERS_PER_MILE
    return this.roundToNearestTenth(kilometers)
  }

  roundToNearestTenth = (value:number):string => {
    let output = Math.round(value * 10) / 10
    return output.toFixed(1)
  }

  getDistanceUnit = ():string => {
    if (this.props.displayUnit === 'mi') {
      return 'mi'
    }

    return 'km'
  }
}