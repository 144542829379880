import * as React from 'react'
import cx from 'classnames'
import { normalize } from 'path'

type LimitFunction = () => number

type Props = {
  className?:string
  fullyOpenClassName?: string
  handleClassName?:string
  innerClassName?:string
  limit: number | LimitFunction
  slowness: number // Larger means slower. Sorry.
  onPositionChanged?: (top:number) => void
}

type State = {
  openPercentage: number,
  animationPercentage: number
}

type Coordinate = {
  x: number,
  y: number
}

export default class SlidePanel extends React.Component<Props, State> {
  _mounted:boolean = false
  _onAnimationCompleteCallback:Function = null

  _handle:HTMLElement
  _inner:HTMLElement

  _lastCoordinate:Coordinate|null

  constructor(props) {
    super(props)

    this.state = {
      openPercentage: 0.0,
      animationPercentage: 0.0
    }
  }

  _findElement = (selector:string):HTMLElement|null => {
    if (!this._inner) {
      return null
    }

    return this._inner.querySelector(selector)
  }

  openToSelector = (selector:string, adjustment:number=0, completeCallback:Function=null) => {
    const element = this._findElement(selector)
    if (!element) {
      console.warn('SlidePanel openToSelector called, but no matching element found for selector `' + selector + '`!')  
    }

    this.openToElement(element, adjustment, completeCallback)
  }

  openToElement = (element:HTMLElement, adjustment:number=0, completeCallback:Function=null) => {
    if (!element) {
      console.warn('SliderPanel.openToElement called, but no element passed in!')
      return
    }
    if (!this._inner) {
      console.warn('SliderPanel.openToElement called, but this._inner not set yet!')
      return
    }

    const elementRect = element.getBoundingClientRect()
    const openTo = elementRect.height + adjustment // We only have to care about the height because we're going to scroll the thing into view later.
    const normalizedOpenTo = (openTo / this._getScalarLimit())
    this._setOpenPercentage(normalizedOpenTo, completeCallback)
  }

  // The height should be how far you want the SlidePanel to move up, NOT including the slider handle.
  // So if you want the SlidePanel to be 100px from where it is, simply pass in 100px. No math required.
  openToHeight = (height:number, completeCallback:Function=null) => {
    const normalizedOpenTo = (height / this._getScalarLimit())
    this._setOpenPercentage(normalizedOpenTo, completeCallback)
  }

  componentDidMount = () => {
    this._mounted = true
    this._animationLoop()
  }

  componentWillUnmount() {
    this._mounted = false
    this._destroyAllListeners()
  }

  _setInnerRef = (element) => {
    this._inner = element
  }

  _setHandleRef = (element) => {
    if (!element) {
      if (this._handle) { // This can happen when we toggle between desktop & mobile viewports (When the SlidePanel gets mounted & unmounted).
        this._destroyAllListeners()
        this._handle = null
      }
      return
    }

    
    this._handle = element
    this._handle.addEventListener('mousedown', this._onHandleDown)
    this._handle.addEventListener('touchstart', this._onHandleDown)
    this._handle.addEventListener('click', this.toggle)
  }

  _onHandleDown = (evt) => {
    const coordinate = this._tryToGetCoordinate(evt)
    this._lastCoordinate = coordinate

    document.addEventListener('mousemove', this._onHandleMove)
    document.addEventListener('touchmove', this._onHandleMove)

    document.addEventListener('mouseup', this._onHandleUp)
    document.addEventListener('touchend', this._onHandleUp)
    document.addEventListener('touchcancel', this._onHandleUp)
  }

  _onHandleMove = (evt) => {
    const coordinate = this._tryToGetCoordinate(evt)
    const difference = this._verticalDifference(this._lastCoordinate, coordinate)
    this._lastCoordinate = coordinate

    const normalizedDelta = -1 * (difference / this._getScalarLimit())
    
    let newValue = this.state.openPercentage + normalizedDelta
    this._setOpenPercentage(newValue)
  }

  _setOpenPercentage = (newValue:number, onAnimationCompleteCallback:Function = null) => {
    if (newValue < 0.0) {
      newValue = 0.0
    }
    else if (newValue > 1.0) {
      newValue = 1.0
    }

    this._onAnimationCompleteCallback = onAnimationCompleteCallback // Even if it's null, we save it, as we want to nuke any old callbacks when a new one comes in.

    this.setState({ openPercentage: newValue})
  }

  _onHandleUp = (evt) => {
    this._destroyMoveListeners()
  }

  _destroyAllListeners = () => {
    this._handle.removeEventListener('mousedown', this._onHandleDown)
    this._handle.removeEventListener('touchstart', this._onHandleDown)
    this._handle.removeEventListener('click', this.toggle)
    this._destroyMoveListeners()
  }

  _destroyMoveListeners = () => {
    document.removeEventListener('mousemove', this._onHandleMove)
    document.removeEventListener('touchmove', this._onHandleMove)
    document.removeEventListener('mouseup', this._onHandleUp)
    document.removeEventListener('touchend', this._onHandleUp)
  }

  _getScalarLimit = () => {
    return (typeof this.props.limit === 'function') ? this.props.limit() : this.props.limit
  }

  _verticalDifference = (a:Coordinate, b:Coordinate):number => {
    return b.y - a.y
  }

  _tryToGetCoordinate = (evt):Coordinate|null => {
    switch(evt.type) {
      case 'touchstart':
      case 'touchmove':
      case 'touchend':
      case 'touchcancel':
        return {
          x: evt.touches[0].clientX,
          y: evt.touches[0].clientY
        }
      case 'mousemove':
      case 'mouseup':
      case 'mousedown':
        return {
          x: evt.clientX,
          y: evt.clientY
        }
      default:
        return null
    }
  }

  toggle = () => {
    if (this.state.openPercentage > 0) {
      this.close()
    }
    else {
      this.open()
    }
  }

  open = () => {
    this._setOpenPercentage(1.0)
  }

  close = () => {
    this._setOpenPercentage(0)
  }

  _animationLoop = () => {
    if (!this._mounted) {
      return
    }

    const difference = (this.state.openPercentage - this.state.animationPercentage)
    if (difference === 0.0) {
      if (this._onAnimationCompleteCallback) {
        this._onAnimationCompleteCallback()
        this._onAnimationCompleteCallback = null
      }
    }
    else {
      let newPercentage = NaN
      if (Math.abs(difference) <= 0.0001) {
        newPercentage = this.state.openPercentage
      }
      else {
        newPercentage = this.state.animationPercentage + (difference / this.props.slowness)
      }

      this.setState({animationPercentage: newPercentage})

      if (this.props.onPositionChanged) {
        const top = this.calculateTop(newPercentage)
        this.props.onPositionChanged(top)
      }
    }
    
    window.requestAnimationFrame(this._animationLoop)
  }

  calculateTop = (percentage:number):number => {
    const top = -1 * percentage * this._getScalarLimit()
    return top
  }

  render() {
    const top = this.calculateTop(this.state.animationPercentage)

    let innerHeight = 0
    if (this._inner) {
      const rect:any = this._inner.getBoundingClientRect()
      if (rect && (rect.y || rect.top)) {
        innerHeight = (window.innerHeight - (rect.y || rect.top))
      }
    }

    // Note, I am manually setting some style properties here.
    // I think they should NOT be defined in a SCSS file, because 
    // they are *core* to how this component works. The component
    // will not function without them here, so I don't think they
    // should be "editable" via a CSS update. If people really
    // want to mess with it, they can !important over them with 
    // their own CSS file.
    return (
      <div
        className={cx(this.props.className, this.state.animationPercentage >= 1.0 && this.props.fullyOpenClassName)}
        style={{
          transform: 'translateY(' + top + 'px)'
        }}
      >
        <div 
          ref={this._setHandleRef}
          className={cx(this.props.handleClassName)}
          style={{
            userSelect: 'none' // Without this, the cursor tries to select content in the page when dragging via the mouse. Very disconcerting to end-users.
          }}
        >
          <span />
        </div>
        <div 
          ref={this._setInnerRef}
          className={cx(this.props.innerClassName)}
          style={{
            height: innerHeight,
            overflowY: 'scroll'
          }}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}