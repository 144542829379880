import UserSelections from './UserSelections'
import VoidPromise from "./utils/VoidPromise"
import partsInstances from '../core/materials/parts/PartsInstances'
import MaterialLookup from './materials/MaterialLookup'
import IMaterial from './materials/IMaterial'

/// Given a users selections, make sure everything (vehicle, materials, tints, etc) are loaded!
class UserSelectionsLoader {
  static load = async (basePath:string, selections:UserSelections, defaultTint:IMaterial, defaultWrap:IMaterial):Promise<void> => {
    await partsInstances.load(basePath) // Probably unecessary to do this since it gets loaded on startup, but won't hurt because the parts instances are smart enough not to load themselves twice.

    const vehicle = selections.getVehicle()
    await vehicle.load(basePath) // Same as above. Vehicle is smart enough not to load twice, so we're safe repeat-calling load on the same vehicle.

		// Make sure the defaults are always available.
		await defaultTint.load(basePath)
		await defaultWrap.load(basePath)

    const tint:IMaterial = selections.getTint() || defaultTint
    const accentWrap:IMaterial = selections.getAccent() || defaultWrap

    const materialPromises:Array<Promise<void>> = []
		vehicle.model.traverse( (child:any) => {
			if (child.isMesh) {
				let material = null
				if (!material && vehicle.isWindowMeshName(child.name)) {
					material = tint
				}

				if (!material && vehicle.isAccentMeshName(child.name)) {
					material = accentWrap
				}

				if (!material) {
					material = MaterialLookup.tryToFindPartMaterialForMesh(child)
				}

				if (!material) {
					const panelGroup = vehicle.getPaintablePanelGroupForMeshName(child.name)
					if (panelGroup) {
						material = selections.getWrapForPanelGroup(panelGroup) || defaultWrap
					}
				}

				if (material) {
					materialPromises.push(material.load(basePath))
				}
			}
		})

		const allPromises = VoidPromise.all(materialPromises)
    return allPromises
  }
}

export default UserSelectionsLoader