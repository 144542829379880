import AppState from "../models/AppState";
import FetchState from "../models/FetchState";
import Coordinate from "../models/Coordinate";
import RestylerSearchResult from "../models/RestylerSearchResult";
import selectors from "../selectors/index";
import * as _ from "underscore";
import RestylerClassification from "../models/RestylerClassification";

const restylerSelectors = {
  getSelectedMarkerImagePath: (state: AppState): string => {
    if (!state || !state.ui || !state.ui.environmentConfig) {
      return "";
    }

    return (
      state.ui.environmentConfig.getAssetBasePath() +
      "assets/images/markers/pin_active@2x.png"
    );
  },

  getUnselectedMarkerImagePath: (state: AppState): string => {
    if (!state || !state.ui || !state.ui.environmentConfig) {
      return "";
    }

    return (
      state.ui.environmentConfig.getAssetBasePath() +
      "assets/images/markers/pin_standard@2x.png"
    );
  },

  getClusterMarkerImagePath: (state: AppState): string => {
    if (!state || !state.ui || !state.ui.environmentConfig) {
      return "";
    }

    return (
      state.ui.environmentConfig.getAssetBasePath() +
      "assets/images/markers/cluster@2x.png"
    );
  },

  getGoogleMapsApiKey: (state: AppState): string | null => {
    if (!state || !state.ui || !state.ui.environmentConfig) {
      return null;
    }

    return state.ui.environmentConfig.getRestylerGoogleMapsApiKey();
  },

  getGoogleMapsClient: (state: AppState): string | null => {
    if (!state || !state.ui || !state.ui.environmentConfig) {
      return null;
    }

    return state.ui.environmentConfig.getRestylerGoogleMapsClient();
  },

  getSearchValue: (state: AppState): string => {
    return state.restylers.searchValue;
  },

  searchValueWasAutomated: (state: AppState): boolean => {
    return state.restylers.searchWasAutomated;
  },

  isSearching: (state: AppState): boolean => {
    return state.restylers.restylerSearchState === FetchState.Loading;
  },

  hasAnySearchResults: (state: AppState): boolean => {
    return (
      state.restylers.searchResults !== null &&
      state.restylers.searchResults.length > 0
    );
  },

  getSearchResults: (state: AppState): Array<any> | null => {
    let results = state.restylers.searchResults;
    if (!results) {
      return null;
    }

    const filters = state.restylers.searchFilters;
    if (filters.length > 0) {
      results = results.filter((restyler) => {
        const translatedRestylerMetaTags = _.map(
          restyler.metaTags,
          (tag: string) => {
            return selectors.translate(
              state,
              "search.filters." + tag.replace(/[ ]/g, "-")
            );
          }
        );
        const matches = _.intersection(filters, translatedRestylerMetaTags);
        return matches.length === filters.length;
      });
    }

    const environmentConfig = selectors.getEnvironmentConfig(state);
    while (results.length > environmentConfig.getRestylerMaxResultCount()) {
      results.pop();
    }

    return results;
  },

  getAvailableSearchFilters: (state: AppState): Array<string> => {
    const results = restylerSelectors.getSearchResults(state);
    if (!results || results.length <= 0) {
      return [];
    }

    let resultMetaTags = _.reduce(
      results,
      (memo, result) => {
        const translatedTags = _.map(result.metaTags, (tag) => {
          const key =
            "search.filters." + tag.replace(/[ ]/g, "-");
          const translatedSelectors = selectors.translate(state, key);
          if (key == translatedSelectors) {
            return null;
          }
          return translatedSelectors;
        });
        memo.push(...translatedTags);
        return memo;
      },
      []
    );

    resultMetaTags = _.filter(resultMetaTags, (value: string) => {
      return !!value;
    });

    resultMetaTags = _.uniq(resultMetaTags);
    resultMetaTags.sort(); // For some reason, the sort order was changing when filters were being selected.

    return resultMetaTags;
  },

  getRestylerClassifications: (
    state: AppState,
    restyler: RestylerSearchResult
  ): Array<RestylerClassification> => {
    if (!restyler) {
      console.warn(
        "restylerSelectors translateClassifications called, but no restyler was supplied!"
      );
      return [];
    }

    if (!restyler.classification) {
      return [];
    }

    let allClassifications: Array<any> = selectors.translateObject(
      state,
      "search.classifications"
    );
    if (!allClassifications) {
      console.warn(
        "restylerSelectors translateClassifications called, but no classifications were found!"
      );
      return [];
    }

    let restylerClassifications = restyler.classification
      .toLowerCase()
      .replace(/:/g, "|")
      .replace(/,/g, "|")
      .split("|");
    restylerClassifications = _.map(restylerClassifications, (classification:string) => {
      return classification.trim()
    })

    const list = [];
    allClassifications.forEach((classification) => {
      const matchingPatterns = classification.patterns.filter((pattern) => {
        return restylerClassifications.indexOf(pattern.toLowerCase()) >= 0;
      });

      if (matchingPatterns.length >= classification.patterns.length) {
        list.push(classification);
      }
    });

    const sortedList = _.sortBy(
      list,
      (classification: RestylerClassification) => {
        return classification.sortOrder;
      }
    );
    return sortedList;
  },

  getSelectedSearchFilters: (state: AppState): Array<string> => {
    return [].concat(state.restylers.searchFilters);
  },

  getCountryCodeDenied: (state: AppState): boolean => {
    return state.restylers.countryCodeDenied;
  },

  getSelectedRestyler: (state: AppState): RestylerSearchResult => {
    return state.restylers.selectedRestyler;
  },

  getMapCenterCoordinate: (state: AppState): Coordinate => {
    if (state.restylers.mapCoordinate) {
      return state.restylers.mapCoordinate;
    }

    const DEFAULT_COORDINATE = {
      latitude: 44.97902,
      longitude: -93.26494,
    };
    if (!state || !state.ui || !state.ui.environmentConfig) {
      return DEFAULT_COORDINATE;
    }

    const coordinate: Coordinate = {
      latitude: parseFloat(
        selectors.translate(state, "search.defaultMapCenter.latitude")
      ),
      longitude: parseFloat(
        selectors.translate(state, "search.defaultMapCenter.longitude")
      ),
    };

    // Just some extra, extra safety to make sure we have actual numbers.
    if (isNaN(coordinate.latitude)) {
      coordinate.latitude = DEFAULT_COORDINATE.latitude;
    }
    if (isNaN(coordinate.longitude)) {
      coordinate.longitude = DEFAULT_COORDINATE.longitude;
    }

    return coordinate;
  },

  getMapZoom: (state: AppState): number => {
    return state.restylers.mapZoom;
  },

  getContactRestyler: (state: AppState): RestylerSearchResult => {
    return state.restylers.contactRestyler;
  },

  getRestylerFormSubmitState: (state: AppState): FetchState => {
    return state.restylers.restylerFormSubmitState;
  },
};

export default restylerSelectors;
