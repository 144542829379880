import * as React from 'react'
import { connect } from 'react-redux'
import { animated, useSpring } from 'react-spring'

import OptionCategoryButton from '../OptionCategoryButton'
import SceneMode from '../../../../../../core/SceneMode'

import AccentsIcon from '../../icons/Accents'
import MaterialsIcon from '../../icons/Materials'
import TintsIcon from '../../icons/Tints'
import ShieldInactive from '../../icons/shields/ShieldInactive'
import ShieldActive from '../../icons/shields/ShieldActive'
import TranslationComponent from '../../TranslationComponent'
import selectors from '../../../store/selectors'
import AppState from '../../../store/models/AppState'
import EditorPanelState from '../../../store/models/EditorPanelState'
import openPanelGroupSelector from '../../../actionCreators/editorPanels/openPanelGroupSelector'
import openWindowTintPanel from '../../../actionCreators/editorPanels/openWindowTintPanel'
import openAccentPanel from '../../../actionCreators/editorPanels/openAccentPanel'
import selectPanelGroup from '../../../actionCreators/wraps/selectPanelGroup'
import openWrapsPanel from '../../../actionCreators/editorPanels/openWrapsPanel'
import openPpfPanel from '../../../actionCreators/editorPanels/openPpfPanel'
import openChangeVehiclePanel from '../../../actionCreators/editorPanels/openChangeVehiclePanel'
import openYourDesignPanel from '../../../actionCreators/editorPanels/openYourDesignPanel'
import PaintProtectionFilm from '../../../../../../core/films/PaintProtectionFilm'

interface OwnProps { }

interface ReduxProps {
  sceneMode:SceneMode
  editorPanelState: EditorPanelState
  selectedFilm: PaintProtectionFilm|null
  paintProtectionFilmsEnabed: boolean
}

interface DispatchProps {
  openWrapsPanel: () => void
  openWindowTintPanel: () => void
  openAccentPanel: () => void
  openPpfPanel: () => void
  openChangeVehiclePanel: () => void
  openYourDesignPanel: () => void
}

type Props = OwnProps & ReduxProps & DispatchProps

export function MobileLowerButtons(props: Props) {
  const visible = props.editorPanelState === EditorPanelState.Closed
  let { v } = useSpring({ v: visible ? 1 : 0 })
  return (
    <animated.div
      className='mobile-lower-buttons'
      style={{
        transform: v.interpolate((v) => `translateY(${(1 - v) * 10}px)`),
        opacity: v,
      }}
    >
      <div className='mobile-lower-buttons__icons'>
        <OptionCategoryButton
          icon={<MaterialsIcon />}
          desktop={false}
          sceneMode={props.sceneMode}
          onClick={props.openWrapsPanel}
        />
        
        <OptionCategoryButton
          icon={<AccentsIcon />}
          desktop={false}
          sceneMode={props.sceneMode}
          onClick={props.openAccentPanel}
        />

        <OptionCategoryButton
          icon={<TintsIcon />}
          desktop={false}
          sceneMode={props.sceneMode}
          onClick={props.openWindowTintPanel}
        />
        
        {props.paintProtectionFilmsEnabed && (
          <OptionCategoryButton
            className={props.selectedFilm ? 'option-category-button--selected' : ''}
            icon={props.selectedFilm ? <ShieldActive width={40} /> : <ShieldInactive width={30} />}
            desktop={false}
            sceneMode={props.sceneMode}
            onClick={props.openPpfPanel}
          />
        )}
      </div>
      <div className='mobile-lower-buttons__links'>
        <div>
          <button onClick={props.openChangeVehiclePanel}>
            <TranslationComponent localizationKey="editor.change-vehicle" />
          </button>
        </div>
        <div>
          <button onClick={props.openYourDesignPanel}>
            <TranslationComponent localizationKey="editor.your-design" />
          </button>
        </div>
      </div>
    </animated.div>
  )
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      sceneMode: selectors.getSelectedSceneMode(state),
      editorPanelState: selectors.getEditorPanelState(state),
      selectedFilm: selectors.getSelectedPaintProtectionFilm(state),
      paintProtectionFilmsEnabed: selectors.isPaintProtectionFilmsEnabled(state)
    }
  },
  {
    openPanelGroupSelector,
    openWindowTintPanel,
    openWrapsPanel,
    openAccentPanel,
    openPpfPanel,
    openChangeVehiclePanel,
    openYourDesignPanel,
    selectPanelGroup
  }
)(MobileLowerButtons)