import * as React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import AppState from '../../store/models/AppState'
import selectors from '../../store/selectors/index'
import restylerSelectors from '../../store/selectors/restylers'
import toggleSearchFilter from '../../actionCreators/findRestyler/toggleSearchFilter'

interface OwnProps {
  className?: string
  labelClassName?: string
  filterClassName?: string
  selectedClassName?: string
}

interface ReduxProps {
  filters:Array<string>
  selectedFilters: Array<string>
  translate: (key:string) => string
  language: string
}

interface DispatchProps {
  toggleSearchFilter: (filter:string) => any
}

interface State {
}


class Filters extends React.Component<OwnProps & ReduxProps & DispatchProps, State> {
  constructor(props) {
    super(props)
  }


  render() {
    if (this.props.filters.length <= 0) {
      return null
    }

    return (
      <div className={cx('find-restyler--filters', this.props.className, this.props.language === 'fr_CA' && 'find-restyler--filtersFrench')}>
        <label className={cx(this.props.labelClassName, 'hidden-below-desk')}>
          {this.props.translate('search.filter-label')}
        </label>
        <ul>
          {this.props.filters.map( (filter) => {
            const isSelected = !!(this.props.selectedFilters.indexOf(filter) >= 0)
            return (
              <li
                key={filter}
                className={cx(this.props.filterClassName, isSelected && this.props.selectedClassName)}
                onClick={() => this.props.toggleSearchFilter(filter)}
                dangerouslySetInnerHTML={{ __html: filter }}
              />
            )
          })}
        </ul>
      </div>
    )
  }
}


export default connect(
  (state: AppState): ReduxProps => {
    return {
      filters: restylerSelectors.getAvailableSearchFilters(state),
      selectedFilters: restylerSelectors.getSelectedSearchFilters(state),
      translate: selectors.getTranslator(state),
      language: selectors.getLanguage(state),
    }
  },
  {
    toggleSearchFilter
  }
)(Filters)