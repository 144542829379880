import Dispatch from '../Dispatch'
import GetState from '../GetState'
import Coordinate from '../../store/models/Coordinate'

export default (level:number) => {
  if (level <= 0) {
    console.warn('setMapZoom called, but level was <= 0. Ignoring.')
    return
  }

  return async (dispatch: Dispatch, getState: GetState):Promise<void> => {
    dispatch({
      type: 'SET_RESTYLER_MAP_ZOOM',
      zoom: level
    })
  }
}