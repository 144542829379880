import * as React from 'react'

interface OwnProps extends google.maps.MarkerOptions {
  identifier: string
  isSelected: boolean
  onMarkerCreated?: (marker:google.maps.Marker) => any
  // See https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerOptions
}

interface State {
}


export default class Marker extends React.Component<OwnProps, State> {

  constructor(props) {
    super(props)
  }

  render() {
    return null
  }
}