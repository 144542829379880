import * as React from 'react'
import { animated, useSpring, config as springConfig } from 'react-spring'
import useGesture from '../../../helpers/react-use-gesture'
import { clamp } from '../../../helpers/math'

// WHERE DID MY PROPS TYPES GO
const DesktopScrollBar = React.forwardRef(function ScrollBar(props: any, ref) {
  let handleWidth = props.width / props.pageCount

  // useReducer?
  let [hovered, setHovered] = React.useState(false)
  let [dragging, setDragging] = React.useState(false)

  let [{ x }, setX] = useSpring(() => ({
    x: 0,
    config: springConfig.stiff
  }));

  (ref as any).current = { setX: (x) => {
    console.log('ScrollBar##setX', x)
    setX({ x: x })
  } }

  let containerRef = React.useRef(null)

  let bind = (useGesture as any)({
    onDown: () => setDragging(true),
    onUp: () => {
      setDragging(false)

      if (x.get() < 0.1 /* TODO tune this */) {
        setX({ x: 0 })
        props.onScroll(0)
      } else if (x.get() > 0.9) {
        let newX = 1
        setX({ x: newX })
        props.onScroll(newX)
      }
    },
    onAction: ({ first, down, delta: [deltaX], temp = 0, initial }) => {
      if (first && containerRef.current && containerRef.current.getBoundingClientRect) {
        let initialPx =
          initial[0] -
          containerRef.current.getBoundingClientRect().left -
          handleWidth / 2
        let x = clamp(0, 1, initialPx / (props.width - handleWidth))
        setX({ x, immediate: false })
        props.onScroll(x)
        return initialPx
      }

      let x = clamp(0, 1, (temp + deltaX) / (props.width - handleWidth))
      setX({ x, immediate: down })
      props.onScroll(x)
      return temp
    }
  })

  React.useEffect(() => {
    setX({ x: 0, immediate: true })
  }, [props.pageCount, props.width])

  let handleTransform = x.interpolate(
    (x) => `translate(${x * (props.width - handleWidth)}px, -50%)`
  )

  return (
    <div
      ref={containerRef}
      style={{
        width: props.width,
        height: 14,
        position: 'relative',
        paddingTop: 12
      }}
      {...bind()}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        style={{
          width: '100%',
          height: 4,
          background: '#e8e8e8',
          position: 'absolute',
          top: 'calc(50% - 2px)',
          left: 0
        }}
      />
      {props.pageCount > 1 ? (
        <>
          <animated.div
            key="handle"
            style={{
              position: 'absolute',
              top: '50%',
              transform: handleTransform,
              height: 20,
              width: props.width / props.pageCount,
              left: 0
            }}
          />
          <animated.div
            key="handle-display"
            style={{
              position: 'absolute',
              top: '50%',
              transform: handleTransform,
              height: dragging || hovered ? 8 : 4,
              width: props.width / props.pageCount,
              left: 0,
              background: dragging ? 'red' : 'black',
              pointerEvents: 'none',
              borderRadius: 10
            }}
          />
        </>
      ) : null}
    </div>
  )
})

export default DesktopScrollBar