enum EditorPanelState {
	Closed,
	ChangeVehicleOpen,
	YourDesignOpen,
	SelectPanelOpen,
	SelectMaterialOpen,
	SelectTintOpen,
	SelectAccentOpen,
	SelectPpfOpen
}

export default EditorPanelState