export default (name:string ,value:string):string => {
	if (!value) {
		return null // This validator doesn't check required-ness.
	}

	let errorMessage = 'Must be a valid email address'
	if (name) {
		errorMessage = name + ' must be a valid email address'
	}

	const indexOfAt = value.indexOf('@')
	if (indexOfAt <= 0) {
		return errorMessage
	}

	if (indexOfAt >= value.length) {
		return errorMessage
	}

	const indexOfPeriod = value.indexOf('.', indexOfAt)
	if (indexOfPeriod <= 0) {
		return errorMessage
	}

	if (indexOfPeriod >= value.length - 2) {
		return errorMessage
	}

	return null
}