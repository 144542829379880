import * as React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import AppState from '../../store/models/AppState'
import selectors from '../../store/selectors'
import LogoIcon from '../../components/icons/Logo'
import TranslationComponent from '../TranslationComponent'
import navigateBack from '../../actionCreators/navigateBack'
import resetExperience from '../../actionCreators/resetExperience'
import openModal from '../../actionCreators/modals/openModal'
import Modal from '../../store/models/Modal'
import FetchState from '../../store/models/FetchState'
import ArrowIcon from '../icons/Arrow'
import BackButton from '../BackButton'
import View from '../../store/models/View'
import navigateTo from '../../actionCreators/navigateTo'
import BaseView from './BaseView'

interface OwnProps { 

}

interface ReduxProps {
  shareUploadState: FetchState
  shareImageURL:string
}

interface DispatchProps { 
  resetExperience():void
  navigateBack():void,
  navigateTo(view:View):void
  openModal(modal:Modal):void
}

interface State {
}

class FinaleView extends React.Component<OwnProps & ReduxProps & DispatchProps, State> {
  constructor(props) {
    super(props)
  }

  onBackSelected = () => {
    this.props.navigateBack()
  }

  onRestartSelected = () => {
    this.props.resetExperience()
  }

  onFindRestylerSelected = () => {
    this.props.navigateTo(View.FindRestyler)
  }

  onShareSelected = () => {
    this.props.openModal(Modal.Share)
  }

  render() {
    return (
      <BaseView className='view-finale'>
        <div className="finale-content">
          <div className='island--quads'>
            <h1 className='h1'>
              <TranslationComponent localizationKey='finale.title' />
            </h1>
            <a href="#" onClick={this.onFindRestylerSelected} className='btn 3m_btn_FindRestyler'>
              <TranslationComponent localizationKey='finale.restylerAction' />
            </a>
          </div>

          <div className="finale-footer">
            <div className="finale-btn-group">
              <div className="site-wrapper">
                <div className='gw'>
                  <div className={cx('g', 'one-half')}>
                    <a href="#" onClick={this.onShareSelected} className="btn btn--outline 3m_btn_Share">
                      <TranslationComponent localizationKey='finale.shareAction' />
                    </a>
                  </div>
                  <div className="g one-half">
                    <a href={this.props.shareImageURL} 
                      className={cx("btn btn--outline 3m_btn_Download", this.props.shareUploadState !== FetchState.Success && 'disabled')} 
                      download target="_new"
                    >
                      <TranslationComponent localizationKey='finale.downloadAction' />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <a href="#" className="finale-reset-link a--white" onClick={this.onRestartSelected}>
              <TranslationComponent localizationKey='finale.restartAction' />
              <ArrowIcon direction="right" width={12} />
            </a>
          </div>
        </div>
        
        {/* Yuck. I'm sorry I had to put this last, but it needed to be after the finale-content */}
        <div className="finale-header">
          <BackButton className='finale-back' onClick={this.onBackSelected} />
          <a className='finale-logo' href="./"><LogoIcon width={52} /></a>
        </div>
      </BaseView>
    )
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      shareUploadState: selectors.getShareImageUploadState(state),
      shareImageURL: selectors.getShareImageURL(state),
    }
  },
  {
    navigateBack,
    resetExperience,
    navigateTo,
    openModal
  }
)(FinaleView)