import THREE from '../three/threeWithExtensions'
import * as dat from 'dat.gui'
import WrapMetaData from './WrapMetaData'
import AbstractWrapMaterial from '../materials/wraps/AbstractWrapMaterial'
import IMaterial from '../materials/IMaterial'
import axios from 'axios'
import VoidPromise from '../utils/VoidPromise';

class Wrap implements IMaterial {
  meta:WrapMetaData = null
  _material:AbstractWrapMaterial = null

  _swatchPromise:Promise<any> = null
  _loadPromise:Promise<any> = null

  constructor(meta:WrapMetaData, material:AbstractWrapMaterial) {
    if (!meta) {
      throw new Error('wrap metadata is required')
    }
    this.meta = meta

    if (!material) {
      throw new Error('material is required')
    }
    this._material = material
  }

  getRenderMaterial = ():THREE.Material => {
    return this._material.getRenderMaterial()
  }

  configureVehicleColorMap = (colorMap:THREE.Texture):void => {
    this._material.configureVehicleColorMap(colorMap)
  }

  configureEnvironmentMaps = (dayEnvMap:any, nightEnvMap:any):void => {
    this._material.configureEnvironmentMaps(dayEnvMap, nightEnvMap)
  }

  setEnvironmentMapDaytimePercentage = (daytimePercentage:number):void => {
    this._material.setEnvironmentMapDaytimePercentage(daytimePercentage)
  }

  isLoading = ():boolean => {
    return this._material.isLoading()
  }

  isLoaded = ():boolean => {
    return this._material.isLoaded()
  }

  loadSwatchImages = (basePath:string):Promise<void> => {
    if (this._swatchPromise) {
      return this._swatchPromise
    }

    if (!this.meta.swatchImage) {
      return VoidPromise.resolve()
    }
    this._swatchPromise = axios.get(basePath+this.meta.swatchImage)
    return this._swatchPromise
  }

  load = (basePath:string):Promise<void> => {
    if (this._loadPromise) {
      return this._loadPromise
    }
    
    // console.log('Wrap `' + this.meta.name + '` loading...')
    const promises:Array<any> = [this._material.load(basePath)]
    
    // const now = new Date()
    this._loadPromise = VoidPromise.all(promises)
    // this._loadPromise.then( () => {
    //   const after = new Date()
    //   const duration = after.getTime() - now.getTime()
    //   console.log('Wrap `' + this.meta.name + '` finished loading in ' + duration + 'ms.')
    // })
    
    return this._loadPromise
  }

  configureGui = (gui:dat.GUI):void => {
    this._material.configureGui(gui)
  }

  unloadGui = (gui:dat.GUI):void => {
    this._material.unloadGui(gui)
  }
}

export default Wrap