// Three.JS extensions
// We utilize some demo/example/extension code that Three.js provides in our builds. However, these extensions are not listed in the THREE namespace when you do an `import * as THREE from 'three'` by default. So we needed some way to make these extensions available.
// The easiest fix ended up to just having a single entrypoint to retrieve a THREE.js context with all of these things loaded.
//
// import THREE from '../../../core/three/threeWithExtensions

import * as THREE from 'three'

// <jankiness>
// Needed because these example files aren't really Node modules, rather they insert
// constructors into the THREE namespace.
declare var require: any
if (!(window as any)['THREE']) {
  (window as any)['THREE'] = THREE // This is needed so the below requires write to the same instance of THREE that we're using

  require('./OrbitControls')
  require('./WebGL');

  (window as any)['fflate'] = require('three/examples/js/libs/fflate.min.js');
  require('three/examples/js/loaders/FBXLoader')

  // <outline> requirements https://threejs.org/examples/?q=outline#webgl_postprocessing_outline
  require('three/examples/js/shaders/CopyShader')
  require('three/examples/js/shaders/FXAAShader')
  require('three/examples/js/postprocessing/EffectComposer')
  require('three/examples/js/postprocessing/RenderPass')
  require('three/examples/js/postprocessing/OutlinePass')
  require('three/examples/js/postprocessing/ShaderPass')
}
// </outline>
// </jankiness>

export default THREE