import Dispatch from '../Dispatch'
import GetState from '../GetState'
import RestylerSearchResult from '../../store/models/RestylerSearchResult'
import restylerSelectors from '../../store/selectors/restylers'
import deselectRestyler from './deselectRestyler'

export default (restyler:RestylerSearchResult) => {
  return async (dispatch: Dispatch, getState: GetState):Promise<void> => {
    const state = getState()

    const selected = restylerSelectors.getSelectedRestyler(state)
    if (selected === restyler) { // If this restyler is already selected, deselect him.
      dispatch(deselectRestyler())
      return
    }

    dispatch({
      type: 'SELECT_RESTYLER',
      restyler: restyler
    })
  }
}