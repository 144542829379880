import * as React from 'react'
import AbstractFormInput from './AbstractFormInput'
import cx from 'classnames'

interface OwnProps {
	label?: string
	placeholder?:string
	options?: Array<string>
  defaultValue?: string
	onChange?: (value:string) => any
}

interface DispatchProps {

}

interface State {
	selected:string
}

export default class Select extends AbstractFormInput<OwnProps & DispatchProps, State> {
	constructor(props) {
		super(props)

		this.state = {
			selected: this.props.defaultValue,
			error: null
		}
	}

  render() {
		return <React.Fragment>
			{this.props.label && 
				<label className={cx(
					this.state.error && 'error'
				)}>{this.props.label}</label>
			}
			<div className={cx(
					'select-wrapper',
					this.state.error && 'error'
				)}
			>
				<select
					{...this.props}
					onChange={this._onValueChanged}
					value={this.state.selected}
				>
					{this.props.placeholder && <option>{this.props.placeholder}</option>}
					{this.props.options.map( (option, index) => {
						return <option key={option}>{option}</option>
					})}
				</select>
			</div>
			{this.state.error && <p className='error visuallyhidden'>{this.state.error}</p>}
		</React.Fragment>
	}

	getValue = () => {
		return this.state.selected
	}

	_onValueChanged = (evt) => {
		let value = evt.target.value
		if (this.props.placeholder && value === this.props.placeholder) {
			value = ''
		}

		this.setState({ selected:value }, () => {
			this.validate()
			if (this.props.onChange) {
				this.props.onChange(this.getValue())
			}
		})
	}
}