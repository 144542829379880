import axios from 'axios'
import THREE from './three/threeWithExtensions'

let _cache = {}
const AssetLibrary:any = { }

AssetLibrary.loadJson = (url:string):Promise<any> => {
  let promise = _cache[url]
  if (promise) {
    return promise
  }

  promise = new Promise( (resolve, reject) => {
    const axiosPromise = axios.get(url)
    axiosPromise.then( (response) => {
      resolve(response.data)
    })
    axiosPromise.catch( (error) => {
      reject()
    })
  })
  _cache[url] = promise
  return promise
}

AssetLibrary.loadCubeTexture = (
  xPositive:string, xNegative:string,
  yPositive:string, yNegative:string,
  zPositive:string, zNegative:string
):Promise<THREE.CubeTextureLoader> => {
  const key = [xPositive, xNegative, yPositive, yNegative, zPositive, zNegative].join('|')
  let promise = _cache[key]
  if (promise) {
    return promise
  }

  promise = new Promise((resolve, reject) => {
    const loader = new THREE.CubeTextureLoader().load([
      xPositive, xNegative,
      yPositive, yNegative,
      zPositive, zNegative
    ], (texture) => {
      resolve(loader) // TODO Should we return texture instead of the loader? Everything seems to work fine with the loader instance.
    })
  })
  _cache[key] = promise
  return promise
}

AssetLibrary.loadTexture = (url:string, wrapST:number):Promise<THREE.Texture> => {
  let promise = _cache[url]
  if (promise) {
    return promise
  }

  promise = new Promise( (resolve, reject) => {
    const loader = new THREE.TextureLoader()
    loader.load(url, (texture) => {
      if (wrapST) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.repeat.set(wrapST, wrapST)
      }
      // TODO: Error handling?
      resolve(texture)
    })
  })
  _cache[url] = promise
  return promise
}

AssetLibrary.loadFbxModel = (url:string) => {
  let promise = _cache[url]
  if (promise) {
    return promise
  }

  promise = new Promise( (resolve, reject) => {
    const loader = new THREE.FBXLoader()
    loader.load(
      url,
      (model:any) => {
        resolve(model)
      },
      undefined,
      (error) => {
        reject(error)
      }
    )
  })

  _cache[url] = promise
  return promise
}

AssetLibrary.removeFromCache = (key:string) => [
  delete _cache[key]
]

AssetLibrary.clearCache = () => {
  _cache = {}
}

export default AssetLibrary