export default (fieldName:string ,value:string):string => {
	if (value) {
		return null
	}

	if (!fieldName) {
		return 'Required'
	}
	
	return fieldName + " is required"
}