import Dispatch from './Dispatch'
import GetState from './GetState'
import View from '../store/models/View'
import updateRenderingStatus from './updateRenderingStatus'
import requestShareImageData from './requestShareImageData'
import selectors from '../store/selectors'
import setEditorPanelState from './editorPanels/setEditorPanelState'
import EditorPanelState from '../store/models/EditorPanelState'
import loadFormConfigIfNecessary from './loadFormConfigIfNecessary'
import closeAllModals from './modals/closeAllModals'
import clearRestylerSearchResults from './findRestyler/clearRestylerSearchResults'
import deselectRestyler from './findRestyler/deselectRestyler'

export default (view:View, clearHistory:boolean=false) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    if (!view) {
      console.warn('navigateTo called, but no view was supplied! Ignoring.')
      return
    }
    
    dispatch(closeAllModals())

    if (view !== View.VehicleEdit) {
      dispatch(setEditorPanelState(EditorPanelState.Closed))
    }

    switch(view) {
      case View.Finale: // Since the finale view needs the sharing download image, we need to kick this off.
        const state = getState()
        const selectedVehicle = selectors.getSelectedVehicle(state)
        if (selectedVehicle) {
          dispatch(requestShareImageData())
        }
        break;
      case View.FindRestyler:
        dispatch(clearRestylerSearchResults())
        dispatch(deselectRestyler())
        await dispatch(loadFormConfigIfNecessary()) // Make sure the form config is loaded before setting the new view!
        break;
    }

		dispatch({
			type: 'SET_VIEW',
      view: view,
      clearHistory: clearHistory
    })

    // Need to remember to update the rendering status! If the view changed, we may need to pause/unpause rendering!
    dispatch(updateRenderingStatus())
  }
}