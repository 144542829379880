import SharingUtility from "../SharingUtility";

class WrapMetaData {
	shareIdentifier:string = ''
  code:string = ''
  name:string = ''
  productFamily:string = ''
  colorGroup:string = ''
	finishGroup:string = ''
	isNew:boolean = false
	isDefault:boolean = false
	sortOrder:number = Number.MAX_VALUE
  swatchColor:string = ''
  swatchImage:string = ''

	constructor(shareIdentifier:string, code:string, name:string, productFamily:string, colorGroup:string, finishGroup:string, swatchColor:string, swatchImage:string) {
		if (!shareIdentifier) {
      throw new Error('shareIdentifier is required')
		}
		if (shareIdentifier.length !== SharingUtility.WrapIdentifierLength) {
			throw new Error('shareIdentifier must be of length ' + SharingUtility.WrapIdentifierLength)
		}
		this.shareIdentifier = shareIdentifier

		if (!code) {
      throw new Error('wrapCode is required')
    }
		this.code = code

		if (!name) {
			throw new Error('name is required')
		}
		this.name = name

		if (!productFamily) {
			throw new Error('productFamily is required')
		}
		this.productFamily = productFamily

		if (!colorGroup) {
			throw new Error('colorGroup is required')
		}
		this.colorGroup = colorGroup

		if (!finishGroup) {
			throw new Error('finishGroup is required')
		}
		this.finishGroup = finishGroup

		if (!swatchColor) {
			throw new Error('swatchColor is required')
		}
    this.swatchColor = swatchColor
    this.swatchImage = swatchImage
	}
}

export default WrapMetaData