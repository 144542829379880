import * as React from 'react'
import { connect } from 'react-redux'
import AppState from '../../store/models/AppState'
import selectors from '../../store/selectors'
import FacebookIcon from '../icons/Facebook'
import TwitterIcon from '../icons/Twitter'
import EmailIcon from '../icons/Email'
import TranslationComponent from '../TranslationComponent'
import FetchState from '../../store/models/FetchState'
import { FacebookShareButton, TwitterShareButton, EmailShareButton} from 'react-share'
import { Helmet } from "react-helmet"
const ClipboardJS = require('clipboard')
import cx from 'classnames'
import ModalComponent from './ModalComponent'

interface OwnProps {}

interface ReduxProps {
  hasMadeSelections: boolean,
  shareImageURL: string,
  sharePreviewImageURL: string,
  shareUploadState: FetchState,
  facebookShareURL: string
  facebookShareDescription: string
  twitterShareURL: string
  twitterShareDescription: string
  emailShareURL: string
  emailShareDescription: string
  translate: (key:string) => string
}

interface DispatchProps { }

interface StateProps {
  shareLinkCopied: boolean
}

class ShareModal extends React.Component<OwnProps & ReduxProps & DispatchProps, StateProps> {

  _clipboardJs:any = null

  constructor(props) {
    super(props)

    this.state = { 
      shareLinkCopied: false 
    }
  }

  componentDidMount() {
    this._clipboardJs = new ClipboardJS('.js-share-link-copy')
    this._clipboardJs.on('success', (e) => {
      // Switch out the button text to say copied for a little while!
      this.setState({ shareLinkCopied: true }, () => {
        setTimeout(() => {
          this.setState({ shareLinkCopied: false })
        }, 2000)
      })
    })
  }

  componentWillUnmount() {
    this._clipboardJs.destroy()
  }

  onLinkCopied = (e) => {
    e.preventDefault()
  }

  render() {
    const showLoadingMessage = this.props.hasMadeSelections && this.props.shareUploadState !== FetchState.Success

    const shareTitle = this.props.hasMadeSelections ? 
      this.props.translate('share.title')
      : this.props.translate('share.defaultTitle')

    return <ModalComponent closeable={true} className='modal-share modal--dark'>
      <React.Fragment>
        <div className="landmark--dubs">
          {!showLoadingMessage && (
            <img src={this.props.sharePreviewImageURL} className='modal-share-image' />
          )}
          {showLoadingMessage && (
            <div className='modal-share-loading' />
          )}
        </div>

        <div className='share-content'>
          <h1 className="h1 landmarklet share-header">
            {shareTitle}
          </h1>
          <h2 className="h2 landmark share-header">
            <TranslationComponent localizationKey='share.callToAction' />
          </h2>

          {showLoadingMessage && 
            <p>
              {(this.props.shareUploadState === FetchState.NotAsked || this.props.shareUploadState === FetchState.Loading) && 
                <TranslationComponent localizationKey='share.uploading' />
              }
              {this.props.shareUploadState === FetchState.Failure && 
                <TranslationComponent localizationKey='share.error' />
              }
            </p>
          }

          {!showLoadingMessage && (
            <React.Fragment>
              <nav className="social-nav">
                <ul>
                  <li>
                    <Helmet>
                      <meta property="og:title" content={this.props.translate('share.channels.facebook.title')} />
                      <meta property="og:image" content={this.props.shareImageURL} />
                      <meta property="og:url" content={this.props.facebookShareURL}  />
                    </Helmet>
                    <FacebookShareButton
                      url={this.props.facebookShareURL}
                      quote={this.props.facebookShareDescription}
                      hashtag={this.props.translate('share.channels.facebook.hashtag')}
                    >
                      <a href="#" data-url={this.props.facebookShareURL} className="link" title={this.props.translate('share.channels.facebook.label')}>
                        <FacebookIcon/>
                      </a>
                    </FacebookShareButton>
                  </li>
                  <li>
                    <Helmet>
                      <meta name="twitter:card" content="summary_large_image" />
                      <meta name="twitter:title" content={this.props.translate('share.channels.twitter.title')} />
                      <meta name="twitter:image" content={this.props.shareImageURL} />
                      <meta name="twitter:domain" content={this.props.twitterShareURL} />
                    </Helmet>
                    <TwitterShareButton
                      url={this.props.twitterShareURL}
                      title={this.props.twitterShareDescription}
                      hashtags={[this.props.translate('share.channels.twitter.hashtag')]}
                    >
                      <a href="#" data-url={this.props.twitterShareURL} className="link" title={this.props.translate('share.channels.twitter.label')}>
                        <TwitterIcon/>
                      </a>
                    </TwitterShareButton>
                  </li>
                  <li>
                    <EmailShareButton
                      url={this.props.emailShareURL}
                      subject={this.props.translate('share.channels.email.title')}
                      body={this.props.emailShareDescription + "\n\n" + this.props.emailShareURL}
                    >
                      <a href="#" className="link" title={this.props.translate('share.channels.email.label')}>
                        <EmailIcon/>
                      </a>
                    </EmailShareButton>
                  </li>
                </ul>
              </nav>

              <div className="island">
                <a
                  href={this.props.emailShareURL} 
                  onClick={this.onLinkCopied} 
                  className={cx("btn btn--outline--black share-copy-btn js-share-link-copy", this.state.shareLinkCopied && 'share-copy-btn--copied')}
                  data-clipboard-text={this.props.emailShareURL}
                >
                  <TranslationComponent localizationKey={this.state.shareLinkCopied ? 'share.linkCopied' : 'share.copyLink'} />
                </a>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    </ModalComponent>
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      hasMadeSelections: selectors.hasMadeAnySelectionsForVehicle(state),
      shareImageURL: selectors.getShareImageURL(state),
      sharePreviewImageURL: selectors.getSharePreviewImageURL(state),
      shareUploadState: selectors.getShareImageUploadState(state),
      facebookShareURL: selectors.getSelectionsShareURL(state, 'facebook', false),
      facebookShareDescription: selectors.getSelectionsShareDescription(state, 'facebook'),
      twitterShareURL: selectors.getSelectionsShareURL(state, 'twitter', false),
      twitterShareDescription: selectors.getSelectionsShareDescription(state, 'twitter'),
      emailShareURL: selectors.getSelectionsShareURL(state, 'email', false),
      emailShareDescription: selectors.getSelectionsShareDescription(state, 'email'),
      translate: selectors.getTranslator(state),
    }
  }
)(ShareModal)