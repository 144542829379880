import * as React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import { animated } from 'react-spring'

import AppState from '../../../store/models/AppState'
import selectors from '../../../store/selectors'
import selectTint from '../../../actionCreators/tints/selectTint'
import Tint from '../../../../../../core/tints/Tint'
import { remap } from '../../../helpers/math'
import TintsIcon from '../../icons/Tints'
import DesktopAssetSelectorPanel from './DesktopAssetSelectorPanel'

interface OwnProps {
  onBackClick: () => void
}

interface ReduxProps {
  tints: Array<Tint>
  selectedTint: Tint
  translate: (key: string) => string
}

interface DispatchProps {
  selectTint: (tint: Tint) => void
}

const noTint = new Tint(' ', 'editor.no-selection.title', 'editor.no-selection.caption', ' ', '', {})

interface State {
  hoveredTint: Tint
}

class DesktopTintSelector extends React.Component<OwnProps & ReduxProps & DispatchProps, State> {
  constructor(props) {
    super(props)

    this.state = {
      hoveredTint: props.selectedTint
    }
  }

  render() {
    const highlightedTint = this.state.hoveredTint || this.props.selectedTint || noTint
    const value = 1.0
    return (
      <DesktopAssetSelectorPanel
        title={this.props.translate('editor.tints.title')}
        titleIcon={TintsIcon}
        className='panel-group-selector--tints'
        bodyClassName='panel-group-selector--body'
        footerText={this.props.translate('editor.tints.caption')}
      >
        <React.Fragment>
          <animated.div style={{ opacity: value }}>
            <div>
              <b>{this.props.translate(highlightedTint.productFamily)}</b>
            </div>
            <div>
              {this.props.translate(highlightedTint.code)}
            </div>
          </animated.div>

          <animated.ul
            className="h-list"
            style={{
              opacity: remap(0.75, 1, 0, 1, value)
            }}
          >
            <li className={cx('no-selection', !this.props.selectedTint && 'selected')}>
              <button
                type="button"
                onMouseEnter={() => {
                  this.setState({ hoveredTint: noTint })
                }}
                onMouseLeave={() => {
                  this.setState({ hoveredTint: null })
                }}
                onClick={() => {
                  this.props.selectTint(null)
                }}
              >
                <svg height="76" width="76">
                  <defs>
                    <clipPath id="xxx">
                      <circle fill="white" cx="38" cy="38" r="36" />
                    </clipPath>
                  </defs>
                  <circle
                    stroke="#b7b7b7"
                    fill="none"
                    cx="38" cy="38"
                    r="36"
                    strokeWidth="2"
                  />
                  <line
                    x1="76" y1="76"
                    x2="0" y2="0"
                    stroke="#b7b7b7"
                    strokeWidth="8"
                    style={{
                      clipPath: 'url(#xxx)'
                    }}
                  />
                </svg>
              </button>
            </li>
            
            {this.props.tints
              .slice()
              .reverse()
              .map((tint) => {
                return (
                  <li
                    key={tint.code}
                    className={cx(this.props.selectedTint === tint && 'selected')}
                  >
                    <button
                      type="button"
                      style={{
                        background: tint.swatchColor
                      }}
                      onMouseEnter={() => {
                        this.setState({ hoveredTint: tint })
                      }}
                      onClick={() => {
                        this.props.selectTint(tint)
                      }}
                    />
                  </li>
                )
              })}
          </animated.ul>
        </React.Fragment>
      </DesktopAssetSelectorPanel>
    )
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      tints: selectors.getTints(state),
      selectedTint: selectors.getSelectedTint(state),
      translate: selectors.getTranslator(state)
    }
  },
  { selectTint }
)(DesktopTintSelector)
