import AssetLibrary from "./AssetLibrary"
import FormConfig from "./FormConfig"
import * as _ from 'underscore'

class FormConfigLoader {
	constructor() {
		// nothing
	}

	load = async (url:string):Promise<FormConfig> => {
		const data = await AssetLibrary.loadJson(url)
		if (!data) {
			return null
		}

		if (!data["restyler"]) {
			throw new Error('Loaded JSON did not have a `restyler` form element.')
		}

		return new FormConfig(data)
	}

}

export default FormConfigLoader