import * as React from 'react'
import Wrap from '../../../../../../core/wraps/Wrap'
import { useRect } from '@reach/rect'
import Pager from './DesktopPager'

function DesktopColorSelector(props: {
  basePath: string
  wraps: Array<Wrap>
  selectedWrap: Wrap | null
  temporaryWrap: Wrap | null
  onHoverWrap: (wrap: Wrap) => void
  onSelectWrap: (wrap: Wrap) => void
}) {
  let ref = React.useRef(null)
  let rect = useRect(ref)

  // Round up to the nearest even number to prevent gaps.
  let width = rect != null ? Math.ceil(rect.width) : null
  if (width != null) {
    if (width % 2 !== 0) {
      width += 1
    }
  }

  return (
    <div
      ref={ref}
      style={{ width: '100%', position: 'relative', overflow: 'hidden' }}
    >
      {rect != null ? (
        <Pager
          basePath={props.basePath}
          key={`${props.wraps.length}-wraps`}
          wraps={props.wraps}
          width={width}
          selectedWrap={props.selectedWrap}
          temporaryWrap={props.temporaryWrap}
          onHoverWrap={props.onHoverWrap}
          onSelectWrap={props.onSelectWrap}
        />
      ) : null}
    </div>
  )
}

export default DesktopColorSelector
