import Dispatch from '../Dispatch'
import GetState from '../GetState'
import selectors from '../../store/selectors'
import Wrap from '../../../../../core/wraps/Wrap'

export default (wrap:Wrap) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		// TODO: Check to make sure we're in a valid state to be able to transition to the new state?

    dispatch({
      type: 'TEMPORARILY_SELECT_ACCENT_WRAP',
      wrap: wrap
    })
  }
}