import Dispatch from '../Dispatch'
import GetState from '../GetState'
import restylerSelectors from '../../store/selectors/restylers'

export default (filter:string) => {
  return async (dispatch: Dispatch, getState: GetState):Promise<void> => {
    if (!filter) {
      console.warn('toggleSearchFilter called, but no filter was supplied! Ignoring.')
      return
    }

    const state = getState()
    const filters = restylerSelectors.getSelectedSearchFilters(state)
    const existingIndex = filters.indexOf(filter)

    if (existingIndex >= 0) {
      filters.splice(existingIndex, 1)
    }
    else {
      filters.push(filter)
    }

    dispatch({
      type: 'SET_RESTYLER_SEARCH_FILTERS',
      filters: filters
    })
  }
}