import IMaterial from '../../core/materials/IMaterial'
import UserSelections from "../UserSelections"
import MaterialLookup from '../materials/MaterialLookup'
import UserSelectionsLoader from '../UserSelectionsLoader'

// Wanted to keep the Vehicle class purely focused on data storage & retrieval. This class is focused on updating the vehicle THREE.js model with the latest user selections.
class VehicleMaterialUpdater {
	_materials:Array<IMaterial>

	constructor() {
		this._materials = []
	}

	configure = (basePath:string, defaultTint:IMaterial, defaultWrap:IMaterial, envMapDay:any, envMapNight:any, envDaytimePercentage:number, selections:UserSelections) => {
    UserSelectionsLoader.load(basePath, selections, defaultTint, defaultWrap).then( () => {
      const vehicle = selections.getVehicle()
      const tint = selections.getTint() || defaultTint
      const accentWrap = selections.getAccent() || defaultWrap

      this._materials = [] // Clear out any old materials. We'll refill the array below.
      // console.log('Updating ' + vehicle.modelURL)
      vehicle.model.traverse( (child:any) => {
        if (child.isMesh) {
          let material:IMaterial = null
          if (!material && vehicle.isWindowMeshName(child.name)) {
            material = tint
          }

          if (!material && vehicle.isAccentMeshName(child.name)) {
            material = accentWrap
          }

          if (!material) {
            const panelGroup = vehicle.getPaintablePanelGroupForMeshName(child.name)
            if (panelGroup) {
              material = selections.getWrapForPanelGroup(panelGroup) || defaultWrap
            }
          }

          if (!material) {
            material = MaterialLookup.tryToFindPartMaterialForMesh(child)
          }

          if (material) {
            if (this._materials.indexOf(material) < 0) { // No need to fill the array with copies of the same material instance.
              material.configureVehicleColorMap(vehicle.colorMapTexture)
              material.setEnvironmentMapDaytimePercentage(envDaytimePercentage)
              material.configureEnvironmentMaps(envMapDay, envMapNight)
              // material.load() // Load is unnecessary here since we just loaded above via the UserSelectionsLoader
              this._materials.push(material)
            }
            child.material = material.getRenderMaterial()
          }
        }
      })
    })
	}

	setEnvironmentMapDaytimePercentage = (value:number):void => {
		this._materials.forEach( (material) => {
      material.setEnvironmentMapDaytimePercentage(value)
    })
	}
}

export default VehicleMaterialUpdater