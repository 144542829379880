import * as React from 'react'
import { connect } from 'react-redux'

import AppState from '../../../store/models/AppState'
import selectors from '../../../store/selectors'
import Wrap from '../../../../../../core/wraps/Wrap'
import PanelGroup from '../../../../../../core/vehicles/PanelGroup'
import selectPanelGroup from '../../../actionCreators/wraps/selectPanelGroup'
import deselectPanelGroup from '../../../actionCreators/wraps/deselectPanelGroup'
import setEditorPanelState from '../../../actionCreators/editorPanels/setEditorPanelState'
import EditorPanelState from '../../../store/models/EditorPanelState'
import selectWrap from '../../../actionCreators/wraps/selectWrap'
import selectAccentWrap from '../../../actionCreators/accents/selectAccentWrap'
import selectFinishGroup from '../../../actionCreators/selectFinishGroup'
import MaterialsIcon from '../../icons/Materials'
import AccentsIcon from '../../icons/Accents'
import MobileTintSelector from './MobileTintSelector'
import MobileWrapSelector from './MobileWrapSelector'
import useSlideCanvasUpIf from './useSlideCanvasUpIf'
import MobileFilmSelector from './MobileFilmSelector'

type OwnProps = {}

type ReduxProps = {
  editorPanelState: EditorPanelState
  selectedPanelGroupWrap: Wrap
  selectedAccentWrap: Wrap
  translate: (key: string) => string
  // PanelGroupSelector
  panelGroups: Array<PanelGroup>
  selectedPanelGroup: PanelGroup
  // WrapSelector
  wraps: Array<Wrap>
  selectedWrap: Wrap
}

type DispatchProps = {
  setEditorPanelState: (state: EditorPanelState) => void
  selectPanelGroup: (panelGroup: PanelGroup) => void
  selectWrap: (wrap: Wrap) => void
  selectAccentWrap: (wrap: Wrap) => void
  selectFinishGroup: (group: string) => void
}

type Props = OwnProps & ReduxProps & DispatchProps

const tryToGetComponent = (props: Props) => {
  switch(props.editorPanelState) {
    case EditorPanelState.SelectPanelOpen:
    case EditorPanelState.SelectMaterialOpen:
      return (
        <MobileWrapSelector
          type="materials"
          title={props.translate('editor.materials.title')}
          icon={<MaterialsIcon />}
          selectedWrap={props.selectedWrap}
          onSelectWrap={props.selectWrap}
          panelGroups={props.panelGroups}
          selectPanelGroup={props.selectPanelGroup}
          selectedPanelGroup={props.selectedPanelGroup}
        />
      )
    case EditorPanelState.SelectAccentOpen:
        return (
          <MobileWrapSelector
            type="accents"
            title={props.translate('editor.accents.title')}
            icon={<AccentsIcon />}
            selectedWrap={props.selectedAccentWrap}
            onSelectWrap={props.selectAccentWrap}
            panelGroups={null}
            selectPanelGroup={null}
            selectedPanelGroup={null}
          />
        )
    case EditorPanelState.SelectTintOpen:
      return <MobileTintSelector />
    case EditorPanelState.SelectPpfOpen:
      return <MobileFilmSelector />
  }

  return null
}

function MobileOptionsSelector(props: Props) {
  const panelIsOpen = (
    props.editorPanelState === EditorPanelState.SelectPanelOpen ||
    props.editorPanelState === EditorPanelState.SelectMaterialOpen ||
    props.editorPanelState === EditorPanelState.SelectAccentOpen ||
    props.editorPanelState === EditorPanelState.SelectTintOpen ||
    props.editorPanelState === EditorPanelState.SelectPpfOpen
  )
  useSlideCanvasUpIf(panelIsOpen)

  const component = tryToGetComponent(props)
  return component
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      panelGroups: selectors.getSelectedVehiclePanelGroups(state),
      selectedPanelGroup: selectors.getSelectedPanelGroup(state),
      editorPanelState: selectors.getEditorPanelState(state),
      selectedPanelGroupWrap: selectors.getSelectedWrapForSelectedPanelGroup(
        state
      ),
      selectedAccentWrap: selectors.getSelectedAccentWrap(state),
      wraps: selectors.getWrapsInSelectedFinishGroup(state),
      selectedWrap: selectors.getSelectedWrapForSelectedPanelGroup(state),
      translate: selectors.getTranslator(state)
    }
  },
  {
    selectPanelGroup,
    deselectPanelGroup,
    setEditorPanelState,
    selectWrap,
    selectAccentWrap,
    selectFinishGroup
  }
)(MobileOptionsSelector)