import Dispatch from '../Dispatch'
import GetState from '../GetState'
import Tint from '../../../../../core/tints/Tint'
import selectors from '../../store/selectors'
import checkSelectionsForLoading from '../checkSelectionsForLoading'

export default (tint:Tint) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    // TODO: Error checking to make sure this doesn't happen when the app is in the wrong state?
    dispatch({
      type: 'SELECT_TINT',
      tint: tint
    })

    dispatch(checkSelectionsForLoading())
  }
}