import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const initializeLanguages = (languageKeys, defaultLanguageKey, basePath):Promise<any> => {
  return new Promise( (resolve, reject) => {
    i18n
      // load translation using http -> see /public/locales
      // learn more: https://github.com/i18next/i18next-http-backend (this replaces deprecated 'i18next-xhr-backend')
      .use(HttpApi)
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      .use(LanguageDetector)
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        fallbackLng: defaultLanguageKey,
        react: {
          wait: true,
        },
        debug: process.env.NODE_ENV === 'development',
        supportedLngs: languageKeys,
        cache: {
          enabled: false,
        },
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
          loadPath: basePath + 'assets/js/locales/{{lng}}/{{ns}}.json'
        },
        returnObjects: true
      },
      (err, t) => {
        if (err) {
          console.warn('Error loading translation file!', err)
          reject(err)
          return
        }

        resolve(i18n)
      })
  })
}

export default initializeLanguages