import SessionManager from '../helpers/SessionManager'
import Modal from '../store/models/Modal'
import selectors from '../store/selectors'
import Dispatch from './Dispatch'
import GetState from './GetState'
import openModal from './modals/openModal'
import selectLanguage from './selectLanguage'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const options = selectors.getLocaleSelectionOptions(getState())
    if (!options || options.length <= 1) {
      return
    }

    // use cookie to determine if user has chosen a language using the modal, but NOT to determine language (so that site URL will determine language)
    if (SessionManager.hasSavedLanguageSelection()) {
      return
    }

    dispatch(openModal(Modal.LanguageSelection))
	}
}