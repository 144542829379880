import Dispatch from '../Dispatch'
import GetState from '../GetState'
import selectors from '../../store/selectors'
import Wrap from '../../../../../core/wraps/Wrap'
import checkSelectionsForLoading from '../checkSelectionsForLoading'

export default (wrap:Wrap) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch({
      type: 'SELECT_ACCENT_WRAP',
      wrap: wrap
    })

		dispatch(checkSelectionsForLoading())
  }
}