import * as React from 'react'
import cx from 'classnames'
import Marker from './Marker'
import * as MarkerClusterer from 'marker-clusterer-plus'
import selectors from '../../store/selectors'

export type AriaLabelFn = (text: string) => string
export type Calculator = (markers: Marker[], clusterIconStylesCount: number) => ClusterIconInfo

// https://googlemaps.github.io/v3-utility-library/interfaces/_google_markerclustererplus.clustericoninfo.html
export interface ClusterIconInfo {
  index: number
  text: string
  title: string
}

// https://googlemaps.github.io/v3-utility-library/interfaces/_google_markerclustererplus.clustericonstyle.html
export interface ClusterIconStyle {
  anchorIcon?: Array<number>
  anchorText?: Array<number>
  backgroundPosition?: string
  className?: string
  fontFamily?: string
  fontStyle?: string
  fontWeight?: string
  height: number
  textColor?: string
  textDecoration?: string
  textLineHeight?: number
  textSize?: number
  url?: string
  width: number
}

// https://googlemaps.github.io/v3-utility-library/interfaces/_google_markerclustererplus.markerclustereroptions.html
export interface MarkerClusterOptions { 
  AriaLabelFn?: AriaLabelFn
  averageCenter?: boolean
  batchSize?: number
  batchSizeIE?: number
  calculator?: Calculator
  clusterClass?: string
  enableRetinaIcons?: boolean
  gridSize?: number
  ignoreHidden?: boolean
  imageExtension?: string
  imagePath?: string
  imageSizes?: Array<number>
  maxZoom?: number
  minimumClusterSize?: number
  styles?: Array<ClusterIconStyle>
  title?: string
  zIndex?: number
  zoomOnClick?: boolean
}

interface OwnProps {
  className?:string,
  apiKey?: string
  client?: string
  zoom?: number
  center?: google.maps.LatLng|google.maps.LatLngLiteral
  mapOptions?: google.maps.MapOptions // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
  clusterOptions?: MarkerClusterOptions,
  onMarkerSelected?: (marker:Marker) => any
  onCenterChanged?: (newCenter:google.maps.LatLng) => any
  onZoomChanged?: (newZoom:number) => any
  styles?: any
  innerStyles?: any
  mapChildren?: any
  children?: any
}

interface State {
}

let _loadScriptPromise:Promise<void>|null = null;

const scriptPromise = (props:OwnProps):Promise<void> => {
  if (!_loadScriptPromise) {
    _loadScriptPromise = new Promise( (resolve, reject) => {
      const script = document.createElement('script')

      let querystringSuffix = ''
      if (props.apiKey) {
        querystringSuffix += '&key=' + props.apiKey
      }
      if (props.client) {
        querystringSuffix += '&client=' + props.client
      }

      window['initializeGoogleMapsScript'] = () => {
        clearTimeout(timeout)
        resolve()
      }

      const timeout = setTimeout(() => {
        _loadScriptPromise = null // Destroy the promise so we can try again later.
        reject()
      }, 5000) // If we couldn't load by now, throw an error.

      script.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?callback=initializeGoogleMapsScript' + querystringSuffix)
      script.setAttribute('async', '')
      script.setAttribute('defer', '')
      document.body.append(script)
    })
  }

  return _loadScriptPromise
}

export default class GoogleMap extends React.Component<OwnProps, State> {
  wrapper:HTMLElement

  map:google.maps.Map
  markers:Array<google.maps.Marker> = []
  mapDiv:HTMLElement
  markerCluster:MarkerClusterer // https://googlemaps.github.io/v3-utility-library/classes/_google_markerclustererplus.markerclusterer.html

  constructor(props) {
    super(props)
  }

  setWrapper = (element) => {
    this.wrapper = element
  }

  getWidth = ():number => {
    return this.wrapper.clientWidth
  }

  getHeight = ():number => {
    return this.wrapper.clientHeight
  }

  createMap = (element) => {
    if (!element || this.map) {
      return
    }

    const options = {
      ...this.props.mapOptions
    }
    this.map = new google.maps.Map(element, options)

    google.maps.event.addListener(this.map, 'dragend', this.onCenterChanged) // If you use center_changed, you get a continuous stream of change events. Usually not what we want. Wait for the user to settle down before dispatching a change.
    google.maps.event.addListener(this.map, 'zoom_changed', this.onZoomChanged)

    if (this.props.clusterOptions) {
      this.markerCluster = new MarkerClusterer(this.map, [], this.props.clusterOptions)
    }

    this.updateMapToProps({})
  }

  onCenterChanged = () => {
    console.log('GoogleMap onCenterChanged')
    const coordinate = this.map.getCenter()
    if (this.props.onCenterChanged) {
      this.props.onCenterChanged(coordinate)
    }
  }

  onZoomChanged = () => {
    const zoom = this.map.getZoom()
    if (this.props.onZoomChanged) {
      this.props.onZoomChanged(zoom)
    }
  }

  componentDidMount = () => {
    scriptPromise(this.props).then(() => {
      this.createMap(this.mapDiv)
    })
  }

  componentDidUpdate = (prevProps:OwnProps, prevState, snapshot) => {
    this.updateMapToProps(prevProps)
  }

  updateMapToProps = (prevProps:OwnProps) => {
    if (!this.map) {
      return
    }

    if (this.props.children !== prevProps.children) {
      let newChildren = React.Children.toArray(this.props.children)
      newChildren = this.removeDuplicateCoordinates(newChildren)

      // Remove/update old children
      for(let i=this.markers.length - 1; i >= 0; i--) {
        const oldMarker:any = this.markers[i]
        
        const match:any = newChildren.find( (child:any) => {
          if (!child.props) { 
            return false 
          }
          return child.props.identifier === oldMarker.identifier
        })

        if (!match) {
          if (this.markerCluster) {
            this.markerCluster.removeMarker(oldMarker)
          }
          
          oldMarker.setMap(null)
          this.markers.splice(i, 1)
        }
        else {
          oldMarker.setOptions(match.props)

          // Remove the child from our new list so that we don't add it down below
          const index = newChildren.indexOf(match)
          newChildren.splice(index, 1)
        }
      }

      // By this point, `newChildren` will only contain the children that were not in the previous child list.
      newChildren.forEach((child:any) => {
        const marker = new google.maps.Marker({
          ...child.props, 
          map: this.map
        })
        this.markers.push(marker)

        if (this.markerCluster) {
          this.markerCluster.addMarker(marker)
        }

        if (child.props.onMarkerCreated) {
          child.props.onMarkerCreated(marker)
        }
      })
    }

    if (prevProps.center !== this.props.center) {
      if (this.props.center) {
        this.map.panTo(this.props.center)
        // this.map.setCenter(this.props.center) Opted to animate via panTo instead.
      }
    }

    if (prevProps.zoom !== this.props.zoom) {
      if (this.props.zoom) {
        this.map.setZoom(this.props.zoom)
      }
    }
  }

  render() {
    return (
      <div 
        ref={this.setWrapper}
        className={cx('map--container', this.props.className)}
        style={this.props.styles}
      >
        <div 
          className={cx('map--inner')}
          ref={(element) => { this.mapDiv = element }}
          style={this.props.innerStyles}
        >
        </div>
        {this.props.mapChildren}
      </div>
    )
  }

  removeDuplicateCoordinates = (results:Array<Marker>|any):Array<Marker> => {
    const filtered = []

    const selected = results.find(x => x.props.isSelected)
    if (selected) {
      filtered.push(selected)
    }

    for (var i=0; i<results.length; i++) {
      const marker:Marker = results[i]
      const match = filtered.find(x => x.props.position.lat === marker.props.position.lat && x.props.position.lng === marker.props.position.lng)
      if (!match) {
        filtered.push(marker)
      }
    }

    return filtered
  }
}