import * as React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import { animated } from 'react-spring'

import AppState from '../../../store/models/AppState'
import selectors from '../../../store/selectors'
import selectPaintProtectionFilm from '../../../actionCreators/films/selectPaintProtectionFilm'
import selectPaintProtectionFinish from '../../../actionCreators/films/selectPaintProtectionFilmFinish'
import removePaintProtectionFilm from '../../../actionCreators/films/removePaintProtectionFilm'
import PaintProtectionFilm from '../../../../../../core/films/PaintProtectionFilm'
import { remap } from '../../../helpers/math'
import ShieldInactive from '../../icons/shields/ShieldInactive'
import DesktopAssetSelectorPanel from './DesktopAssetSelectorPanel'
import ShieldIconFactory from '../../icons/shields/ShieldFactory'

interface OwnProps {
  onBackClick: () => void
}

interface ReduxProps {
  films: Array<PaintProtectionFilm>
  selectedFilm: PaintProtectionFilm
  finishOptions: Array<string>
  selectedFinishOption: string
  translate: (key: string) => string
}

interface DispatchProps {
  removePaintProtectionFilm: () => void
  selectPaintProtectionFilm: (film: PaintProtectionFilm) => void
  selectPaintProtectionFinish: (finish: string) => void
}

const noFilm = new PaintProtectionFilm(' ', 'editor.no-selection.title', 'editor.no-selection.caption', ' ', ' ', false)

interface State {
  hoveredFilm: PaintProtectionFilm|null
}

class DesktopFilmSelector extends React.Component<OwnProps & ReduxProps & DispatchProps, State> {
  constructor(props) {
    super(props)

    this.state = {
      hoveredFilm: props.selectedFilm
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedFilm !== this.props.selectedFilm) {
      this.setState({ hoveredFilm: this.props.selectedFilm })
    }
  }

  render() {
    const headerOptions = []
    if (this.props.finishOptions.length > 0) {
      headerOptions.push({
        title: this.props.translate('editor.films.finish-options-title'),
        values: this.props.finishOptions,
        selectedValue: this.props.selectedFinishOption,
        onHeaderOptionSelected: this.props.selectPaintProtectionFinish
      })
    }

    const highlightedFilm = this.state.hoveredFilm || this.props.selectedFilm || noFilm
    const value = 1.0
    return (
      <DesktopAssetSelectorPanel
        className="panel-group-selector--films"
        title={this.props.translate('editor.films.title')}
        titleIcon={<ShieldInactive width={35} className='panel-group-selector--films--title-icon' />}
        headerOptions={headerOptions}
        bodyClassName='panel-group-selector--body'
        footerText={this.props.translate('editor.films.caption')}
      >
        <React.Fragment>
          <animated.div style={{ opacity: value }}>
            <div>
              <b>{this.props.translate(highlightedFilm.name)}</b>
            </div>
            <div>
              {this.props.translate(highlightedFilm.finish)}
            </div>
          </animated.div>

          <animated.ul
            className="h-list"
            style={{
              opacity: remap(0.75, 1, 0, 1, value)
            }}
          >
            <li className={cx('no-selection', !this.props.selectedFilm && 'selected')}>
              <button
                type="button"
                onMouseEnter={() => {
                  this.setState({ hoveredFilm: noFilm })
                }}
                onMouseLeave={() => {
                  this.setState({ hoveredFilm: null })
                }}
                onClick={this.props.removePaintProtectionFilm}
              >
                <svg height="76" width="76">
                  <defs>
                    <clipPath id="xxx">
                      <circle fill="white" cx="38" cy="38" r="36" />
                    </clipPath>
                  </defs>
                  <circle
                    stroke="#b7b7b7"
                    fill="none"
                    cx="38" cy="38"
                    r="36"
                    strokeWidth="2"
                  />
                  <line
                    x1="76" y1="76"
                    x2="0" y2="0"
                    stroke="#b7b7b7"
                    strokeWidth="8"
                    style={{
                      clipPath: 'url(#xxx)'
                    }}
                  />
                </svg>
              </button>
            </li>
            
            {this.props.films.map((film) => {
              return (
                <li
                  key={film.code}
                  className={cx(this.props.selectedFilm === film && 'selected')}
                >
                  <button
                    type="button"
                    onMouseEnter={() => {
                      this.setState({ hoveredFilm: film })
                    }}
                    onMouseLeave={() => {
                      this.setState({ hoveredFilm: null })
                    }}
                    onClick={() => {
                      this.props.selectPaintProtectionFilm(film)
                    }}
                  >
                    {ShieldIconFactory.tryToGetIcon(film.icon, { width:40 })}
                  </button>
                </li>
              )
            })}
          </animated.ul>
        </React.Fragment>
      </DesktopAssetSelectorPanel>
    )
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      films: selectors.getSelectablePaintProtectionFilms(state),
      selectedFilm: selectors.getSelectedPaintProtectionFilm(state),
      finishOptions: selectors.getPaintProtectionFinishOptions(state),
      selectedFinishOption: selectors.getSelectedPaintProtectionFinish(state),
      translate: selectors.getTranslator(state)
    }
  },
  { 
    selectPaintProtectionFilm, 
    selectPaintProtectionFinish,
    removePaintProtectionFilm
  }
)(DesktopFilmSelector)
