import Dispatch from '../Dispatch'
import GetState from '../GetState'
import selectors from '../../store/selectors'
import selectPaintProtectionFilm from './selectPaintProtectionFilm'

export default (finish:string) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState()

    const alreadySelectedFinish = selectors.getSelectedPaintProtectionFinish(state)
    if (alreadySelectedFinish === finish) {
      return // If the thing is already selected, no need to do anything.
    }

    const finishOptions = selectors.getPaintProtectionFinishOptions(state)
    const index = finishOptions.indexOf(finish)
    if (index < 0) {
      console.warn('selectPaintProtectionFilmFinish called, but finish `' + finish + '` was not an available option! Ignoring.')
      return
    }

    dispatch({
      type: 'SELECT_PAINT_PROTECTION_FINISH',
      finish: finish
    })

    const existingFilm = selectors.getSelectedPaintProtectionFilm(state)
    if (existingFilm && existingFilm.finish !== finish) {
      const existingNameTranslated = selectors.translate(state, existingFilm.name)

      // If the user had previously selected a PPF, we need to find the corresponding one for the new finish and automatically select that.
      const films = selectors.getAllPaintProtectionFilms(state)
      const match = films.find( (film) => {
        const translatedName = selectors.translate(state, film.name)
        const translatedFinish = selectors.translate(state, film.finish)
        return translatedFinish === finish && translatedName === existingNameTranslated
      })

      if (!match) {
        console.warn('selectPaintProtectionFilmFinish - User switched to `' + finish + '`, but no corresponding `' + existingFilm.name + '` PPF was found!')
        // We will let this continue, and just nuke the users selection. Sorry, mom.
      }

      dispatch(selectPaintProtectionFilm(match))
    }
  }
}