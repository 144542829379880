import Vehicle from "./vehicles/Vehicle"
import Tint from "./tints/Tint"
import Wrap from "./wraps/Wrap"
import PanelGroup from "./vehicles/PanelGroup"
import SceneMode from "./SceneMode"
import PaintProtectionFilm from "./films/PaintProtectionFilm"

class UserSelections {
	_vehicle:Vehicle|null
	_tint:Tint|null
	_accent:Wrap|null

	_filmFinish:string|null // I know it seems counter-intuitive to store the selected finish, when the same information is also present in the PaintProtectionFilm instance. BUT, it is possible for users to select a finish type WITHOUT selecting a PPF. So we need this here.
	_film:PaintProtectionFilm|null
	
	_panelGroupWraps: {
		[panelGroup:string]: Wrap
  }
	_sceneMode:SceneMode
	_loading:boolean

	constructor() {
		this._vehicle = null
		this._tint = null
		this._accent = null
		this._filmFinish = null
		this._film = null
    this._panelGroupWraps = {}
		this._sceneMode = SceneMode.Nighttime
  }

  hasEnoughToRender = ():boolean => {
    const vehicle = this.getVehicle()
    if (!vehicle || !vehicle.isLoaded()) {
      return false
    }
		
		const film = this.getPaintProtectionFilm()
		if (film && !film.isLoaded()) {
			return false
		}

    return true
  }

	getVehicle = ():Vehicle => {
		return this._vehicle
	}

	getTint = ():Tint => {
		return this._tint
	}

	getAccent = ():Wrap => {
		return this._accent
	}

	getPaintProtectionFilmFinish = ():string|null => {
		return this._filmFinish
	}

	getPaintProtectionFilm = ():PaintProtectionFilm|null => {
		return this._film
	}

	getPanelGroupWraps = ():Array<Wrap> => {
		const wraps = []

		PanelGroup.Keys.forEach( (groupKey) => {
			const wrap = this._panelGroupWraps[groupKey]
			if (wrap) {
				wraps.push(wrap)
			}
		})

		return wraps
	}

	getWrapForPanelGroupKey = (panelGroupKey:string):Wrap => {
		if (!panelGroupKey) {
      return this.getEntireVehicleWrap()
		}

		const wrap = this._panelGroupWraps[panelGroupKey]
		if (wrap) {
			return wrap
		}

		return null
	}

	getWrapForPanelGroup = (panelGroup:PanelGroup):Wrap => {
    if (!panelGroup) {
      return this.getEntireVehicleWrap()
    }

		return this.getWrapForPanelGroupKey(panelGroup.key)
	}

  getEntireVehicleWrap = ():Wrap => {
    // Searches the entire selections options. If anything is null, we return null.
    // If any wraps are different, we return null. Otherwise we return the matching
    // wrap.
    let allMatchingWrap = null
    for (var key in this._panelGroupWraps) {
			if (this._panelGroupWraps.hasOwnProperty(key)) {
        let wrap = this._panelGroupWraps[key]
        if (!wrap) {
          return null
        }

        if (!allMatchingWrap) {
          allMatchingWrap = wrap
        }
        else if (allMatchingWrap !== wrap) {
          return null
        }
      }
    }

    // If we survived the loop, then all of the wraps were the same so we're good!
    return allMatchingWrap
  }

  getSceneMode = ():SceneMode => {
    return this._sceneMode
  }

	setVehicle = (vehicle:Vehicle):void => {
		if (this._vehicle === vehicle) {
			//console.warn('UserSelections setVehicle called, but the supplied vehicle was the same. Ignoring.')
			return
		}

		this._vehicle = vehicle

		const oldPanelGroupWraps = Object.assign({}, this._panelGroupWraps)
		this._panelGroupWraps = {}

		if (vehicle) {
			// If we're switching vehicles, copy over the selected panels
			PanelGroup.Keys.forEach( (groupKey) => {
				const panelGroup = vehicle.getPanelGroup(groupKey)
				this._panelGroupWraps[panelGroup.key] = null
				let useWrap = oldPanelGroupWraps[panelGroup.key]
				if (useWrap) {
					this.setPanelGroupWrap(panelGroup, useWrap)
				}
			})
		}
	}

	setTint = (tint:Tint):void => {
		this._tint = tint
	}

	setAccent = (wrap:Wrap):void => {
		this._accent = wrap
	}

	setPaintProtectionFilmFinish = (finish:string):void => {
		this._filmFinish = finish
	}

	setPaintProtectionFilm = (film:PaintProtectionFilm):void => {
		this._film = film
	}

	setPanelGroupWrap = (panelGroup:PanelGroup, wrap:Wrap):void => {
		if (!panelGroup) {
			this.setAllPanelGroupsTo(wrap)
			return
		}

		this._panelGroupWraps[panelGroup.key] = wrap
	}

	setAllPanelGroupsTo = (wrap:Wrap):void => {
		for (var key in this._panelGroupWraps) {
			if (this._panelGroupWraps.hasOwnProperty(key)) {
				this._panelGroupWraps[key] = wrap
			}
		}
  }

  setSceneMode = (mode:SceneMode):void => {
    this._sceneMode = mode
  }

  hasMadeAnySelectionsForVehicle = ():boolean => {
    if (this._tint) {
      return true
    }

    if (this._accent) {
      return true
		}
		
		if (this._film) { // Yep, we made the call that even though PPFs don't affect the final sharing render that we want to render it anyway.
			return true
		}

    for (var key in this._panelGroupWraps) {
			if (this._panelGroupWraps.hasOwnProperty(key)) {
        const wrap = this._panelGroupWraps[key]
        if (wrap) {
          return true
        }
			}
    }

    return false
	}

	clone = () => {
		const copy = new UserSelections()
		copy._vehicle = this._vehicle
		copy._tint = this._tint
		copy._accent = this._accent
		copy._filmFinish = this._filmFinish
		copy._film = this._film
    copy._sceneMode = this._sceneMode

		for (const key in this._panelGroupWraps) {
			if (this._panelGroupWraps.hasOwnProperty(key)) {
				copy._panelGroupWraps[key] = this._panelGroupWraps[key]
			}
		}

		return copy
	}

	equals = (other:UserSelections):boolean => {
		if (this._vehicle !== other._vehicle) {
			return false
		}

		if (this._tint !== other._tint) {
			return false
		}

		if (this._accent !== other._accent) {
			return false
		}
		
		if (this._filmFinish !== other._filmFinish) {
			return false
		}

		if (this._film !== other._film) {
			return false
		}

    if (this._sceneMode !== other._sceneMode) {
      return false
    }

		for (const key in this._panelGroupWraps) {
			if (this._panelGroupWraps.hasOwnProperty(key)) {
				const wrap = this._panelGroupWraps[key]
				const otherWrap = other._panelGroupWraps[key]
				if (wrap !== otherWrap) {
					return false
				}
			}
    }

		return true
	}

	isLoading = ():boolean => {
		if (this._vehicle && this._vehicle.isLoading()) {
			return true
		}

		if (this._accent && this._accent.isLoading()) {
			return true
		}

		if (this._tint && this._tint.isLoading()) {
			return true
		}

		if (this._film && this._film.isLoading()) {
			return true
		}

		const wraps = this.getPanelGroupWraps()
		for(let i=0; i<wraps.length; i++) {
			const wrap = wraps[i]
			if (wrap.isLoading()) {
				return true
			}
		}

		return false
	}
}

export default UserSelections