import Tint from './Tint'
import ShareURLGenerator from '../SharingUtility'


class TintFactory {
	static fromConfig = (json:any):Tint => {
		if (!json) {
			throw new Error('Must supply a JSON configuration node!')
    }

    if (json.disabled) {
      return null
    }

    if (!json.shareIdentifier) {
      console.warn('TintFactory - tint config missing `shareIdentifier` property: `' + JSON.stringify(json) + '`.')
      return null
    }
    if (json.shareIdentifier.length !== ShareURLGenerator.TintIdentifierLength) {
      console.warn('TintFactory - `shareIdentifier` property was not of length ' + ShareURLGenerator.TintIdentifierLength + ': `' + JSON.stringify(json) + '`.')
      return null
    }

    if (!json.name) {
      console.warn('TintFactory - tint config missing `name` property: `' + JSON.stringify(json) + '`.')
      return null
    }

    if (!json.code) {
      console.warn('TintFactory - tint config missing `code` property: `' + JSON.stringify(json) + '`.')
      return null
    }

    if (!json.productFamily) {
      console.warn('TintFactory - tint config missing `productFamily` property: `' + JSON.stringify(json) + '`.')
      return null
    }

    if (typeof(json.uniforms) === 'undefined') {
      console.warn('TintFactory - tint config missing `uniforms` property: `' + JSON.stringify(json) + '`.')
      return null
    }

    let tint = null
    try {
      tint = new Tint(json.shareIdentifier ,json.name, json.code, json.productFamily, json.swatchColor, json.uniforms)
    }
    catch(e) {
      console.warn('TintFactory - Unable to create Tint oject from json: `' + JSON.stringify(json) + '`.')
      return null
    }

    tint.new = !!json.new

    if (json.sortOrder) {
			const sortOrder = parseInt(json.sortOrder)
			if (!isNaN(sortOrder)) {
				tint.sortOrder = sortOrder
			}
		}

    return tint
	}
}

export default TintFactory