
import * as React from 'react'
import { connect } from 'react-redux'
import { animated } from 'react-spring'
import cx from 'classnames'

import AppState from '../../../store/models/AppState'
import Wrap from '../../../../../../core/wraps/Wrap'
import PanelGroup from '../../../../../../core/vehicles/PanelGroup'
import CancelIcon from '../../icons/Cancel'
import MobilePanelHeader from './MobilePanelHeader'
import MobileSelectedItemInfo from './MobileSelectedItemInfo'
import MobileFilterMenu from './MobileFilterMenu'
import useSlideUpPanelTransform from './useSlideUpPanelTransform'
import selectors from '../../../store/selectors'
import selectFinishGroup from '../../../actionCreators/selectFinishGroup'
import MobileAssetSelectorPanel from './MobileAssetSelectorPanel'

interface OwnProps {
  title: string
  type: string
  icon: any
  selectedWrap: Wrap | null
  onSelectWrap: (wrap: Wrap) => void
  panelGroups: Array<PanelGroup> | null
  selectPanelGroup: (group: PanelGroup) => void | null
  selectedPanelGroup: PanelGroup | null
}

interface ReduxProps {
  wraps: Array<Wrap>
  finishGroups: Array<string>
  selectedFinishGroup: string | null
  translate: (key: string) => string
}

interface DispatchProps {
  selectFinishGroup: (group: string) => void
}

type Props = OwnProps & ReduxProps & DispatchProps

function MobileWrapSelector(props: Props) {
  const headerOptions = []

  if (props.type === 'materials') {
    headerOptions.push({
      title: props.translate('editor.materials.entire-vehicle'),
      values: props.panelGroups.map((group) => {
        return props.translate(group.localizationKey)
      }),
      selectedValue: props.selectedPanelGroup ? props.translate(props.selectedPanelGroup.localizationKey) : '',
      onHeaderOptionSelected: (option:string) => {
        const match = props.panelGroups.find( (panelGroup) => {
          return props.translate(panelGroup.localizationKey) === option
        })
        props.selectPanelGroup(match)
      }
    })
  }
  
  headerOptions.push(
    {
      title: props.translate('editor.materials.all'),
      values: props.finishGroups.map((finish) => {
        return finish
      }),
      selectedValue: props.selectedFinishGroup,
      onHeaderOptionSelected: (finish:string) => {
        props.selectFinishGroup(finish)
      }
    }
  )

  return (
    <MobileAssetSelectorPanel
      title={props.title}
      icon={props.icon}
      iconClassName={props.type}
      headerOptions={headerOptions}
    >
      <MobileSelectedItemInfo
        name={
          props.selectedWrap != null
            ? props.translate(props.selectedWrap.meta.name)
            : props.translate('editor.no-selection.title')
        }
        code={
          props.selectedWrap != null
            ? props.selectedWrap.meta.code
            : props.translate('editor.no-selection.captionMobile')
        }
      />

      <div 
        className="mobile-swatch-grid">
        <button
          className={cx(
            'mobile-swatch',
            props.selectedWrap == null && 'selected',
          )}
          style={{ color: '#aaa' }}
          onClick={(event) => {
            event.preventDefault()
            props.onSelectWrap(null)
          }}
        >
          <CancelIcon />
        </button>
        {props.wraps.map( (wrap) => {
          return (
            <button
              key={wrap.meta.code}
              onClick={(event) => {
                event.preventDefault()
                props.onSelectWrap(wrap)
              }}
              className={cx({
                'mobile-swatch': true,
                selected: props.selectedWrap === wrap
              })}
              style={{
                background: wrap.meta.swatchColor
              }}
            />
          )
        })}
      </div>
    </MobileAssetSelectorPanel>
  )
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      wraps: selectors.getWrapsInSelectedFinishGroup(state),
      finishGroups: selectors.getAllWrapFinishGroups(state),
      selectedFinishGroup: selectors.getSelectedFinishGroup(state),
      translate: selectors.getTranslator(state),
    }
  },
  {
    selectFinishGroup
  }
)(MobileWrapSelector)