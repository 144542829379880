import Dispatch from './Dispatch'
import GetState from './GetState'
import SceneMode from '../../../../core/SceneMode'

export default (mode:SceneMode) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		// TODO: Error checking to make sure this doesn't happen when the app is in the wrong state?
    dispatch({
      type: 'SELECT_SCENE_MODE',
      sceneMode: mode
    })
  }
}