import * as React from 'react'
import { connect } from 'react-redux'
import { animated, useSpring } from 'react-spring'

import AppState from '../../store/models/AppState'
import selectors from '../../store/selectors'
import SceneMode from '../../../../../core/SceneMode'
import selectSceneMode from '../../actionCreators/selectSceneMode'

// SVGs
import DayIcon from '../icons/Day'
import NightIcon from '../icons/Night'

interface ReduxProps {
  selectedMode: SceneMode
}
interface DispatchProps {
  selectSceneMode: (mode: SceneMode) => void
}

type Props = ReduxProps & DispatchProps

let day = (
  <g transform="translate(60.5,7.5)">
    <DayIcon />
  </g>
)

let night = (
  <g transform="translate(10,8.5)">
    <NightIcon />
  </g>
)

function DayNightToggle({
  selectedMode,
  selectSceneMode
}): React.ReactElement<Props> {
  // hovered is a bitmask. flags: 1 == day, 2 == night.
  let [hovered, setHovered] = React.useState(0)

  let spring = useSpring({
    x: selectedMode === SceneMode.Nighttime ? 21 : 74
  })

  let highlightColor = selectedMode === SceneMode.Nighttime ? 'white' : 'black'
  let highlight = (
    <animated.circle cx={spring.x} cy="21" r="20" stroke="none" fill="white" />
  )

  let dayHovered = !!(hovered & 1)
  let nightHovered = !!(hovered & 2)

  let dayStroke =
    selectedMode === SceneMode.Daytime || dayHovered
      ? 'black'
      : 'rgba(255, 255, 255, 0.5)'

  let nightStroke =
    selectedMode === SceneMode.Nighttime || nightHovered
      ? 'rgba(255, 255, 255, 0.5)'
      : 'black'

  return (
    <div className="day-night-toggle">
      <button
        disabled={selectedMode === SceneMode.Nighttime}
        className="day-night-toggle__button"
        onMouseEnter={() => setHovered((mask) => mask | 2)}
        onMouseLeave={() => setHovered((mask) => mask ^ 2)}
        onClick={() => {
          setHovered(0)
          selectSceneMode(SceneMode.Nighttime)
        }}
        style={{ left: 0 }}
      />
      <button
        disabled={selectedMode === SceneMode.Daytime}
        className="day-night-toggle__button"
        onMouseEnter={() => setHovered((mask) => mask | 1)}
        onMouseLeave={() => setHovered((mask) => mask ^ 1)}
        onClick={() => {
          setHovered(0)
          selectSceneMode(SceneMode.Daytime)
        }}
        style={{ right: 0 }}
      />
      <svg width="95" height="42">
        <defs>
          <clipPath id="dayNightHighlight">{highlight}</clipPath>
        </defs>
        {highlight}

        {/* night circle */}
        <circle id="night-outline" cx="21" cy="21" r="20" stroke="black" fill="rgba(255, 255, 255, 0.4)" />
        <g id="night-icon" style={{ color: "black" }}>{night}</g>
        <g id="epsilon" style={{ color: 'black', clipPath: 'url(#dayNightHighlight)' }}>{night}</g>

        {/* day circle */}
        <circle id="day-outline" cx="74" cy="21" r="20" stroke="black" fill="rgba(255, 255, 255, 0.4)" />
        <g id="day-icon" style={{ color: "black" }}>{day}</g>
        <g id="zug" style={{ color: 'black', clipPath: 'url(#dayNightHighlight)' }}>
          {day}
        </g>
      </svg>
    </div>
  )
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      selectedMode: selectors.getSelectedSceneMode(state)
    }
  },
  {
    selectSceneMode
  }
)(DayNightToggle)
