import Dispatch from './Dispatch'
import GetState from './GetState'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    // TODO: Error checking to make sure this doesn't happen when the app is in the wrong state?
    dispatch({
			type: 'SELECT_RESTYLER',
			restyler: null
    })
  }
}