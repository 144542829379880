import Dispatch from './Dispatch'
import GetState from './GetState'
import selectors from '../store/selectors'
import Wrap from '../../../../core/wraps/Wrap'
import PanelGroup from '../../../../core/vehicles/PanelGroup'
import checkSelectionsForLoading from './checkSelectionsForLoading'

export default (panelGroup:PanelGroup, wrap:Wrap) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    if (!panelGroup) {
      console.warn('selectWrapForPanelGroup called, but no panel group specified! Ignoring.')
      return
    }

		dispatch({
			type: 'SELECT_WRAP',
			panelGroup: panelGroup,
			wrap: wrap
		})

		dispatch(checkSelectionsForLoading())
  }
}