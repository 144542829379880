import Dispatch from '../Dispatch'
import GetState from '../GetState'
import PanelGroup from '../../../../../core/vehicles/PanelGroup'
import EditorPanelState from '../../store/models/EditorPanelState'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch({
      type: 'SELECT_PANEL_GROUP',
    	panelGroup: null
    })

    dispatch({
      type: 'SET_EDITOR_PANEL_STATE',
      panelState: EditorPanelState.Closed
    })
  }
}