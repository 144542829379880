import * as React from 'react'
import AbstractFormInput from './AbstractFormInput'
import cx from 'classnames'
import Required from './validators/Required'

interface OwnProps {
	className?:string
	label?: string
	type?: string
	placeholder?: string
	value?: string
	onChange?: (newValue:string) => any
	onFocus?: () => void
	onBlur?: () => void
}

interface DispatchProps {

}

interface State {
  value:string
}

export default class TextInput extends AbstractFormInput<OwnProps & DispatchProps, State> {
  _input:any

  constructor(props) {
		super(props)

		this.state = {
			value: '',
      error: null
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.value !== this.props.value) {
			if (prevState.value !== this.props.value) {
				this.setState({ value: this.props.value })
			}
		}
	}

  render() {
		return <React.Fragment>
			{this.props.label &&
				<label className={cx(
					this.state.error && 'error'
				)}>{this.props.label}</label>
			}
			<input
        {...this.props}
        ref={(element) => { this._input = element }}
				type={this.props.type ? this.props.type : "text"}
				value={this.state.value}
				onChange={this._onValueChanged}
				onFocus={this.props.onFocus}
				onBlur={this._onBlur}
				className={cx(
					this.props.className,
					this.state.error && 'error'
				)}
			/>
			{this.state.error && <p className='error visuallyhidden'>{this.state.error}</p>}
		</React.Fragment>
	}

	getValue = () => {
		return this.state.value
  }

  focus = () => {
    this._input.focus()
	}
	
	clear = () => {
		this._setNewValue('')
	}

  _onBlur = (evt) => {
		this.validate()
		
		if (this.props.onBlur) {
			this.props.onBlur()
		}
  }

	_onValueChanged = (evt) => {
		const newValue = evt.target.value
		this._setNewValue(newValue)
	}

	_setNewValue = (text:string) => {
		this.setState({ value: text }, () => {
			if (this.props.onChange) {
				this.props.onChange(text)
			}
		})
	}
}