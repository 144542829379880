import * as React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import AppState from '../../store/models/AppState'
import selectors from '../../store/selectors'
import restylerSelectors from '../../store/selectors/restylers'
import TextInput from '../forms/TextInput'
import searchForRestylers from '../../actionCreators/findRestyler/searchForRestylers'
import selectRestyler from '../../actionCreators/findRestyler/selectRestyler'
import contactRestyler from '../../actionCreators/findRestyler/contactRestyler'
import TranslationComponent from '../TranslationComponent'

import EnvironmentConfig from '../../../../../core/EnvironmentConfig'
import RestylerMap from '../map/RestylerMap'
import RestylerSearchResult from '../../store/models/RestylerSearchResult'
import RestylerCard from '../map/RestylerCard'
import navigateBack from '../../actionCreators/navigateBack'
import clearRestylerSearchResults from '../../actionCreators/findRestyler/clearRestylerSearchResults'
import BaseView from './BaseView'
import BackButton from '../BackButton'
import Size from '../../../../../core/utils/Size'
import SlidePanel from '../SlidePanel'
import Filters from '../map/Filters'

interface OwnProps { }

interface ReduxProps {
  isSearching: boolean
  searchedValue: string
  searchedValueWasAutomated: boolean,
  searchResults: Array<RestylerSearchResult>|null
  hasAnySearchResults: boolean
  countryCodeDenied: boolean
  selectedRestyler: RestylerSearchResult
  environmentConfig: EnvironmentConfig
  translate: (key:string) => string
  isDesktopViewport: boolean
  viewportSize: Size
  isSmallViewport: boolean
}

interface DispatchProps {
  navigateBack: () => any
  searchForRestylers: (value:string) => any
  selectRestyler: (restyler:RestylerSearchResult) => any
  contactRestyler: (restyler:RestylerSearchResult) => any
  clearRestylerSearchResults: () => any
}

type Props = OwnProps & ReduxProps & DispatchProps

interface State {
  searchValue: string,
  slidePanelTop: number
}

enum ButtonState {
  None,
  Search,
  Clear
}

class FindRestyler extends React.Component<Props, State> {
  _map = null
  _slidePanel = null
  _input = null
  _resultsWrapper = null

  constructor(props:Props) {
    super(props)
    this.state = {
      searchValue: props.searchedValue,
      slidePanelTop: 0
    }
  }

  setSlidePanelRef = (element) => {
    this._slidePanel = element
  }

  setMapRef = (element) => {
    this._map = element
  }

  componentDidMount() {
    // this._input.focus() // Do not focus the input after loading. This caused some super-weird bugs on Android mobile.
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.searchedValue !== this.props.searchedValue) {
      this.setState({ searchValue: this.props.searchedValue })
    }

    if (this.props.isDesktopViewport) {
      this.componentDidUpdateDesktop(prevProps, prevState, snapshot)
    }
    else {
      this.componentDidUpdateMobile(prevProps, prevState, snapshot)
    }
  }

  componentDidUpdateDesktop = (prevProps, prevState, snapshot) => {
    if (this.props.selectedRestyler && this.props.selectedRestyler !== prevProps.selectedRestyler) {
      const cardSelector = '.' + this.getCardClassNameForRestyler(this.props.selectedRestyler)
      const element = document.querySelector(cardSelector)
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  componentDidUpdateMobile = (prevProps, prevState, snapshot) => {
    const TIMEOUT_DURATION = 300 // Must be a larger duration than the animation time that the map SCSS transition.

    if (this.props.isSearching && prevProps.isSearching !== this.props.isSearching) {
      setTimeout(() => { // SetTimeout to give the browser a few frames to render things.
        const header = document.querySelector('.js-find-restyler-header')
        this._slidePanel.openToHeight(header.getBoundingClientRect().height + 20)
      }, TIMEOUT_DURATION)
    }
    else if (this.props.searchResults && prevProps.searchResults !== this.props.searchResults) {
      setTimeout(() => {
            // Open to the first card in the list. Extra padding to allow the headline to show.
          //this._slidePanel.openToSelector('.find-installer-results--card', 100)
          const header = document.querySelector('.js-find-restyler-header')
          const firstCard = document.querySelector('.find-installer-results--card')
          if (header && firstCard) {
            const height = header.getBoundingClientRect().height + firstCard.getBoundingClientRect().height
            this._slidePanel.openToHeight(height)
          }
      }, TIMEOUT_DURATION)
    }
    else if (this.props.selectedRestyler && this.props.selectedRestyler !== prevProps.selectedRestyler) {
      setTimeout(() => {
        const cardSelector = '.' + this.getCardClassNameForRestyler(this.props.selectedRestyler)
        const element = document.querySelector(cardSelector)
        this._slidePanel.openToHeight(element.getBoundingClientRect().height, () => {
          element.scrollIntoView({ behavior: 'smooth' })
        })
      }, TIMEOUT_DURATION)
    }
  }

  determineTitle = ():string|null => {
    if (this.props.isSearching) {
      return this.props.translate('search.searching')
    }

    if (this.props.searchResults) {
      if (this.props.searchResults.length <= 0) {
        return this.props.translate('search.no-results')
      }

      return this.props.translate('search.title')
    }

    return null
  }

  getMapHeight = ():number => {
    if (!this._map) {
      return 0
    }

    const height = this._map.getHeight()
    return height
  }

  getButtonState = ():ButtonState => {
    if (this.props.searchResults && this.state.searchValue === this.props.searchedValue) {
      return ButtonState.Clear
    }

    if (this.state.searchValue) {
      return ButtonState.Search
    }

    return ButtonState.None
  }

  inputIsAutomated = () => {
    let inputIsAutomated = false
    if (this.props.searchedValueWasAutomated && this.props.searchedValue === this.state.searchValue) {
      inputIsAutomated = true
    }

    return inputIsAutomated
  }

  renderHeader = () => {
    const buttonState = this.getButtonState()
    const inputIsAutomated = this.inputIsAutomated()

    return (
      <div className="find-restyler--header">
        <BackButton
          className='btn--back--black'
          width={45}
          onClick={() => {
            this.props.navigateBack()
          }}
        />

        <form onSubmit={this._onSearchSelected}>
          <TextInput
            className={cx(
              'find-restyler--header--input',
              inputIsAutomated && 'find-restyler--header--input--automated'
            )}
            ref={(element) => { this._input = element }}
            placeholder={this.props.translate('search.search-placeholder')}
            value={this.props.searchedValue}
            onChange={this._onSearchInputChange}
            onFocus={inputIsAutomated ? this._onSearchInputFocus : undefined}
          />

          {buttonState === ButtonState.Search && (
            <button className='find-restyler--header--search'>
              <TranslationComponent localizationKey='search.submit' />
            </button>
          )}
          {buttonState === ButtonState.Clear && (
            <button
              className='find-restyler--header--clearInput'
              onClick={this._onClearSearchSelected}
            ></button>
          )}
        </form>
      </div>
    )
  }

  renderMap = () => {
    return (
      <RestylerMap
        ref={this.setMapRef}
        className='find-restyler--map--wrapper'
        mapClassName='find-restyler--map'
        locationClassName='find-restyler--location'
        mapStyles={{
          transform: 'translateY(' + this.state.slidePanelTop + 'px)'
        }}
      />
    )
  }

  renderResults = (searchResults:Array<RestylerSearchResult>|null) => {
    const panelTitle = this.determineTitle()
    if (!panelTitle) {
      return null
    }

    return (
      <React.Fragment>
        <div className="js-find-restyler-header find-restyler--results--padding">
          <header className="find-restyler--results--header islet">
            {this.props.countryCodeDenied && (
              <p dangerouslySetInnerHTML={{ __html: this.props.translate('search.no-results-country-denied') }} />
            )}

            {!this.props.countryCodeDenied && (
              <React.Fragment>
                <h1 className="h1 marginless text--heavy">{panelTitle}</h1>
                {searchResults && searchResults.length >= 1 && !this.props.countryCodeDenied && (
                  <p className='marginless'>
                    <TranslationComponent localizationKey="search.count" replace={{
                      count: searchResults.length,
                      pluralize: searchResults.length !== 1 ? 's' : ''
                    }} />
                  </p>
                )}
              </React.Fragment>
            )}
          </header>
        </div>

        {searchResults && (
          <ul
            className={cx(
              'find-restyler--results--list',
              "marginless"
            )}
          >
            {searchResults.map( (restyler, index) => {
              return (
                <RestylerCard
                  className={cx('find-installer-results--card', this.getCardClassNameForRestyler(restyler))}
                  innerClassName='find-restyler--results--padding'
                  key={restyler.key}
                  restyler={restyler}
                  resultNumber={index + 1}
                  onSelected={this._onRestylerCardSelected}
                />
              )
            })}
          </ul>
        )}
      </React.Fragment>
    )
  }

  getCardClassNameForRestyler = (restyler:RestylerSearchResult) => {
    return 'js-find-installer-results--card-' + restyler.key
  }

  renderFilters = () => {
    if (!this.props.hasAnySearchResults) {
      return null
    }

    return (
      <Filters
        filterClassName='find-restyler--filter--item'
        selectedClassName='find-restyler--filter--item--selected'
      />
    )
  }

  renderMobile = () => {
    return (
      <React.Fragment>
        {this.renderHeader()}
        {this.renderMap()}
        {this.renderFilters()}

        <SlidePanel
          ref={this.setSlidePanelRef}
          className='find-restyler--results'
          fullyOpenClassName='find-restyler--results--fullyOpen'
          handleClassName='find-restyler--results--handle hidden-desk-and-up'
          limit={this.getMapHeight}
          slowness={5}
          onPositionChanged={(top:number) => {
            // console.log('SlidePanel onPositionChanged', top)
            if (top < -100) {
              top = -100
            }
            top = Math.round(top)

            if (this.state.slidePanelTop !== top) {
              this.setState({ slidePanelTop: top })
            }
          }}
        >
          {this.renderResults(this.props.searchResults)}
        </SlidePanel>
      </React.Fragment>
    )
  }

  renderDesktop = () => {
    return (
      <div className='find-restyler--desktop-layout'>
        <div className='find-restyler--desktop--left'>
          {this.renderHeader()}
          {this.props.hasAnySearchResults && (
            <div className='find-restyler--results--padding'>
              {this.renderFilters()}
            </div>
          )}
          {this.renderResults(this.props.searchResults)}
        </div>
        <div className='find-restyler--desktop--right'>
          {this.renderMap()}
        </div>
      </div>
    )
  }

  render() {
    const isDesktop = this.props.isDesktopViewport
    return (
      <BaseView className={cx(
        'find-restyler',
        this.props.isSearching && 'find-restyler--searching',
        this.props.searchResults && 'find-restyler--finished'
      )}>
        {!isDesktop && this.renderMobile()}
        {isDesktop && this.renderDesktop()}
      </BaseView>
    )
  }

  _onRestylerCardSelected = (restyler:RestylerSearchResult) => {
    if (!this._slidePanel) {
      console.warn('FindRestyler _onRestylerCardSelected hit, but no SlidePanel component was found!')
      return
    }

    this._slidePanel.close()
  }

  _onSearchInputChange = (newValue:string) => {
    this.setState({ searchValue: newValue })
  }

  _onSearchInputFocus = () => {
    this._input.clear()
  }

  _onClearSearchSelected = (evt) => {
    this._input.clear()
    this.props.clearRestylerSearchResults()

    if (!this._slidePanel) {
      console.warn('FindRestyler _onClearSearchSelected hit, but no SlidePanel component was found!')
      return
    }
    this._slidePanel.close()
  }

  _onSearchSelected = (evt) => {
    evt.preventDefault() // Don't want the page to refresh.

    const value = this._input.getValue()
    this.props.searchForRestylers(value)

    if (!this._slidePanel) {
      console.warn('FindRestyler _onSearchSelected hit, but no SlidePanel component was found!')
      return
    }
    this._slidePanel.close()
  }

  _onContactSelected = (restyler:any) => {
    this.props.contactRestyler(restyler)
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      isSearching: restylerSelectors.isSearching(state),
      searchedValue: restylerSelectors.getSearchValue(state),
      searchedValueWasAutomated: restylerSelectors.searchValueWasAutomated(state),
      hasAnySearchResults: restylerSelectors.hasAnySearchResults(state),
      searchResults: restylerSelectors.getSearchResults(state),
      countryCodeDenied: restylerSelectors.getCountryCodeDenied(state),
      selectedRestyler: restylerSelectors.getSelectedRestyler(state),
      translate: selectors.getTranslator(state),
      environmentConfig: selectors.getEnvironmentConfig(state),
      isDesktopViewport: selectors.isDesktopViewport(state),
      viewportSize: selectors.getViewportSize(state),
      isSmallViewport: selectors.isSmallViewport(state)
    }
  },
  {
    navigateBack,
    searchForRestylers,
    selectRestyler,
    contactRestyler,
    clearRestylerSearchResults
  }
)(FindRestyler)