import Dispatch from '../Dispatch'
import GetState from '../GetState'
import PanelGroup from '../../../../../core/vehicles/PanelGroup'
import EditorPanelState from '../../store/models/EditorPanelState'

export default (panelGroup:PanelGroup) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		// TODO: Error checking to make sure this doesn't happen when the app is in the wrong state?
    dispatch({
      type: 'SELECT_PANEL_GROUP',
    	panelGroup: panelGroup
    })

    dispatch({
      type: 'SET_EDITOR_PANEL_STATE',
      panelState: EditorPanelState.SelectMaterialOpen
    })
  }
}