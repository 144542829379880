import * as _ from "underscore"

interface LanguageConfiguration {
  identifier: string
  localizationKey: string
  redirectURLs: string[]
}

interface SharingConfiguration {
  imagePostFullUrl: string
  imageGetBaseUrl: string
}

interface RestylerConfiguration {
  allowedCountryCodes: Array<string>
  maxResultCount: number
  googleMapsUrl: string
  googleMapsClient?: string
  googleMapsApiKey?: string
  sessionKeyUrl: string
  findRestylerUrl: string
  findRestylerEmailUrl: string
}

class EnvironmentConfig {
  performanceBlacklist: Array<string>
  sharing: SharingConfiguration
  assetBasePath: string
  restyler: RestylerConfiguration
  languages: Array<LanguageConfiguration>
  enablePaintProtectionFilms: boolean

  constructor(options: any) {
    if (!options.performanceBlacklist) {
      throw new Error("performanceBlacklist not found on environment config!");
    }
    this.performanceBlacklist = options.performanceBlacklist;

    if (!options.assetBasePath) {
      throw new Error("assetBasePath not found on environment config!");
    }
    this.assetBasePath = options.assetBasePath;

    if (!options.sharing) {
      throw new Error("sharing options not found on environment config!");
    }
    this.sharing = options.sharing;

    if (!options.restyler) {
      throw new Error("restyler options not found on environment config!");
    }
    this.restyler = options.restyler;

    if (!options.languages) {
      throw new Error("languages options not found on environment config!");
    }
    this.languages = options.languages;

    this.enablePaintProtectionFilms = !!options.enablePaintProtectionFilms;
  }

  isPaintProtectionFilmsEnabled(): boolean {
    return this.enablePaintProtectionFilms
  }

  // <AssetPathing>
  getAssetFullPath(url: string) {
    return this.assetBasePath + url
  }

  getAssetBasePath() {
    return this.assetBasePath
  }
  // </AssetPathing>

  // <Sharing>
  getShareImageUploadUrl() {
    return this.sharing.imagePostFullUrl
  }

  getShareImageUrl(shareFilename: string) {
    return this.sharing.imageGetBaseUrl + shareFilename
  }
  // </Sharing>

  // <Restylers>
  getRestylerAllowedCountryCodes(): Array<string> {
    return this.restyler.allowedCountryCodes
  }

  getRestylerMaxResultCount(): number {
    return this.restyler.maxResultCount
  }

  getRestylerGoogleMapsUrl(): string {
    return this.restyler.googleMapsUrl
  }

  getRestylerGoogleMapsApiKey(): string | null {
    return this.restyler.googleMapsApiKey
  }

  getRestylerGoogleMapsClient(): string | null {
    return this.restyler.googleMapsClient
  }

  getRestylerSessionKeyUrl(): string {
    return this.restyler.sessionKeyUrl
  }

  getRestylerFindRestylerUrl(): string {
    return this.restyler.findRestylerUrl
  }

  getRestylerFindRestylerEmailUrl(): string {
    return this.restyler.findRestylerEmailUrl
  }
  // </Restylers>

  // <Languages>
  getDefaultLanguageConfig(): any {
    // console.log(this.languages)
    let key = this.getDefaultLanguageKey();
    for (var i = 0; i < this.languages.length; i++) {
      if (this.languages[i].identifier === key) {
        return this.languages[i]
      }
    }
    if (this.languages.length > 0) {
      return this.languages[0]
    }
    return {}
  }

  getLanguageKeys(): Array<string> {
    return _.pluck(this.languages, "identifier")
  }

  getLanguageLocalizationKey(language:string):null|string {
    for (var i = 0; i < this.languages.length; i++) {
      const locale = this.languages[i]
      if (locale.identifier === language) {
        return locale.localizationKey
      }
    }

    return null
  }

  getLanguageRedirectURLs(language:string):null|string[] {
    for (var i = 0; i < this.languages.length; i++) {
      const locale = this.languages[i]
      if (locale.identifier === language) {
        return locale.redirectURLs
      }
    }

    return null
  }

  getDefaultLanguageKey(): string {
    if (window["SETTINGS"] != null && window["SETTINGS"]["LOCALE"] != null) {
      return window["SETTINGS"]["LOCALE"]
    }

    return this.languages[0].identifier
  }

  getLanguages(): Array<LanguageConfiguration> {
    return this.languages
  }
  // </Languages>
}

export default EnvironmentConfig
