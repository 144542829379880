import * as React from 'react'

export default class FormValidator {
	static validate = (references:Array<any>):boolean => {
		let valid = true

		for(let i=0; i<references.length; i++) {
			const reference = references[i]
			if (!reference.validate()) {
				valid = false
				// Intentionally continuing the loop, because we always want to validate all of the fields even after one is found to be invalid.
			}
		}

		return valid
	}
}