import Dispatch from '../Dispatch'
import GetState from '../GetState'
import selectors from '../../store/selectors'
import Wrap from '../../../../../core/wraps/Wrap'
import checkSelectionsForLoading from '../checkSelectionsForLoading'

export default (wrap:Wrap) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		// TODO: Error checking to make sure this doesn't happen when the app is in the wrong state?

		const state = getState()
		const panelGroup = selectors.getSelectedPanelGroup(state)

		dispatch({
			type: 'SELECT_WRAP',
			panelGroup: panelGroup,
			wrap: wrap
		})
		
		dispatch(checkSelectionsForLoading())
  }
}