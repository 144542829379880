import THREE from '../three/threeWithExtensions'
import AssetLibrary from '../AssetLibrary'
import VoidPromise from '../utils/VoidPromise'
import SceneMode from '../SceneMode'
import { TweenLite } from 'gsap'
import DayNightMaterial from './DayNightMaterial'
import ILoadable from '../ILoadable'

const dayColors = {
  ambient: new THREE.Color(0xff0000),
  directional1: new THREE.Color(0xeeeeff),
  directional2: new THREE.Color(0xffffff),
  directional3: new THREE.Color(0xffffff)
}
const dayPositions = {
  directional1: new THREE.Vector3(125, 100, -200),
  directional2: new THREE.Vector3(-125, 100, -200),
  directional3: new THREE.Vector3(25, 200, 0)
}

const nightColors = {
  ambient: new THREE.Color(0x660000),
  directional1: new THREE.Color(0xeeeeff),
  directional2: new THREE.Color(0xeeeeff),
  directional3: new THREE.Color(0xeeeeff)
}
const nightPositions = {
  directional1: new THREE.Vector3(150, 100, -200),
  directional2: new THREE.Vector3(-150, 100, -200),
  directional3: new THREE.Vector3(0, 200, 0)
}

const cubeGeometry = new THREE.BoxGeometry(20, 20, 20)

class Environment implements ILoadable {
  _backgroundScene:THREE.Scene
  _scene:THREE.Scene
  _sceneMode:SceneMode
  _promise:Promise<void>

  _model:THREE.Mesh
  _ambientLight:THREE.AmbientLight

  _directionalLight1: THREE.DirectionalLight
  _directionalLight2: THREE.DirectionalLight
  _directionalLight3: THREE.DirectionalLight

  _dayEnvMap:THREE.CubeTextureLoader
  _nightEnvMap:THREE.CubeTextureLoader

  _dayTexture:THREE.Texture
  _nightTexture:THREE.Texture
  // _screenshotTexture:THREE.Texture

  _material:THREE.MeshBasicMaterial
  _animations:any

  _cube1: THREE.Mesh
  _cube2: THREE.Mesh
  _cube3: THREE.Mesh

  _setDaytimePercentageCallback:(value:number)=>any
  _isLoaded:boolean = false

  constructor() {
    this._scene = null

    this._sceneMode = SceneMode.Daytime
    this._animations = {
      daytimePercentage: 0.0,
      colors: {
        ambient: dayColors.ambient.clone(),
        directional1: dayColors.directional1.clone(),
        directional2: dayColors.directional2.clone(),
        directional3: dayColors.directional3.clone()
      },
      positions: {
        directional1: dayPositions.directional1.clone(),
        directional2: dayPositions.directional2.clone(),
        directional3: dayColors.directional3.clone()
      }
    }

    this._ambientLight = new THREE.AmbientLight(this._animations.colors.ambient.getHex())
    this._material = DayNightMaterial

    this._directionalLight1 = new THREE.DirectionalLight(this._animations.colors.directional1.getHex(), 0.8)
    this._directionalLight1.position.copy(this._animations.positions.directional1)
    this._directionalLight1.castShadow = true

    this._directionalLight2 = new THREE.DirectionalLight(this._animations.colors.directional2.getHex(), 1.8)
    this._directionalLight2.position.copy(this._animations.positions.directional2)
    this._directionalLight2.castShadow = true

    this._directionalLight3 = new THREE.DirectionalLight(this._animations.colors.directional3.getHex(), 1.8)
    this._directionalLight3.position.copy(this._animations.positions.directional3)
    this._directionalLight3.castShadow = true

    this._setDaytimePercentageCallback = null
    this._isLoaded = false
  }

  addDebugCubes = ():void => {
    this._cube1 = new THREE.Mesh(cubeGeometry, new THREE.MeshBasicMaterial( { color: this._directionalLight1.color } ))
    this._scene.add(this._cube1)

    this._cube2 = new THREE.Mesh(cubeGeometry, new THREE.MeshBasicMaterial( { color: this._directionalLight2.color } ))
    this._scene.add(this._cube2)

    this._cube3 = new THREE.Mesh(cubeGeometry, new THREE.MeshBasicMaterial( { color: this._directionalLight3.color } ))
    this._scene.add(this._cube3)
  }

  isLoaded = ():boolean => {
    return this._isLoaded
  }

  load = (basePath:string):Promise<void> => {
    if (this._promise) {
      return this._promise
    }

    // environmentConfig.getAssetBasePath()

    const modelPromise = AssetLibrary.loadFbxModel(basePath+'assets/models/BKG_Infinity.fbx').then( (model) => {
      this._model = model
    })

    const daySkyboxPromise = AssetLibrary.loadCubeTexture(
      basePath+'assets/textures/skybox/xp.png',
      basePath+'assets/textures/skybox/xn.png',
      basePath+'assets/textures/skybox/yp.png',
      basePath+'assets/textures/skybox/yn.png',
      basePath+'assets/textures/skybox/zp.png',
      basePath+'assets/textures/skybox/zn.png'
    ).then( (envMap) => {
      this._dayEnvMap = envMap
    })

    const dayTexturePromise = AssetLibrary.loadTexture(basePath+'assets/textures/bkg_baked_infinity_day.png').then( (texture) => {
      this._dayTexture = texture
      this._material.uniforms.dayMap.value = texture
    })

    const nightSkyboxPromise = AssetLibrary.loadCubeTexture(
      basePath+'assets/textures/skybox-night/xp.png',
      basePath+'assets/textures/skybox-night/xn.png',
      basePath+'assets/textures/skybox-night/yp.png',
      basePath+'assets/textures/skybox-night/yn.png',
      basePath+'assets/textures/skybox-night/zp.png',
      basePath+'assets/textures/skybox-night/zn.png'
    ).then( (envMap) => {
      this._nightEnvMap = envMap
    })
    const nightTexturePromise = AssetLibrary.loadTexture(basePath+'assets/textures/bkg_baked_infinity_night.png').then( (texture) => {
      this._nightTexture = texture
      this._material.uniforms.nightMap.value = texture
    })

    
    // const screenshotTexturePromise = AssetLibrary.loadTexture(basePath+'assets/textures/background_baked-screenshot.png').then( (texture) => {
    //   this._screenshotTexture = texture
    // })

    this._promise = VoidPromise.all([
      modelPromise,
      daySkyboxPromise, dayTexturePromise,
      nightSkyboxPromise, nightTexturePromise,
      // screenshotTexturePromise
    ]).then( () => {
      this._isLoaded = true
    })

    return this._promise
  }

  setScene = (basePath:string, backgroundScene:THREE.Scene, scene:THREE.Scene) => {
    if (!scene) {
      throw new Error('scene is required')
    }
    this._scene = scene

    if (!backgroundScene) {
      backgroundScene = scene
    }
    this._backgroundScene = backgroundScene

    if (!this.isLoaded()) {
      this.load(basePath).then(this._configureSceneWithAssets)
    }
    else {
      this._configureSceneWithAssets()
    }
  }

  setVisible = (value:boolean):void => {
    this._model.visible = value

    if (value) {
      this._backgroundScene.background = this._dayEnvMap
    }
    else {
      this._backgroundScene.background = null
    }
  }

  isVisible = ():boolean => {
    return this._model.visible
  }

  getDayEnvironmentMap = ():THREE.Material => {
    return this._dayEnvMap
  }

  getNightEnvironmentMap = ():THREE.Material => {
    return this._nightEnvMap
  }

  getDaytimeEnvironmentMapPercentage = ():number => {
    return this._animations.daytimePercentage
  }

  transitionToSceneMode = (sceneMode:SceneMode):Promise<void> => {
    return new Promise( (resolve, reject) => {
      if (this._sceneMode === sceneMode) {
        resolve()
        return
      }

      return this._setToSceneMode(sceneMode, true)
    })
  }

  setDaytimePercentageCallback = (callback:any):any => {
    this._setDaytimePercentageCallback = callback
  }

  _configureSceneWithAssets = ():void => {
    this._backgroundScene.background = this._dayEnvMap

    // this._model.position.setComponent(0, 4308)
    this._model.traverse( (child) => {
      if (child.isMesh) {
        child.material = this._material
      }
    })
    this._backgroundScene.add(this._model)

    // Only add the lights to the vehicle scene. The background has lighting baked in.
    this._scene.add(this._ambientLight)
    this._scene.add(this._directionalLight1)
    this._scene.add(this._directionalLight2)
    this._scene.add(this._directionalLight3)

    this._setToSceneMode(this._sceneMode, false)
  }

  setupForScreenshot = ():void => {
    // this._material.uniforms.nightMap.value = this._screenshotTexture
    // this._material.uniforms.dayMap.value = this._screenshotTexture
    this._material.uniforms.daytimePercentage.value = 0.0
  }

  resetFromScreenshot = ():void => {
    // this._material.uniforms.dayMap.value = this._dayTexture
    // this._material.uniforms.nightMap.value = this._nightTexture
    this._material.uniforms.daytimePercentage.value = ((this._sceneMode === SceneMode.Daytime) ? 1.0 : 0.0)
  }

  _setToSceneMode = (sceneMode:SceneMode, animate:boolean):Promise<void> => {
    this._sceneMode = sceneMode

    const envMap = (sceneMode === SceneMode.Daytime) ? this._dayEnvMap : this._nightEnvMap
    const daytimePercentage = (sceneMode === SceneMode.Daytime) ? 1.0 : 0.0

    return new Promise( (resolve, reject) => {
      // this._scene.envMap = envMap
      this._backgroundScene.envMap = envMap // This doesn't actually matter

      const durationInSeconds = 0.4
      TweenLite.to(this._animations, durationInSeconds, {
        daytimePercentage: daytimePercentage,
        onUpdate: () => {
          this._material.uniforms.daytimePercentage.value = this._animations.daytimePercentage
          if (this._setDaytimePercentageCallback) {
            this._setDaytimePercentageCallback(this._animations.daytimePercentage)
          }
        },
        onComplete: () => {
          resolve()
        }
      })

      this._animateColors(sceneMode, durationInSeconds)
      this._animatePositions(sceneMode, durationInSeconds)
    })
  }

  _animateColors = (sceneMode:SceneMode, durationInSeconds:number) => {
    const toAmbient = (sceneMode === SceneMode.Daytime) ? dayColors.directional1 : nightColors.directional1
    const toDirectional1 = (sceneMode === SceneMode.Daytime) ? dayColors.directional1 : nightColors.directional1
    const toDirectional2 = (sceneMode === SceneMode.Daytime) ? dayColors.directional2 : nightColors.directional2
    const toDirectional3 = (sceneMode === SceneMode.Daytime) ? dayColors.directional3 : nightColors.directional3

    TweenLite.to(this._animations.colors.ambient, durationInSeconds, {
      r: toAmbient.r, g: toAmbient.g, b: toAmbient.b,
      onUpdate: () => { this._ambientLight.color.copy(this._animations.colors.ambient) }
    })

    TweenLite.to(this._animations.colors.directional1, durationInSeconds, {
      r: toDirectional1.r, g: toDirectional1.g, b: toDirectional1.b,
      onUpdate: () => {
        this._directionalLight1.color.copy(this._animations.colors.directional1)
        if (this._cube1) {
          this._cube1.material.color.copy(this._directionalLight1.color)
        }
      }
    })

    TweenLite.to(this._animations.colors.directional2, durationInSeconds, {
      r: toDirectional2.r, g: toDirectional2.g, b: toDirectional2.b,
      onUpdate: () => {
        this._directionalLight2.color.copy(this._animations.colors.directional2)
        if (this._cube2) {
          this._cube2.material.color.copy(this._directionalLight2.color)
        }
      }
    })

    TweenLite.to(this._animations.colors.directional3, durationInSeconds, {
      r: toDirectional3.r, g: toDirectional3.g, b: toDirectional3.b,
      onUpdate: () => {
        this._directionalLight3.color.copy(this._animations.colors.directional3)
        if (this._cube3) {
          this._cube3.material.color.copy(this._directionalLight3.color)
        }
      }
    })
  }

  _animatePositions = (sceneMode:SceneMode, durationInSeconds:number) => {
    const toDirectional1:THREE.Vector3 = (sceneMode === SceneMode.Daytime) ? dayPositions.directional1 : nightPositions.directional1
    const toDirectional2:THREE.Vector3 = (sceneMode === SceneMode.Daytime) ? dayPositions.directional2 : nightPositions.directional2
    const toDirectional3:THREE.Vector3 = (sceneMode === SceneMode.Daytime) ? dayPositions.directional3 : nightPositions.directional3

    TweenLite.to(this._animations.positions.directional1, durationInSeconds, {
      x: toDirectional1.x, y: toDirectional1.y, z: toDirectional1.z,
      onUpdate: () => {
        this._directionalLight1.position.copy(this._animations.positions.directional1)
        if (this._cube1) {
          this._cube1.position.copy(this._directionalLight1.position)
        }
      }
    })

    TweenLite.to(this._animations.positions.directional2, durationInSeconds, {
      x: toDirectional2.x, y: toDirectional2.y, z: toDirectional2.z,
      onUpdate: () => {
        this._directionalLight2.position.copy(this._animations.positions.directional2)
        if (this._cube2) {
          this._cube2.position.copy(this._directionalLight2.position)
        }
      }
    })

    TweenLite.to(this._animations.positions.directional3, durationInSeconds, {
      x: toDirectional3.x, y: toDirectional3.y, z: toDirectional3.z,
      onUpdate: () => {
        this._directionalLight3.position.copy(this._animations.positions.directional3)
        if (this._cube3) {
          this._cube3.position.copy(this._directionalLight3.position)
        }
      }
    })
  }
}

export default Environment
